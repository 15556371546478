import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user/service/user.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  show;role;id;username;
  constructor(private userService: UserService, private router: Router,private titleService: Title ) { }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  ngOnInit() {
  	this.show = this.userService.isLoggedIn();
    this.role = this.userService.getUserRole();
    this.id = this.userService.getUserID();
    this.username= this.userService.getusername();
  }

}
