import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../service/user.service'
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})

export class SignUpComponent implements OnInit {

  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";  
  websiteexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  numberonly = /^-?(0|[1-9]\d*)?$/;
  signUpForm: FormGroup;mailData;username:string;
  showSucessMessage: boolean;
  serverErrorMessages: string; company_name: string; email: string; address: string; city: string; state: string; zipcode: string; phone: string; fax: string; website: string; password: string; confirmpassword: string;

  constructor(public userService: UserService, private formBuilder: FormBuilder, private http: HttpClient) { }

  ngOnInit() {

       this.signUpForm = this.formBuilder.group({
            username       : ['', Validators.required],
            company_name   : ['', Validators.required],
            email          : ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            city           : ['', Validators.required],            
            state          : ['', Validators.required],
            zipcode        : ['', Validators.required],
            phone          : ['', [Validators.required, Validators.pattern(this.mobNumberPattern)]],           
            website        : ['', [Validators.required, Validators.pattern(this.websiteexp)]], 
           // password       : ['', Validators.required], 
           // confirmpassword: ['', Validators.required],
        }); 

  }

  onSubmit(form: NgForm) {

    form.value["slug"] = form.value["username"];
    this.userService.registerCompany(form.value).subscribe(
      res => {
        this.showSucessMessage = true;
        setTimeout(() => this.showSucessMessage = false, 1000);
        this.resetForm(form);
        console.log(res['email']);
         console.log('Hai Deepu');
        console.log(form.value);

              var url=document.getElementsByTagName('base')[0].href +'resetpassword/'+ btoa(res['email']);
              
              let mail_msg = "Hi " + res['company_name'] +", <br/><br/>";
              mail_msg  += "Welcome to Insight Team. <br/><br/>";
              mail_msg  += "Please follow the link provided below to reset your password. <br/><br/>";
              mail_msg  +="<a href="+ url +">Reset your account password.</a>";
              mail_msg  += " <br/><br/> Regards ,<br/> Insight Team";
              this.mailData = {'to':res['email'],'from':'admin@subhosting.net','subject':'Welcome to Insight','text':mail_msg};
                      
              this.userService.sendMail(this.mailData).subscribe(
                res => {
                 console.log(res); 
                }, 
              );
      },
      err => {
        if (err.status === 422) {
          this.serverErrorMessages = err.error.join('<br/>');
        }
        else{
          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      }
    );
  }

  resetForm(form: NgForm) {
    this.userService.userdata = {
      username : '', 
      company_name : '', 
      email : '', 
      address : '', 
      city : '', 
      state : '', 
      zipcode : '', 
      fax : '', 
      website : '', 
      fullName : '', 
      phone : '', 
      password : '', 
      confirmpassword : ''
    };
    form.resetForm();
    this.serverErrorMessages = '';
  }

}
