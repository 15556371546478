import { Component, OnInit } from '@angular/core';
import { EmployeesService } from '../service/employees.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-employees',
  templateUrl: './view-employees.component.html',
  styleUrls: ['./view-employees.component.css']
})
export class ViewEmployeesComponent implements OnInit {

	title='View Employees';
  id; employeesDetails;employeesAvatar;devManagerList;slug;showDelSucessMessage;serverErrorMessages;showSucessMessage;
  constructor(private employeesService: EmployeesService,private route: ActivatedRoute, private router: Router, private http: HttpClient) { }    

  ngOnInit() {
      this.slug = this.employeesService.getusername();
	  	this.id = this.route.snapshot.paramMap.get('id');
	  	this.employeesService.editEmployee(this.id).subscribe(
        res => {
            this.employeesDetails = res[0];
            if(res[0].avatar) {
              let apiURl = environment.apiBaseUrl;
              apiURl = apiURl.replace('api','');
              this.employeesAvatar = apiURl+res[0].avatar;
            }
            this.employeesService.getRepPersonByDept(res[0].department,this.slug).subscribe(
              res => {
                this.devManagerList = res;
              },
              err => { 
                console.log(err);            
              }
          )
        },
        err => { 
            console.log(err);          
        }
      );   
    }

}
