import { Component, OnInit, TemplateRef ,Input  } from '@angular/core';
import { ActivatedRoute, Params, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AttendanceService } from '../attendance/service/attendance.service'
import { UserService } from '../user/service/user.service';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'editattendancecalendar',
  templateUrl: './editattendancecalendar.component.html',
  styles: ['#myModal.modal-body { overflow-y: none; }']   
})

export class EditAttendanceCalendarComponent implements OnInit {
  quickAttendance; loggedUserId;attendanceTypesList;isTodayIn;isTodayOut;id;todayAttendance;show;attendanceEdit;
  message: string;attendanceForm;attend_dates;timein_data;timeout_data;showModal;data;slug;bsInlineValue;
  showDelSucessMessage: Boolean;
  showSucessMessage: Boolean;
  serverErrorMessages: string;
  page:string;
  curtime: Date = new Date();
  updateQattendanceForm: FormGroup;
  addQattendanceForm: FormGroup;
  @Input() public notid: any;
  @Input() public userid: any;

  constructor(  private router: Router, private modalRef: BsModalRef, private modalService: BsModalService, public attendanceService: AttendanceService, public userService: UserService, private formBuilder: FormBuilder,private route: ActivatedRoute) { }

  ngOnInit() {
    this.attendanceEdit={attend_date:'',time_in:'',time_out:'', attend_type_id:''}; 
    this.loggedUserId = this.userService.getUserID(); 
      if(!this.userService.isLoggedIn()){
        this.router.navigateByUrl('/login');
      }

      this.attendanceForm = this.formBuilder.group({
        attend_date       : ['', Validators.required],
        time_in           : ['', Validators.required],
        time_out          : ['', Validators.required],
        attend_type_id    : ['', Validators.required],
      });
      
      this.slug=this.userService.getusername();
      this.attendanceService.getAttendanceTypes(this.slug).subscribe(
        res => {
          this.attendanceTypesList = res;                      
          },
        err => { 
          console.log(err);            
        }
      );

      this.attendanceService.getAttendanceDetails(this.notid).subscribe(
        res => {
        console.log(res);
          this.attendanceEdit = res[0];  
          this.id=this.attendanceEdit._id;
         },
        err => { 
          console.log(err);            
        }
      );
  }

  onValueChange(att_date: Date): void {}

  onTimeInValueChange(timeIn): void {    
    let selDateIn = this.attend_dates.value;
    selDateIn.setHours(timeIn.getHours(), timeIn.getMinutes(), timeIn.getSeconds());
    this.timein_data = selDateIn;
  }

  onTimeOutValueChange(timeOut): void {    
    let selDateout = this.data;
    this.timeout_data = selDateout;
  }

  updateattend(updateattendanceForm: NgForm) {
    this.modalRef.hide();
    
    let selDate    = updateattendanceForm.value["attend_date"];
    let selTimeIn  = updateattendanceForm.value["time_in"];
    let selTimeOut = updateattendanceForm.value["time_out"];
    updateattendanceForm.value["newtimein"]  = selTimeIn; 
    updateattendanceForm.value["newtimeOut"] = selTimeOut; 
    var newtimein  = new Date(selTimeIn);
    var newtimeout = new Date(selTimeOut);
    var diffMs     = (newtimeout.getTime() - newtimein.getTime());
    var diffHrs    = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins   = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    updateattendanceForm.value["total_hours"] = diffHrs +":"+ diffMins; 

    this.attendanceService.postupdateAttendance(updateattendanceForm.value).subscribe(
      res => {
        this.showSucessMessage = true;
        setTimeout(() => {
            this.showSucessMessage = false;
            window.location.reload();
        }, 1000);         
      },
      err => {
        if (err.status === 422) {
          this.serverErrorMessages = err.error.join('<br/>');
        }
        else
          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
      }
    ); 
    this.showModal = false;
  }
    
  hide(): void {
    this.modalRef.hide();
    this.showDelSucessMessage = false;
  }
 
}
