import { Component, OnInit } from '@angular/core';
import { LeaveRequestService } from '../service/leaverequest.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { DepartmentService } from '../../admin/department/service/department.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-list-leaverequest',
  templateUrl: './list-leaverequest.component.html',
  styleUrls: ['./list-leaverequest.component.css']
})

export class ListLeaveRequestComponent implements OnInit {
  title='List LeaveRequest';
	employeesList;attendanceSearch;departmentDetails;slug;showDelSucessMessage;serverErrorMessages;showSucessMessage;role;userdetails;dept;
  modalRef: BsModalRef;   
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  constructor( private leaverequestService: LeaveRequestService, private router: Router, private modalService: BsModalService,private departmentService: DepartmentService) { }

  ngOnInit() {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10
      };
      this.slug=this.leaverequestService.getusername();
      this.role = this.leaverequestService.getUserRole();
      this.userdetails = this.leaverequestService.getUserDtls();
      
      this.attendanceSearch={employeeName:'',department:'',employeeEmail:''}; 
        this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        }
      );

      if(this.role=='Admin'){
         this.dept='Admin';
      }else if(this.role=='Manager'){
         this.dept= this.userdetails.user_dept;
      }else{
         this.dept='User';
      }

    	this.leaverequestService.listLeaveRequest(this.dept,this.slug).subscribe(
          res => {
            this.employeesList = res;  
            
             if (this.employeesList.length > 0) {
                $('.dataTables_empty').css('display', 'none');
              } else {
                $('.dataTables_empty').css('display', 'table-cell');
              }  
              this.dtTrigger.next();          
          },
          err => { 
            console.log(err);            
          }
      );
  }

  onSubmit(form: NgForm) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
   
    this.dtTrigger.unsubscribe(); 
    form.value["slug"] = this.leaverequestService.getusername();
    this.leaverequestService.searchEmployees(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {     
        this.employeesList = employeesDetails;
        console.log(this.employeesList);
        this.dtTrigger.next();
    });
  }

  clearFilter(form: NgForm) {
    this.attendanceSearch={employeeName:'',department:'',employeeEmail:''}; 
    this.dtTrigger.unsubscribe(); 
     if(this.role=='Admin'){
         this.dept='Admin';
      }else if(this.role=='Manager'){
         this.dept= this.userdetails.user_dept;
      }else{
         this.dept='User';
      }
    this.leaverequestService.listLeaveRequest(this.dept,this.slug).subscribe(
          res => {
            this.employeesList = res;  
             if (this.employeesList.length > 0) {
                $('.dataTables_empty').css('display', 'none');
              } else {
                $('.dataTables_empty').css('display', 'table-cell');
              }  
              this.dtTrigger.next();          
          },
          err => { 
            console.log(err);            
          }
      ); 
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

}
