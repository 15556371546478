import { Component, OnInit } from '@angular/core';
import { PushNotificationsService} from 'ng-push';
import { PushNotificationsModule } from 'ng-push'; 

@Component({
  selector: 'app-pushnotification',
  templateUrl: './pushnotification.component.html',
  styleUrls: ['./pushnotification.component.css']
})
export class PushnotificationComponent implements OnInit {
    title = 'Web push Notifications!';
  constructor( private _pushNotifications: PushNotificationsService ) {
    this._pushNotifications.requestPermission();
  }


  ngOnInit() {
     let options = { 
        body: "Notification remainder. Please add the attendance login.",
        icon: "../assets/images/icon.png" 
      }
       this._pushNotifications.create('Notification From Insight', options).subscribe( //creates a notification
          res => console.log(res),
          err => console.log(err)
      );
    }

}
