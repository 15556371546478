import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ReportsService {  

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  //HttpMethods

  listEmployeeAge(slug) {
    return this.http.get(environment.apiBaseUrl + '/listEmployeeAge/'+slug);
  }

  searchEmployeesAgeProfile(employees){
    return this.http.post(environment.apiBaseUrl+'/searchEmployeesAgeProfile',employees,this.noAuthHeader);
  }

  listAttendanceByDate(slug) {
    return this.http.get(environment.apiBaseUrl + '/listAttendanceByDate/'+slug);
  }

  searchAttendanceByDate(attendance){
    return this.http.post(environment.apiBaseUrl+'/searchAttendanceByDate',attendance,this.noAuthHeader);
  }

  listTimeOffBalances(slug,leavetype,empltype) {
    return this.http.get(environment.apiBaseUrl + '/listTimeOffBalances/'+slug+'/'+leavetype+'/'+empltype);
  }

  searchTimeOffBalances(leaves){
    return this.http.post(environment.apiBaseUrl+'/searchTimeOffBalances',leaves,this.noAuthHeader);
  }

  listTimeOffUsed(slug) {
    return this.http.get(environment.apiBaseUrl + '/listTimeOffUsed/'+slug);
  }
  
  searchTimeOffUsed(leaves){
    return this.http.post(environment.apiBaseUrl+'/searchTimeOffUsed',leaves,this.noAuthHeader);
  }


}
