import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { EmployeesService } from '../../employees/service/employees.service';
import { ReportsService } from '../service/reports.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-age-profile',
  templateUrl: './age-profile.component.html',
  styleUrls: ['./age-profile.component.css']
})
export class AgeProfileComponent implements OnInit {
  title='Age Profile';
  slug;age;rowid;employeesDetails;departmentDetails;roleDetails;reportFilters;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private titleService: Title, private employeesService: EmployeesService, private reportsService: ReportsService, private departmentService: DepartmentService) { }

  ngOnInit() {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.slug = this.employeesService.getusername();
    
    this.reportFilters={department:'',role:''}; 

    this.reportsService.listEmployeeAge(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();
    });

    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.employeesService.listRoles(this.slug).subscribe(
        res => {
          this.roleDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );
    
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  public ageFromDateOfBirthday(dateOfBirth: any): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }


  onSubmit(form: NgForm) {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };

    this.dtTrigger.unsubscribe(); 
    form.value["slug"] = this.employeesService.getusername();
     this.reportsService.searchEmployeesAgeProfile(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();
    });
  }

  clearFilter(form: NgForm) {
    this.reportFilters={department:'',role:''}; 
    this.slug = this.employeesService.getusername();
    this.dtTrigger.unsubscribe(); 
    this.reportsService.listEmployeeAge(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();
    });
  }

}
