import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NotificationService } from '../service/notification.service';
import { UserService } from '../../user/service/user.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.css']
})
export class ViewNotificationComponent implements OnInit {

  title='View Notification'; slug;
  signUpForm: FormGroup;
  id;notificationDetails;departmentDetails;viewnotificationDetails;UserID;

  constructor(private notificationService: NotificationService,private route: ActivatedRoute,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private departmentService: DepartmentService, private http: HttpClient) { }

  ngOnInit() {
      this.UserID = this.userService.getUserID();
      this.id = this.route.snapshot.paramMap.get('id');
      this.slug=this.userService.getusername();
        this.departmentService.listDepartment(this.slug).subscribe(
          res => {
            this.departmentDetails = res;
          },
          err => { 
            console.log(err);            
          }
        );

        this.notificationService.editNotification(this.id).subscribe(
  		    res => {
  		        this.notificationDetails = res[0];
  		    },
  		    err => { 
  		        console.log(err);	        
  		    }
  	    );

        this.notificationService.updateViewNotification(this.UserID,this.id).subscribe(
          res => {
              this.viewnotificationDetails = res[0];
          },
          err => { 
              console.log(err);         
          }
        );
  }

}
