import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeesService } from '../service/employees.service'
import { ActivatedRoute } from "@angular/router";
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DepartmentService } from '../../admin/department/service/department.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {

    title='User Password Change';
    showSucessMessage: boolean;
    serverErrorMessages: string;
    signUpForm: FormGroup;    
    loggedUserId;

    constructor(private employeesService: EmployeesService,public userService: UserService,private router: Router, private formBuilder: FormBuilder, private http: HttpClient) { }    

    ngOnInit() {
	  	this.loggedUserId = this.userService.getUserID();		  	 	
	}

    onSubmit(form: NgForm) {
	    
		if(form.value.password != form.value.confirmpassword) {
			this.serverErrorMessages = 'Password Mismatch';			
		} else {			
			this.employeesService.changePassword(form.value).subscribe(
			    res => {		    	
			    	if(res['error']) {
			    		this.serverErrorMessages = res['error'];
			    		this.showSucessMessage = false;
			    	} else {
			    		this.serverErrorMessages = '';
			    		this.showSucessMessage = true;
			    	}		    	
	       
			       /* this.router.navigateByUrl('/homepage');*/
			    },
			    err => {
			        this.serverErrorMessages = err.error.message;
			    }
		    );
		}	    
	}
 

}
