import {Component, Directive, Input, ViewChild,OnInit,ChangeDetectionStrategy} from '@angular/core'; 
import { Title }     from '@angular/platform-browser';
import { EmployeesService } from '../../employees/service/employees.service';
import { ReportsService } from '../service/reports.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';  
import { EventInput } from '@fullcalendar/core';  
import dayGridPlugin from '@fullcalendar/daygrid'; 

@Component({
  selector: 'app-monthly-attendance',
  templateUrl: './monthly-attendance.component.html',
  styleUrls: ['./monthly-attendance.component.css']
})
export class MonthlyAttendanceComponent implements OnInit {
  title='Monthly Attendance';
  slug;age;rowid;employeesDetails;departmentDetails;roleDetails;reportFilters;eventSearch;
  employeeAttendance;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  @ViewChild('calendar',null) calendarComponent: FullCalendarComponent; 
  options: any;  
  eventsModel: any[] = [];  
  calendarVisible = true;  
  calendarWeekends = true;  
  calendarEvents: EventInput[] = [  
    { title: 'Today', start: new Date() }  
  ];  
  toggleVisible() {  
    this.calendarVisible = !this.calendarVisible;  
  } 

  constructor(private titleService: Title, private employeesService: EmployeesService, private reportsService: ReportsService, private departmentService: DepartmentService) { }

  ngOnInit() {
    this.rowid='';
    this.options = { 
      default: true,
      editable: true,  
      disableDragging: false,  
      selectable: true,  
      theme: 'bootstrap', 
      hiddenDays: [ 6,7], 
      header: {  
        left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' 
      }, 
      initialView: 'dayGridMonth', 
      validRange: {  
        start: '2017-05-01',  
        end: new Date()  
      },  
      plugins: [dayGridPlugin]  
    };  
    this.slug = this.employeesService.getusername();
    
    this.reportFilters={department:'',role:'',attend_date:new Date()}; 

    this.reportsService.listAttendanceByDate(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();
    });

    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.employeesService.listRoles(this.slug).subscribe(
        res => {
          this.roleDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );
    
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }


eventClick(event){
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.employeeAttendance  = new Array;
    this.employeesDetails  = new Array;
    this.dtTrigger.unsubscribe(); 
    this.eventSearch = {attend_date: event.target.dataset.date, slug: this.slug}
    this.reportsService.searchAttendanceByDate(this.eventSearch).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeeAttendance = employeesDetails;
        for(let employeeAtt of this.employeeAttendance) { 
          if(employeeAtt.employee.length >0 ) {
            this.employeesDetails.push(employeeAtt);
          }
        }        
        this.dtTrigger.next();
    });
  }

}
