import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Attendance } from './attendance.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  addAttendance: Attendance = {
    userId: sessionStorage.getItem('logged_user'),
    attend_date: '',
    time_in: '',
    time_out: '',
    attend_type_id: '',
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  constructor(private http: HttpClient) { }

  postAddAttendance(attendance: Attendance){
    return this.http.post(environment.apiBaseUrl+'/add_attendance',attendance,this.noAuthHeader);
  }

  listUserAttendance(slug) {
    return this.http.get(environment.apiBaseUrl + '/listAttendancetypes/'+slug);
  }
   
  postupdateAttendance(attendance: Attendance){
    return this.http.post(environment.apiBaseUrl+'/postupdateAttendance',attendance,this.noAuthHeader);
  }

  getAttendanceTypes(slug) {
    return this.http.get(environment.apiBaseUrl + '/listAttendancetypes/'+slug);
  }

  getAttendanceDetails(notid){
     return this.http.get(environment.apiBaseUrl + '/editAttendance/'+notid);
  }
  listEmployeeAttendance(id) {
    return this.http.get(environment.apiBaseUrl + '/listEmployeeAttendance/'+id);
  }

  searchAttendances(attendance: Attendance){
     return this.http.post(environment.apiBaseUrl+'/searchAttendances',attendance,this.noAuthHeader);
  }
  getTodaysAttendance(emp_id){
     return this.http.get(environment.apiBaseUrl+'/getTodaysAttendance/'+emp_id);
  }


}