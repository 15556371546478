import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-policies',
  templateUrl: './company-policies.component.html',
  styleUrls: ['./company-policies.component.css']
})
export class CompanyPoliciesComponent implements OnInit {

  title='Company Policies';
  showDelSucessMessage;serverErrorMessages;showSucessMessage;
  constructor() { }

  ngOnInit() {
  }

}
