import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user/service/user.service';
import { ActivatedRoute, Params, Router} from '@angular/router';
import { Title }     from '@angular/platform-browser';
import { NotificationService } from '../../notification/service/notification.service';
import { TimeAgoPipe} from 'time-ago-pipe';
import { environment } from '../../../environments/environment';
import { QuickAttendanceComponent } from '../../shared/quick-attendance.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  show;loggedUserId;notificationDetails;userDepartment;shownotification;employeesAvatar;fullName;id;slug;
  modalRef: BsModalRef;
  constructor(private userService: UserService, private router: Router,private titleService: Title, public notificationService: NotificationService,private modalService: BsModalService, private route: ActivatedRoute) { }

  ngOnInit() {
  	this.show =  this.userService.isLoggedIn();
    this.id = this.route.snapshot.paramMap.get('id');
    this.loggedUserId = this.userService.getUserDtls();
    this.userDepartment = this.userService.getUserDepartment();
    this.slug=this.userService.getusername();
    let apiURl = environment.apiBaseUrl;
    apiURl = apiURl.replace('api','');
    this.employeesAvatar = apiURl+this.userService.getUserAvatar();
    this.fullName=this.loggedUserId.fullname;

    this.notificationService.listEmployeeNotification(this.userDepartment,this.slug).subscribe(
        notificationDetails => {
            this.notificationDetails = notificationDetails; 
            this.shownotification=this.notificationDetails.length;
    });
  }

  onLogout(){
    this.userService.deleteToken();
    this.userService.deleteLoggedUser();
    location.reload();
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  quick_attendance() {
    const initialState = {
          emp_id: this.id,         
          page:'quickatte',
    };
    this.modalRef = this.modalService.show(QuickAttendanceComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }

}
