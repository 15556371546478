import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from '../service/department.service';
import { UserService } from '../../../user/service/user.service';
import { Router } from "@angular/router";
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.css']
})

export class AddDepartmentComponent implements OnInit { 
  title='Add Department';
  showSucessMessage: boolean;
  serverErrorMessages: string;
  signUpForm: FormGroup;

  constructor(public departmentService: DepartmentService,private userService: UserService, private router: Router, private formBuilder: FormBuilder, private titleService: Title) { }

  ngOnInit() {
      if(!this.userService.isLoggedIn()){
        this.router.navigateByUrl('/login');
      }

      this.signUpForm = this.formBuilder.group({
          department_name   : ['', Validators.required],
          department_code   : ['', Validators.required],
          status            : ['', Validators.required],
      }); 
  }

  onSubmit(form: NgForm) {
      form.value["slug"] = this.userService.getusername();
      this.departmentService.postDepartment(form.value).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => this.showSucessMessage = false, 1000);
          this.resetForm(form);
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
    }

  resetForm(form: NgForm) {
      this.departmentService.addDepartment = {
        department_name   : '',
        department_code   : '',
        status            : '' 
      };
      form.resetForm();
      this.serverErrorMessages = '';
  }

 setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
