import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttendanceService } from '../service/attendance.service'
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { EmployeesService } from '../../employees/service/employees.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DepartmentService } from '../../admin/department/service/department.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  
  title='Employees Summary';
  employeesDetails;departmentDetails;attendanceSearch;slug;showDelSucessMessage;serverErrorMessages;showSucessMessage;today;role;userdetails;dept;
  loggedUserId: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(public attendanceService: AttendanceService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private employeesService: EmployeesService,private modalService: BsModalService,private departmentService: DepartmentService) { }

  ngOnInit() {
      this.dtOptions = {
	      pagingType: 'full_numbers',
	      pageLength: 10,
        order: [ 0, 'asc' ],
	    };
      this.slug = this.employeesService.getusername();
      this.attendanceSearch={employeeName:'',department:'',employeeEmail:''}; 
      this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;

        }
      );
      this.role = this.userService.getUserRole();
      this.userdetails = this.userService.getUserDtls();
      if(this.role=='Admin'){
         this.dept='Admin';
      }else if(this.role=='Manager'){
         this.dept= this.userdetails.user_dept;
      }else{
         this.dept='User';
      }

	    this.employeesService.listEmployee(this.dept,this.slug).pipe(map(this.extractData)
	      ).subscribe(employeesDetails => {
	        this.employeesDetails = employeesDetails;
	        this.dtTrigger.next();
	    });
	}



    onSubmit(form: NgForm) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
       order: [ 0, 'asc' ],
    };
    this.dtTrigger.unsubscribe(); 
    form.value["slug"] = this.employeesService.getusername();
    this.employeesService.searchEmployees(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();

    });
  }

  clearFilter(form: NgForm) {
    //form.reset();
    this.attendanceSearch={employeeName:'',department:'',employeeEmail:''}; 
    this.dtTrigger.unsubscribe(); 
    this.slug=this.userService.getusername();
    this.employeesService.listEmployee(this.dept,this.slug).pipe(map(this.extractData)
        ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();
    });
  }

	private extractData(res: Response) {
    const body = res;
    return body || {};
  }

}
