import { Component, OnInit, TemplateRef  } from '@angular/core';
import { Router } from "@angular/router";
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService,ModalDirective  } from 'ngx-bootstrap/modal';
import { LeaveRequestService } from '../service/leaverequest.service';
import { UserService } from '../../user/service/user.service';
import { EmployeesService } from '../../employees/service/employees.service';

@Component({
  selector: 'leaveapprove-box',
  templateUrl: './leave-approve.component.html',
  styles: ['.btn { margin: 0 5px; }']   
})

export class LeaveApproveComponent implements OnInit {

  leavetypeDetails;   
  message: string;
  showDelSucessMessage: Boolean;
  del_id:any;
  page:string;
  leave_app : number;
  emp_id : number;
  leaveReqDetails;employeeleaveDetails;numleavetaken;leaveBal;lastLeave;todayDate;logged_user;lastWFH;display_name;date_from;leave_type_id;notes;user_email;userdetails;mail_list;mailusers;mailData;emp_email;date_to;leave_type;excluded_approval_by;show;leave_status;exclude;isActive;lastleave_date;
  millisecondsPerDay:any;
  leavdiffdays:any;
  leavedateDate:any;
  lastLeaveSec:any;
  diff:any;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  sucessMessage: string;
  res_data : any;

  constructor( private leaverequestService: LeaveRequestService, private router: Router,  private modalService: BsModalService, private userService: UserService, private modalRef: BsModalRef,public employeeService: EmployeesService) { }

  ngOnInit() {  
    this.logged_user = this.userService.getUserDtls();
    this.user_email=this.logged_user.user_email;
    this.leaverequestService.getLeaveRequestDetails(this.emp_id,this.leave_app).subscribe(
      res => {
        this.leaveReqDetails = res[0]; 
        this.display_name = this.leaveReqDetails.display_name;
        this.date_from    = this.leaveReqDetails.emp_leave_req[0].date_from; 
        this.date_to      = this.leaveReqDetails.emp_leave_req[0].date_to; 
        this.exclude      = this.leaveReqDetails.emp_leave_req[0].exclude_managers; 
        if(this.exclude=='true'){
           this.isActive = true;
        }else{
           this.isActive = false;
        }
        this.emp_email    = this.leaveReqDetails.email;
        

        if(this.leaveReqDetails.lastLeave.length>0) {
          this.lastLeave    = this.leaveReqDetails.lastLeave;


          if(this.lastLeave[0].date_to) {
            this.lastleave_date = this.lastLeave[0].date_to;
          } else {
            this.lastleave_date = this.lastLeave[0].date_from;  
          }
          var date1:any = new Date(this.lastleave_date);
          var date2:any = new Date();
          var diffDays:any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));


          this.leavdiffdays = diffDays;
        } else {
          this.lastLeave    = '';
          this.leavdiffdays = 0;
        }
        console.log(this.leavdiffdays)
        console.log(this.lastleave_date)
        this.lastWFH      = this.leaveReqDetails.lastWFH;          
           
        this.leave_status = this.leaveReqDetails.emp_leave_req[0].leave_status;
        if(this.leave_status=='0' || this.leave_status=='1' || this.leave_status=='2'){
           this.show=true;
        }
        this.leave_type_id = this.leaveReqDetails.emp_leave_req[0].leave_type_id;
        this.leave_type = this.leaveReqDetails.leavedetails[0].type_name;
        this.notes = this.leaveReqDetails.emp_leave_req[0].notes;
        this.mail_list = this.leaveReqDetails.emp_leave_req[0].mailusers;
        this.page=this.page;

         

        this.leaverequestService.numLeavesTaken(this.emp_id,this.leave_type_id).subscribe(
            res => {
                this.employeeleaveDetails = res; 
                this.leaverequestService.getLeaveByID(this.leave_type_id).subscribe(
                  res => {                        
                    if(this.employeeleaveDetails>0) {
                      this.numleavetaken = this.employeeleaveDetails[0]['num_leave'];              
                      this.leaveBal = res['totalleaves'] - this.employeeleaveDetails[0]['num_leave'];
                    } else {
                      this.numleavetaken = 0;
                      this.leaveBal = res['totalleaves'];
                    }                             
                  },
                  err => { 
                      console.log(err);          
                  }
                )        
            },
            err => { 
                console.log(err);          
            }
          )                          
      },
      err => { 
          console.log(err);          
      }
    )
  }

onSubmit(form: NgForm) {
      
        let emp_id       = form.value["emp_id"];
        let display_name = form.value["display_name"];
        let user_email   = form.value["user_email"];
        let mail_list    = form.value["mail_list"];
        let leave_app    = form.value["leave_app"];
        let page         = form.value["page"];
        let exculde      = form.value["exculde"];


       if(this.logged_user.admin_access==true) {
        var hrapprove = true;
        var leaveStatus = 2;
        var managertype= 'HR';
        var hr_approved_date= new Date();
      } else {
        var lineapprove = true;
        var leaveStatus = 1;
        var managertype= 'Line Manager';
        var linemanager_approved_date= new Date();
      }
      this.mailusers= JSON.parse(mail_list);
     
      this.mailusers.splice(this.mailusers.indexOf(this.user_email), 1); //delete the current user from mail list
      this.mailusers.splice(this.mailusers.indexOf(this.emp_email), 1); //delete the current user from mail list

      if(exculde==true){
         var leaveStatus = 3;
         this.excluded_approval_by = this.userService.getUserID();
      }

      let res_data = {empid:emp_id,leaveapp:leave_app,leave_status:leaveStatus,hr_approve:hrapprove,exclude_managers:exculde,line_approve:lineapprove,excluded_by:this.excluded_approval_by,hr_approved_date:hr_approved_date,linemanager_approved_date:linemanager_approved_date};
   
      this.leaverequestService.leaveRequestResponse(res_data).subscribe(
            res => {          
              this.showSucessMessage = true;
              this.sucessMessage = 'Leave Approved'; 
               setTimeout(() => {
                    this.showSucessMessage = false;
                    this.modalService.hide(1);
                    if(page=='empleave'){
                       this.router.navigateByUrl('/list-leave-request');
                    }else{
                       this.router.navigateByUrl('/leave-approval-pending');
                    }
                }, 4000);
            },
            err => {         
              if (err.status === 422) {
                this.serverErrorMessages = err.error.join('<br/>');
              }
              else
                this.serverErrorMessages = 'Something went wrong.Please contact admin.'; 
                this.modalService.hide(1);
            }
      ); 

          //mail to Employee

          var employeesubject= 'Leave Request Approved by '+ managertype;
          let mail_msg ='Hi this.display_name, <br/><br/>';
          mail_msg +='Your Leave Request approved by '+ managertype + '.<br/><br/>';
          this.mailData = {'to' :this.emp_email,'from':this.user_email,'subject':subject,'text':mail_msg};
          this.employeeService.sendMail(this.mailData).subscribe(
            res => {
             console.log(res); 
            },
            err => { 
              console.log(err);            
            }
          );


          //Mail to Manager
          var subject= 'Leave Request Approved by '+ managertype;
          let mailmsg ='Hi, <br/><br/>';
          mailmsg += this.display_name+' Leave Request approved by '+ managertype + '.<br/><br/>';
          this.mailData = {'to' :this.mailusers,'from':this.user_email,'subject':subject,'text':mailmsg};
          this.employeeService.sendMail(this.mailData).subscribe(
            res => {
             console.log(res); 
            },
            err => { 
              console.log(err);            
            }
          );

      

}
  approve(emp_id,display_name,user_email,mail_list,leave_app,page): void {     
      if(this.logged_user.admin_access=='true') {
        var hrapprove = true;
        var leaveStatus = 2;
        var managertype= 'HR';
      } else {
        var lineapprove = true;
        var leaveStatus = 1;
        var managertype= 'Line Manager';
      }
      this.mailusers= JSON.parse(mail_list);
      this.mailusers.splice(this.mailusers.indexOf(this.user_email), 1); //delete the current user from mail list
      this.mailusers.splice(this.mailusers.indexOf(this.emp_email), 1); //delete the current user from mail list

      let res_data = {empid:emp_id,leaveapp:leave_app,leave_status:leaveStatus,hr_approve:hrapprove,line_approve:lineapprove};
   
      this.leaverequestService.leaveRequestResponse(res_data).subscribe(
            res => {          
              this.showSucessMessage = true;
              this.sucessMessage = 'Leave Approved'; 
               setTimeout(() => {
                    this.showSucessMessage = false;
                    this.modalService.hide(1);
                    if(page=='empleave'){
                       this.router.navigateByUrl('/list-leave-request');
                    }else{
                       this.router.navigateByUrl('/leave-approval-pending');
                    }
                }, 4000);
            },
            err => {         
              if (err.status === 422) {
                this.serverErrorMessages = err.error.join('<br/>');
              }
              else
                this.serverErrorMessages = 'Something went wrong.Please contact admin.'; 
                this.modalService.hide(1);
            }
      ); 

          //mail to Employee

          var employeesubject= 'Leave Request Approved by '+ managertype;
          let mail_msg ='Hi, <br/><br/>';
          mail_msg +='Your Leave Request approved by '+ managertype + '.<br/><br/>';
          this.mailData = {'to' :this.mailusers,'from':this.user_email,'subject':subject,'text':mail_msg};
          this.employeeService.sendMail(this.mailData).subscribe(
            res => {
             console.log(res); 
            },
            err => { 
              console.log(err);            
            }
          );


          //Mail to Manager
          var subject= 'Leave Request Approved by '+ managertype;
          let mailmsg ='Hi, <br/><br/>';
          mailmsg += this.display_name+' Leave Request approved by '+ managertype + '.<br/><br/>';
          this.mailData = {'to' :this.mailusers,'from':this.user_email,'subject':subject,'text':mailmsg};
          this.employeeService.sendMail(this.mailData).subscribe(
            res => {
             console.log(res); 
            },
            err => { 
              console.log(err);            
            }
          );
  }
 
  reject(emp_id,display_name,emp_email,mail_list,leave_app): void {

  if(this.logged_user.emp_role=='HR') {
        var managertype= 'HR';
      } else {
        var managertype= 'Line Manager';
      }

    this.mailusers= JSON.parse(mail_list);
    this.mailusers.splice(this.mailusers.indexOf(this.user_email), 1); //delete the current user from mail list
    this.mailusers.splice(this.mailusers.indexOf(this.emp_email), 1); //delete the current user from mail list
   
    var leaveStatus = 4;
    let res_data = {empid:emp_id,leaveapp:leave_app,leave_status:leaveStatus};
   
    this.leaverequestService.leaveRequestResponse(res_data).subscribe(
        res => {            
          this.showSucessMessage = true;
          this.sucessMessage = 'Leave Rejected'; 
            setTimeout(() => {
                  this.showSucessMessage = false;
                  this.modalService.hide(1);
                  this.router.navigateByUrl('/list-leave-request');
            }, 4000);             
        },
        err => {           
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.'; 
            this.modalService.hide(1);
        }
    ); 

      //mail to Employee

          var employeesubject= 'Leave Request Rejected by '+ managertype;
          let mail_msg ='Hi, <br/><br/>';
          mail_msg +='Your Leave Request Rejected by '+ managertype + '.<br/><br/>';
          this.mailData = {'to' :this.mailusers,'from':this.user_email,'subject':subject,'text':mail_msg};
          this.employeeService.sendMail(this.mailData).subscribe(
            res => {
             console.log(res); 
            },
            err => { 
              console.log(err);            
            }
          );


          //Mail to Manager
          var subject= 'Leave Request Rejected by '+ managertype;
          let mailmsg ='Hi, <br/><br/>';
          mailmsg += this.display_name+' Leave Request Rejected by '+ managertype + '.<br/><br/>';
          this.mailData = {'to' :this.mailusers,'from':this.user_email,'subject':subject,'text':mailmsg};
          this.employeeService.sendMail(this.mailData).subscribe(
            res => {
             console.log(res); 
            },
            err => { 
              console.log(err);            
            }
          );
  }

  closeM() : void {
    this.modalService.hide(1);
  }

}
