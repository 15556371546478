<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
         
   
              
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i> {{ title }}</h3>
                        </div> 
                        <div class="card-body">
                            <div class="card pull-left card-margin">
                              <div class="fa-hover col-md-12 col-sm-12">
                                <img class="card-img-top" src="../assets/images/avatars/employees.png" alt="Card image cap">
                              </div>
                              <div class="card-body">
                                <h5 class="card-title">Employee Info</h5>
                              </div>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item"><a href="age-profile" class="card-link">Reporting Person</a></li>
                                <li class="list-group-item"><a href="age-profile" class="card-link">Age Profile</a></li>
                                <li class="list-group-item"><a href="birthdays" class="card-link">Birthdays</a></li>
                                <li class="list-group-item"><a href="employee-status-history" class="card-link">Employement Status</a></li>
                                <li class="list-group-item"><a href="gender-reports" class="card-link">Gender</a></li>
                                <li class="list-group-item"><a href="#" class="card-link">Job History</a></li>
                                <li class="list-group-item"><a href="years-service" class="card-link">Years of Service</a></li>
                              </ul>
                            </div> 
                            <div class="card pull-left card-margin" >
                              <div class="fa-hover col-md-12 col-sm-12">
                                <img class="card-img-top" src="../assets/images/avatars/att.png" alt="Card image cap">
                              </div>
                              <div class="card-body">
                                <h5 class="card-title">Attendance Summary</h5>
                              </div>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item"><a href="daily-attendance" class="card-link">Daily Attendance</a></li>
                                <li class="list-group-item"><a href="monthly-attendance" class="card-link">Attendance By Month</a></li>
                                <!--<li class="list-group-item"><a href="#" class="card-link">Attendance By Week</a></li>-->
                              </ul>
                            </div>
                            <div class="card pull-left card-margin" >
                              <div class="fa-hover col-md-12 col-sm-12">
                                <img class="card-img-top" src="../assets/images/avatars/leave.png" alt="Card image cap">
                              </div>
                              <div class="card-body">
                                <h5 class="card-title">Employee Time Off</h5>
                              </div>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item"><a href="timeoff-balances" class="card-link">Time Off Balances</a></li>
                                <li class="list-group-item"><a href="timeoff-used" class="card-link">Time Off Used</a></li>
                                <li class="list-group-item"><a href="#" class="card-link">Time Off Schedule</a></li>
                              </ul>
                            </div>
                            <div class="card pull-left card-margin" >
                              <div class="fa-hover col-md-12 col-sm-12">
                                <img class="card-img-top" src="../assets/images/avatars/leaves.png" alt="Card image cap">
                              </div>
                              <div class="card-body">
                                <h5 class="card-title">Additions & Leaves</h5>
                              </div>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item"><a href="#" class="card-link">Headcount</a></li>
                                <li class="list-group-item"><a href="#" class="card-link">New Employees</a></li>
                                <li class="list-group-item"><a href="#" class="card-link">Terminations</a></li>
                              </ul>
                            </div> 
                        </div>                        
                    </div>
                </div>
            </div>  

             


        </div>
    </div>
</div>

