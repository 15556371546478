import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Employees, EmployeesLeaveReq } from './employees.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EmployeesService {
  addEmployees: Employees = {
    fullName     : '',
    display_name : '',
    gender       : '',
    dob          : new Date(),
    email        : '',
    address      : '',
    locality     : '',
    password     : '',
    emp_code     : '',
    joining_date : new Date(),
    chat_name    : '',
    department   : '',
    role         : '',
    description  : '',
    manager      : '',
    hrmanager    : '',
    status       : '',
    employee_type: '',
    reporting_person: false,
    admin_access : false,
    pincode: '',
  };

  leaveRequest: EmployeesLeaveReq = {
    leave_type_id: '',    
    leave_mode: 1,    
    days_no: 1,    
    date_from: new Date(),
    date_to:new Date(),    
    pre_approved: false,    
    hr_approve: false,    
    line_approve: false,    
    pre_approved_by: '',    
    notes: '',    
    leave_status: 0,    
    added_date: new Date(),
    updated_date: new Date(),
    exclude_managers: false,
    exclude_by: '',
    hr_approved_date: '',
    linemanager_approved_date: '', 
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  //HttpMethods

  postUser(employees: Employees){
    return this.http.post(environment.apiBaseUrl+'/register',employees,this.noAuthHeader);
  }

  listEmployee(dept,slug) {
    return this.http.get(environment.apiBaseUrl + '/listEmployees/'+slug+'/'+dept);
  }

  editEmployee(id) {
    return this.http.get(environment.apiBaseUrl + '/editEmployees/'+id);
  }

  UpdateEmployees(employees: Employees){
    return this.http.post(environment.apiBaseUrl + '/updateEmployees',employees,this.noAuthHeader);
  }

  deleteEmployee(id){ 
    this.http.get(environment.apiBaseUrl + '/deleteEmployees/'+id, {observe: 'response'})
      .subscribe(resp => {
      console.log(resp.headers.get('X-Token'));
    });
  }

  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userProfile');
  }

  getUserAvatar() {
    return this.http.get(environment.apiBaseUrl + '/images');
  }

  listEmployeeAttendance(id) {
    return this.http.get(environment.apiBaseUrl + '/listEmployeeAttendance/'+id);
  }

  listRoles(slug) {
    return this.http.get(environment.apiBaseUrl + '/listRoles/'+slug);
  }
  getusername() {    
    return localStorage.getItem('slug');
  }

  postFile(fileToUpload: File){    
  }

  UploadAvatar(fileToUpload: File) {
  }

  getRepPersonByDept(dept,slug) {
    return this.http.get(environment.apiBaseUrl + '/getRepPersonByDept/'+dept+'/'+slug);
  }

  getLeaveTypes(slug) {
    return this.http.get(environment.apiBaseUrl + '/listLeavetypes/'+slug);
  }

  postLeaveRequest(leaveReq: EmployeesLeaveReq){
    return this.http.post(environment.apiBaseUrl + '/postLeaveRequest',leaveReq,this.noAuthHeader);
  }

  sendMail(mailData){
    return this.http.post(environment.apiBaseUrl+'/sendMail',mailData,this.noAuthHeader);
  }

  isLeaveDateExist(leave_dates){
    return this.http.post(environment.apiBaseUrl+'/isLeaveDateExist',leave_dates,this.noAuthHeader);
  }

  getUserDetail(id) {
    var userDetail = this.http.get(environment.apiBaseUrl + '/editEmployees/'+id);
    userDetail.pipe(
        map(res =>  {                      
                      console.log(res[0].manager.fullName)
                 })
    );
  }

  searchEmployees(employees: Employees){
    return this.http.post(environment.apiBaseUrl+'/searchEmployees',employees,this.noAuthHeader);
  }

  getRepPersonByDeptHR(slug){
     return this.http.get(environment.apiBaseUrl + '/getRepPersonByDeptHR/'+slug);
  }

  getRepPersonByDeptManager(dept,slug){
     return this.http.get(environment.apiBaseUrl + '/getRepPersonByDeptManager/'+dept+'/'+slug);
  }

  changePassword(employees: Employees){
    return this.http.post(environment.apiBaseUrl + '/changePassword',employees,this.noAuthHeader);
  }

   getUserRole() {
    const current_user = JSON.parse(localStorage.getItem("current_user"))
    if(current_user){
      return current_user.user_role;
    }
  } 

  getUserDtls() {
    var  current_user = JSON.parse(localStorage.getItem("current_user"))
    return current_user;
  }

  listEmployeeType(slug) {
    return this.http.get(environment.apiBaseUrl + '/listEmployement/'+slug);
  }

}
