<div class="wrapper">
  <div id="formContent">
  	<h2 class="underlineHover"  routerLinkActive="active"> Forgot PAssword </h2>
  	<div>
    <img src="../../assets/img/single_user.png" id="icon" alt="User Icon" />
  </div>
  <form #signInForm="ngForm" (ngSubmit)="signInForm.valid && onSubmit(signInForm)">
    <input type="text" name="email" #email="ngModel" [(ngModel)]="model.email" placeholder="Email" [pattern]="emailRegex" required
      [ngClass]="{'invalid-textbox' :signInForm.submitted && !email.valid }">
    <div *ngIf="signInForm.submitted && email.errors?.pattern">
      <label class="validation-message">Invalid email address.</label>
    </div>
    <a class="forgot" [routerLink]="['/login']">Login</a>

    <input type="submit" value="Send">
  </form>
  <!-- Error message -->
<div class="alert" *ngIf="serverErrorMessages">
    {{serverErrorMessages}}
  </div>
  <!-- Success message --> 
  
<div class="success" *ngIf="showSucessMessage">
  Password Reset Mail send to your requested mail id.
</div>


    </div>
      </div>