import { Component, OnInit, OnDestroy, TemplateRef  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { LeaveTypesService } from '../service/leavetypes.service'
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-list-leavetypes',
  templateUrl: './list-leavetypes.component.html',
  styleUrls: ['./list-leavetypes.component.css']
})

export class ListLeaveTypesComponent implements OnDestroy, OnInit {
  title='List Leave Types';
	leavetypeDetails;slug;showDelSucessMessage;serverErrorMessages;showSucessMessage;
  modalRef: BsModalRef;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor( private leavetypesService: LeaveTypesService, private router: Router, private modalService: BsModalService, private http: HttpClient, private titleService: Title) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.slug = this.leavetypesService.getusername();
    this.leavetypesService.listLeavetypes(this.slug).pipe(map(this.extractData)
      ).subscribe(leavetypeDetails => {
        this.leavetypeDetails = leavetypeDetails;
        console.log(this.leavetypeDetails);
        this.dtTrigger.next();
    });
  }  

  deleteLeavetype( id) {
    const initialState = {
            del_id: id,
            page: 'leave_type',
        };
    this.modalRef = this.modalService.show(ConfirmBoxComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }
  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
