<div class="content-page">
    <div class="content">            
        <div class="container-fluid"> 
            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                                      
                <div class="col-12">                       
                    <div class="card">
                        <div class="card-header">
                           <h3><i class="fa fa-users"></i> Timeoff Balances</h3>
                        </div>
                        <div class="card-body">
                            <div class="report-filters">
                                <form #serachFilterForm="ngForm" (ngSubmit)="serachFilterForm.valid && onSubmit(serachFilterForm)" autocomplete="off">
                                    <div class="row rf-margin-bot">                                        
                                        <div class="col-lg-3 ">
                                            <label>Select by Leavetype:</label>
                                            <select class="select2 form-control custom-select" #leavetype="ngModel" [(ngModel)]="def_leavtype" name="leavetype">
                                                <option *ngFor="let leavetype of leaveTypesList" value="{{leavetype._id}}">{{leavetype.type_name}} - {{leavetype.employementname[0].employement_name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 ">
                                            <label>Select by Department:</label>
                                            <select class="select2 form-control custom-select" #department="ngModel" [(ngModel)]="reportFilters.department" name="department">
                                                <option value="">Select Department</option>
                                                <option *ngFor="let department of departmentDetails" value="{{department._id}}">{{department.department_name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 ">
                                            <label>Select by Role</label>
                                             <select class="select2 form-control custom-select" #role="ngModel" [(ngModel)]="reportFilters.role" name="role">
                                                <option value="">Select Role</option>   
                                                <option  *ngFor="let role of roleDetails" value="{{role._id}}">{{role.role_name}}</option>                             
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <button type="button" (click)="clearFilter(serachFilterForm)" class="btn btn-secondary m-l-5" > Reset </button>
                                    &nbsp;&nbsp;<input type="submit" role="button" class="btn btn-primary" value="Search">
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="table-responsive">
                                <table id="example2" class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  >
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Begining Balance</th>
                                            <th>Used</th>                                            
                                            <th>Ending Balance</th>
                                            <th>Job Title</th>
                                            <th>Department</th> 
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr *ngFor="let employee of employeeLeaves" id="row{{employee._id}}">
                                            <td>{{employee.fullName}}</td>
                                            <td>{{employee.begining_bal}}</td>
                                            <td>{{employee.used_leave}}</td>
                                            <td>{{employee.end_bal}}</td>
                                            <td>{{employee.roles[0].role_name}}</td>
                                            <td>{{employee.departmentname[0].department_name}}</td>
                                        </tr>
                                        
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>Begining Balance</th>
                                            <th>Used</th>                                            
                                            <th>Ending Balance</th>
                                            <th>Job Title</th>
                                            <th>Department</th>  
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
         </div>
    </div>
</div>                                
                               

