import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Employees, LeaveRequest } from './leaverequest.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeaveRequestService {

  leaveRequest: LeaveRequest = {
    leave_type_id: '',    
    leave_mode: 1,    
    days_no: 1,    
    date_from: new Date(),
    date_to:new Date(),    
    pre_approved: false,    
    hr_approve: false,    
    line_approve: false,    
    pre_approved_by: '',    
    notes: '',    
    leave_status: 0,    
    added_date: new Date(),
    updated_date: new Date(),
  };

  addEmployees: Employees = {
    fullName: '',
    display_name: '',
    gender: '',
    dob: new Date(),
    email: '',
    address: '',
    locality: '',
    password: '',
    emp_code: '',
    joining_date: new Date(),
    chat_name: '',
    department:'',
    role: '',
    description: '',
    manager: '',
    hrmanager: '',
    status: '',
    employee_type: '',
    reporting_person: false,
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  //HttpMethods
  listLeaveRequest(dept,slug) {
    return this.http.get<any[]>(environment.apiBaseUrl + '/listLeaveRequest/'+slug+'/'+dept);
  }

  getEmployee(id) {
    return this.http.get(environment.apiBaseUrl + '/getEmployee/'+id);
  }

  numLeavesTaken(id,leave_type) {
    return this.http.get(environment.apiBaseUrl + '/numLeavesTaken/'+id+'/'+leave_type);
  }

  getLeaveByID(leave_type) {
    console.log(leave_type)
    return this.http.get(environment.apiBaseUrl + '/getLeaveByID/'+leave_type);
  }

  getLeaveRequestDetails(emp_id,leave_id) {
    return this.http.get(environment.apiBaseUrl + '/getLeaveRequest/'+emp_id+'/'+leave_id);
  }

  leaveRequestResponse(resp_data) {
    return this.http.post(environment.apiBaseUrl + '/leaveRequestResponse',resp_data,this.noAuthHeader);
  }
  
  listLeaveApprovalPending(slug) {
    return this.http.get<any[]>(environment.apiBaseUrl + '/listLeaveApprovalPending/'+slug);
  }

  getEmployees() {
    return this.http.get(environment.apiBaseUrl + '/listEmployees');
  }
  getEmployeesLeaves(id) {
    return this.http.get(environment.apiBaseUrl + '/getEmployeesLeaves/'+id);
  }

  listEmployeeLeaveTypes(id) {
    return this.http.get(environment.apiBaseUrl + '/listEmployeeLeaveTypes/'+id);
  }
  
  filterEmployeeLeaves(employeesLeavs: Employees){    
    return this.http.post(environment.apiBaseUrl+'/filterEmployeeLeaves',employeesLeavs,this.noAuthHeader);
  }
  searchEmployees(employees: Employees){
    return this.http.post(environment.apiBaseUrl+'/searchEmployees',employees,this.noAuthHeader);
  }
  searchLeaveApprovalPending(employees: Employees){
    return this.http.post(environment.apiBaseUrl+'/searchLeaveApprovalPending',employees,this.noAuthHeader);
  }
  getusername() {    
    return localStorage.getItem('slug');
  }
  getUserRole() {
    const current_user = JSON.parse(localStorage.getItem("current_user"))
    if(current_user){
      return current_user.user_role;
    }
  }
   getUserDtls() {
    var  current_user = JSON.parse(localStorage.getItem("current_user"))
    return current_user;
  } 
}
