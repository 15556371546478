import { Component, OnInit, TemplateRef  } from '@angular/core';
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveTypesService } from '../admin/leave_types/service/leavetypes.service'
import { AttendanceTypesService } from '../admin/attendance_types/service/attendancetypes.service'
import { DepartmentService } from '../admin/department/service/department.service'
import { EmployeesService } from '../employees/service/employees.service';
import { RoleService } from '../admin/roles/service/role.service';
import { NotificationService } from '../notification/service/notification.service';

@Component({
  selector: 'confirm-box',
  templateUrl: './confirm-box.component.html',
  styles: ['.btn { margin: 0 5px; }']   
})

export class ConfirmBoxComponent implements OnInit {
	leavetypeDetails;   
  message: string;
  showDelSucessMessage: Boolean;
  del_id:any;
  page:string;

  constructor( private leavetypesService: LeaveTypesService,private attendancetypesService: AttendanceTypesService,private departmentService: DepartmentService, private employeesService: EmployeesService, private router: Router, private modalRef: BsModalRef, private modalService: BsModalService,private roleService: RoleService,public notificationService: NotificationService) { }

  ngOnInit() { 
  }

  confirm(del_id,page): void {
    if(page=='leave_type'){
      this.leavetypesService.deleteLeavetype(del_id);
    }
    if(page=='attendance_type'){
      this.attendancetypesService.deleteAttendancetype(del_id);
    }
    if(page=='department'){
      this.departmentService.deleteDepartment(del_id); 
    }  
    if(page=='employee'){
      this.employeesService.deleteEmployee(del_id); 
    }
    if(page=='roles'){
      this.roleService.deleteRoles(del_id); 
    }
    if(page=='notification'){
      this.notificationService.deleteNotification(del_id); 
    }

    document.getElementById('row' + del_id).style.display = "none";
    this.showDelSucessMessage = true;
    this.modalRef.hide();
  }
 
  decline(): void {
    this.modalRef.hide();
    this.showDelSucessMessage = false;
  }

}
