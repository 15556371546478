import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/register/sign-up.component';
import { SignInComponent } from './user/login/sign-in.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { AuthGuard } from './auth/auth.guard';

import { HomeComponent } from './design/home/home.component';
import { AddAttendanceComponent } from './attendance/add-attendance/add-attendance.component';

import { AddEmployeesComponent } from './employees/add-employees/add-employees.component';
import { ListEmployeesComponent } from './employees/list-employees/list-employees.component';
import { EditEmployeesComponent } from './employees/edit-employees/edit-employees.component';

import { ProfileComponent } from './employees/profile/profile.component';
import { ChangePasswordComponent } from './employees/change-password/change-password.component';
import { ForgotComponent } from './user/forgot/forgot.component';
import { ViewEmployeesComponent } from './employees/view-employees/view-employees.component';
import { ResetComponent } from './user/reset/reset.component';

import { AddDepartmentComponent } from './admin/department/add-department/add-department.component';
import { ListDepartmentComponent } from './admin/department/list-department/list-department.component';
import { EditDepartmentComponent } from './admin/department/edit-department/edit-department.component';

import { AddLeaveTypesComponent } from './admin/leave_types/add-leavetypes/add-leavetypes.component';
import { ListLeaveTypesComponent } from './admin/leave_types/list-leavetypes/list-leavetypes.component';
import { EditLeaveTypesComponent } from './admin/leave_types/edit-leavetypes/edit-leavetypes.component';
import { AddAttendanceTypesComponent } from './admin/attendance_types/add-attendancetypes/add-attendancetypes.component';
import { ListAttendanceTypesComponent } from './admin/attendance_types/list-attendancetypes/list-attendancetypes.component';
import { EditAttendanceTypesComponent } from './admin/attendance_types/edit-attendancetypes/edit-attendancetypes.component';
import { ViewDepartmentComponent } from './admin/department/view-department/view-department.component';

import { LeaveRequestComponent } from './employees/leave-request/leave-request.component';
import { ListLeaveRequestComponent } from './leave/list-leaverequest/list-leaverequest.component';
import { EmployeeLeaveComponent } from './leave/employee-leave/employee-leave.component';

import { ListAttendanceComponent } from './attendance/list-attendance/list-attendance.component';
import { EditAttendanceComponent } from './attendance/edit-attendance/edit-attendance.component';

import { AddRolesComponent } from './admin/roles/add-roles/add-roles.component';
import { ListRolesComponent } from './admin/roles/list-roles/list-roles.component';
import { EditRolesComponent } from './admin/roles/edit-roles/edit-roles.component';
import { SummaryComponent } from './attendance/summary/summary.component';
import { SummaryDetailsComponent } from './attendance/summary-details/summary-details.component';

import { AddNotificationComponent } from './notification/add-notification/add-notification.component';
import { EditNotificationComponent } from './notification/edit-notification/edit-notification.component';
import { ListNotificationComponent } from './notification/list-notification/list-notification.component';
import { ViewNotificationComponent } from './notification/view-notification/view-notification.component';
import { LeaveApprovalPendingComponent } from './leave/leave-approval-pending/leave-approval-pending.component';
import { LeaveBalanceComponent } from './leave/leave-balance/leave-balance.component';
import { CronComponent } from './cron/cron.component';
import { NotificationViewStatusComponent } from './notification/notification-view-status/notification-view-status.component';
import { CompanyPoliciesComponent } from './company-policies/company-policies.component';
import { ResetpasswordComponent } from './user/resetpassword/resetpassword.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { Location } from '@angular/common';
import { CalendarComponent } from './notification/calendar/calendar.component';
import { ReportsComponent } from './reports/reports.component';
import { AgeProfileComponent } from './reports/age-profile/age-profile.component';
import { PushNotificationsService} from 'ng-push';
import { PushnotificationComponent } from './pushnotification/pushnotification.component';
import { PushNotificationsModule } from 'ng-push'; 
import { AddEmployementTypesComponent } from './admin/employement_types/add-employement-types/add-employement-types.component';
import { EditEmployementTypesComponent } from './admin/employement_types/edit-employement-types/edit-employement-types.component';
import { ListEmployementTypesComponent } from './admin/employement_types/list-employement-types/list-employement-types.component';
import { MiscellaneousComponent } from './admin/miscellaneous/miscellaneous.component';
import { BirthdaysComponent } from './reports/birthdays/birthdays.component';
import { EmployeeStatusHistoryComponent } from './reports/employee-status-history/employee-status-history.component';
import { GenderReportComponent } from './reports/gender-reports/gender-reports.component';
import { YearsServiceComponent } from './reports/years-of-service/years-of-service.component';
import { DailyAttendanceComponent } from './reports/daily-attendance/daily-attendance.component';
import { MonthlyAttendanceComponent } from './reports/monthly-attendance/monthly-attendance.component';
import { TimeoffBalancesComponent } from './reports/timeoff-balances/timeoff-balances.component';
import { TimeoffUsedComponent } from './reports/timeoff-used/timeoff-used.component';


const routes: Routes = [
    {path: 'signup', component: UserComponent,children: [{ path: '', component: SignUpComponent }] },
    {path: 'login', component: UserComponent,  children: [{ path: '', component: SignInComponent }] },
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'homepage', redirectTo: '/login', pathMatch: 'full'},
    {path: "home", component:HomeComponent},
    {path: "add-employees", component:AddEmployeesComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] } },
    {path: "list-employees", component:ListEmployeesComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] } },
    {path: "edit-employees/:id", component:EditEmployeesComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] }  },
    {path: 'userprofile', component: ProfileComponent,canActivate:[AuthGuard] },
    {path: 'changepassword', component: ChangePasswordComponent,canActivate:[AuthGuard] },
    {path: "view-employees/:id", component:ViewEmployeesComponent,data: { roles: ['Admin'] } },
    {path: "forgot", component:ForgotComponent},
    {path: "resetpassword/:id", component:ResetComponent},

    {path: "add-department", component:AddDepartmentComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] }  },
    {path: "list-department", component:ListDepartmentComponent,canActivate:[AuthGuard] ,data: { roles: ['Admin'] } },
    {path: "edit-department/:id", component:EditDepartmentComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] }  },
    {path: "view-department/:id", component:ViewDepartmentComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] }  },

    {path:"add-leavetypes", component:AddLeaveTypesComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] }  },
    {path:"list-leavetypes", component:ListLeaveTypesComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] }  },
    {path:"edit-leavetypes/:id", component:EditLeaveTypesComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] }  },
    
    {path:"add-attendancetypes", component:AddAttendanceTypesComponent,canActivate:[AuthGuard] ,data: { roles: ['Admin'] } },
    {path:"list-attendancetypes", component:ListAttendanceTypesComponent,canActivate:[AuthGuard] ,data: { roles: ['Admin'] } },
    {path:"edit-attendancetypes/:id", component:EditAttendanceTypesComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] }  },

  //  {path:"view-employees", component:ViewEmployeesComponent},
    {path:"add-attendance", component:AddAttendanceComponent,canActivate:[AuthGuard],data: { roles: ['Admin','User','Manager'] }  },
    {path:"leave-request", component:LeaveRequestComponent,canActivate:[AuthGuard]  },
    {path:"list-leave-request", component:ListLeaveRequestComponent,canActivate:[AuthGuard],data: { roles: ['Admin','User','Manager'] }   },
    {path:"employee-leaves/:id", component:EmployeeLeaveComponent,canActivate:[AuthGuard],data: { roles: ['Admin','User','Manager'] }  },

    {path:"list-attendance", component:ListAttendanceComponent,canActivate:[AuthGuard],data: { roles: ['Admin','User','Manager'] }  },
    {path:"edit-attendance/:id/:userid", component:EditAttendanceComponent,canActivate:[AuthGuard] },

    {path:"add-roles", component:AddRolesComponent,canActivate:[AuthGuard] },
    {path:"list-roles", component:ListRolesComponent,canActivate:[AuthGuard] },
    {path:"edit-roles/:id", component:EditRolesComponent,canActivate:[AuthGuard], },

    {path:"summary", component:SummaryComponent,canActivate:[AuthGuard] },
    {path:"summary-details/:id", component:SummaryDetailsComponent,canActivate:[AuthGuard] },

    {path:"add-notification", component:AddNotificationComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] } },
    {path:"edit-notification/:id", component:EditNotificationComponent,canActivate:[AuthGuard],data: { roles: ['Admin'] } },
    {path:"list-notification", component:ListNotificationComponent,canActivate:[AuthGuard]  },
    {path:"view-notification/:id", component:ViewNotificationComponent,canActivate:[AuthGuard],   },

    {path:"leave-approval-pending", component:LeaveApprovalPendingComponent,canActivate:[AuthGuard],data: { roles: ['Admin','Manager'] }  },
    {path:"leave-balance", component:LeaveBalanceComponent,canActivate:[AuthGuard] },
    {path:"cron", component:CronComponent,canActivate:[AuthGuard] },
    {path:"notification-status/:id", component:NotificationViewStatusComponent,canActivate:[AuthGuard] },
    {path:"company-policy", component:CompanyPoliciesComponent,canActivate:[AuthGuard] },
    {path:"calendar", component:CalendarComponent,canActivate:[AuthGuard] },
    {path:"reports", component:ReportsComponent,canActivate:[AuthGuard] },
    {path:"age-profile", component:AgeProfileComponent,canActivate:[AuthGuard] },
    {path:"pushnotification", component:PushnotificationComponent},
    {path:"add-employement-types", component:AddEmployementTypesComponent},

    {path:"edit-employement-types/:id", component:EditEmployementTypesComponent,canActivate:[AuthGuard] },
    {path:"list-employement-types", component:ListEmployementTypesComponent,canActivate:[AuthGuard] },
    {path:"miscellaneous", component:MiscellaneousComponent,canActivate:[AuthGuard] },
    {path:"birthdays", component:BirthdaysComponent,canActivate:[AuthGuard] },
    {path:"employee-status-history", component:EmployeeStatusHistoryComponent,canActivate:[AuthGuard] },
    {path:"gender-reports", component:GenderReportComponent,canActivate:[AuthGuard] },
    {path:"years-service", component:YearsServiceComponent,canActivate:[AuthGuard] },
    {path:"daily-attendance", component:DailyAttendanceComponent,canActivate:[AuthGuard] },
    {path:"monthly-attendance", component:MonthlyAttendanceComponent,canActivate:[AuthGuard] },
    {path:"timeoff-balances", component:TimeoffBalancesComponent,canActivate:[AuthGuard] },
    {path:"timeoff-used", component:TimeoffUsedComponent,canActivate:[AuthGuard] },
   

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }