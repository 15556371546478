import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../notification/service/notification.service';
import { UserService } from '../user/service/user.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cron',
  templateUrl: './cron.component.html',
  styleUrls: ['./cron.component.css']
})
export class CronComponent implements OnInit {
 currentdate;notificationDetails;mailData;currdate;
  constructor(private notificationService: NotificationService,private userService: UserService,private datePipe: DatePipe) { }

  ngOnInit() {

  this.currdate = this.datePipe.transform(Date.now(),'yyyy-MM-dd');
  
     this.notificationService.listAllNotifications(this.currdate).subscribe(
          res => {
            this.notificationDetails = res;
               let mail_msg = "Hi All, <br/><br/>";
                //mail section
                for(let notification of this.notificationDetails) { 
                  let maillist = [];
                  for(let employee of notification.employees) {                     
                      maillist.push(employee.emp_email);
                  }
                  
                  maillist.toString();

                  mail_msg  += notification.content;
                  mail_msg  += " <br/><br/> Regards ,<br/> Insight Team";
                  this.mailData = {'to':maillist,'from':notification.from_email,'subject':notification.subject,'text':mail_msg};
                  
                  this.userService.sendMail(this.mailData).subscribe(
                    res => {
                     console.log(res); 
                    }, 
                  );
                  this.notificationService.sendNotificationMailstatus(notification._id).subscribe(
                      res => {
                       console.log(res); 
                      }, 
                  );

              }
  
          },          
        );
  }

}