<div class="content-page">
    <div class="content">            
        <div class="container-fluid"> 

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <!-- Success message -->
            <div class="success" *ngIf="showDelSucessMessage">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong> Deleted Successfully</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
            </div>
            <!-- Error message -->
            <div class="alert" *ngIf="serverErrorMessages">
              <!-- Error message -->
                  <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                    <strong> {{serverErrorMessages}}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>  
            </div>

            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>                                
            </div>


            <div class="row">
                <div class="col-12">                       
                    <div class="card">
                         <div class="card-header">
                           <h3><i class="fa fa-users"></i> Attendance Types</h3>
                           <a [routerLink]="['/add-attendancetypes']" (click)="setTitle( 'Add Attendance Types' )" class="pull-right"><button type="button" class="btn btn-primary btn-sm ">Add Attendance Types</button></a>
                        </div>
                        <div class="card-body">
                           
                            <div class="table-responsive">
                               <!-- <table id="zero_config" class="table table-striped table-bordered" *ngIf="attendancetypeDetails"  datatable="ng" [dtOptions]="dtOptions"> -->
                                <table id="example2" class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>BreakTime</th>
                                            <th>Is Default</th>
                                            <th>Status</th>                                               
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let attendancetype of attendancetypeDetails" id="row{{attendancetype._id}}">
                                                <td>{{attendancetype.type_name}}</td>
                                                <td>{{attendancetype.break_time}}</td>
                                                <td>
                                                    <span  *ngIf="attendancetype.is_default;else notdef_content"> Yes </span>
                                                    <ng-template #notdef_content>No</ng-template>
                                                </td> 
                                                <td>
                                                    <span  *ngIf="attendancetype.status;else inactive_content"> Active </span>
                                                    <ng-template #inactive_content>Inactive</ng-template>
                                                </td>                                                
                                                <td>

                                                    <a routerLink="/edit-attendancetypes/{{attendancetype._id}}"><i class="fa fa-pencil medfonts" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Edit" ></i> </a> 
                                                    <a (click)="deleteAttendancetype(attendancetype._id)"><i class="fa fa-trash medfonts" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Delete"></i>  </a>

                                                                                                        
                                                </td>
                                            </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>BreakTime</th>
                                            <th>Is Default</th>
                                            <th>Status</th>                                               
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>






