<div class="content-page">
    <div class="content">            
        <div class="container-fluid"> 
            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Notification Added Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- Error message -->
                                
            </div>
            <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                <strong> {{serverErrorMessages}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 

            <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" autocomplete="off"> 
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">                        
                        <div class="card mb-3">
                            <div class="card-header">
                                <h3><i class="fa fa-check-square-o"></i>Personal Info</h3>
                            </div>                            
                            <div class="card-body">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 notification"> 
                                    <div class="form-group">
                                        <label for="department">Notification Type</label>
                                        <select class="select2 form-control custom-select" #notification_type="ngModel" [(ngModel)]="notificationService.addNotification.notification_type" name="notification_type" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !notification_type.valid }">
                                            <option value="">Select</option>   
                                            <option value="birthday">Birthday</option>
                                            <option value="holiday">Holiday</option>
                                            <option value="announcements">Custom Announcements</option>
                                        </select>  
                                        <div *ngIf="signUpForm.submitted && notification_type.errors">
                                          <label class="validation-message" *ngIf="notification_type.errors.required">Please select the notification type.</label>
                                        </div>  
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 notification"> 
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Fullname</label>
                                        <input type="text"  class="form-control" #name="ngModel" [(ngModel)]="notificationService.addNotification.name" name="name" placeholder="Full Name" required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !name.valid }">
                                        <div *ngIf="signUpForm.submitted && name.errors">
                                          <label class="validation-message" *ngIf="name.errors.required">Please enter the fullname.</label>
                                        </div>                   
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 notification">
                                    <div class="form-group">
                                        <label for="fromname">From Name</label>
                                        <input type="text"  class="form-control" #from_name="ngModel" [(ngModel)]="notificationService.addNotification.from_name" name="from_name" placeholder="Full Name" required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !from_name.valid }">
                                        <div *ngIf="signUpForm.submitted && from_name.errors">
                                          <label class="validation-message" *ngIf="from_name.errors.required">Please enter the from_name.</label>
                                        </div>                   
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 notification">
                                    <div class="form-group">
                                        <label for="from_email">From Email</label>
                                        <input type="text"  class="form-control" #from_email="ngModel" [(ngModel)]="notificationService.addNotification.from_email" name="from_email" placeholder="Email"  required [pattern]="emailRegex"  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !from_email.valid }" autocomplete="off">
                                        <div *ngIf="signUpForm.submitted && from_email.errors">
                                          <label *ngIf="from_email.errors.required" class="validation-message">Email field is required.</label>
                                          <label *ngIf="from_email.errors.pattern" class="validation-message">Please enter a valid email address.</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 notification">
                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Department</label>
                                        <select multiple="" class="select2 form-control select2-hidden-accessible" #department="ngModel" [(ngModel)]="notificationService.addNotification.department" name="department" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !department.valid }">
                                            <option>Select</option>                                          
                                            <option value="all"> All </option>                                          
                                            <option  *ngFor="let department of departmentDetails" value="{{department.department_code}}">{{department.department_name}}</option>                                                                       
                                        </select>  

                                        

                                        <div *ngIf="signUpForm.submitted && department.errors">
                                          <label class="validation-message" *ngIf="department.errors.required">Please select the department.</label>
                                        </div>  
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 notification">
                                    <div class="form-group">
                                        <label for="subject">Subject</label>
                                        <input type="text"  class="form-control" #subject="ngModel" [(ngModel)]="notificationService.addNotification.subject" name="subject" placeholder="Subject" required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !subject.valid }">
                                        <div *ngIf="signUpForm.submitted && subject.errors">
                                          <label class="validation-message" *ngIf="subject.errors.required">Please enter the subject.</label>
                                        </div>                   
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 notification">
                                    <div class="form-group">
                                        <label for="Content">Content</label>
                                        <textarea class="form-control" #content="ngModel" [(ngModel)]="notificationService.addNotification.content" name="content" required rows="5" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !content.valid }"></textarea>
                                        <div *ngIf="signUpForm.submitted && content.errors">
                                          <label class="validation-message" *ngIf="content.errors.required">Please enter the content.</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 notification">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Schedule Mail</label>
                                        <input type="text" class="form-control" #schedule_mail="ngModel" [(ngModel)]="notificationService.addNotification.schedule_mail" name="schedule_mail" placeholder="Schedule mail" required [maxDate]="today" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }"  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !schedule_mail.valid }">
                                        <div *ngIf="signUpForm.submitted && schedule_mail.errors">
                                          <label class="validation-message" *ngIf="schedule_mail.errors.required">Please enter the Schedule Mail date.</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 notification">
                                    <div class="form-group">
                                        <label for="expire_date">Expire Date</label>
                                        <input type="text" class="form-control" #expire_date="ngModel" [(ngModel)]="notificationService.addNotification.expire_date" name="expire_date" placeholder="Expire Date" required [maxDate]="today" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }"  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !expire_date.valid }">
                                        <div *ngIf="signUpForm.submitted && expire_date.errors">
                                          <label class="validation-message" *ngIf="expire_date.errors.required">Please enter the Expire Date.</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 notification">

                                    <div class="form-group">
                                      <button type="submit" class="btn btn-primary">Submit</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
