import { Component, OnInit, ElementRef, ViewChild,Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttendanceService } from '../service/attendance.service'
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
 
@Component({
  selector: 'app-edit-attendance',
  templateUrl: './edit-attendance.component.html',
  styleUrls: ['./edit-attendance.component.css']
})
export class EditAttendanceComponent implements OnInit {

  
  attendanceTypesList;id;attendanceEdit;attend_dates;slug;bsInlineValue;
  title='Edit Attendance';
  showSucessMessage: boolean;
  serverErrorMessages: string;
  attendanceForm: FormGroup;
  data: string;
  timeout_data: string;
  timein_data: string;
  loggedUserId: string;
   

  constructor(public attendanceService: AttendanceService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private route: ActivatedRoute) {
  }

  ngOnInit() {
     if(!this.userService.isLoggedIn()){
       this.router.navigateByUrl('/login');
     }

     this.attendanceForm = this.formBuilder.group({
          attend_date       : ['', Validators.required],
          time_in           : ['', Validators.required],
          time_out          : ['', Validators.required],
          attend_type_id    : ['', Validators.required],
      });

      this.id = this.route.snapshot.paramMap.get('id');
      this.loggedUserId = this.userService.getUserID();                 
      
      this.slug=this.userService.getusername();
      this.attendanceService.getAttendanceTypes(this.slug).subscribe(
        res => {
          this.attendanceTypesList = res;                      
        },
        err => { 
          console.log(err);            
        }
      );
      this.attendanceService.getAttendanceDetails(this.id).subscribe(
        res => {
        console.log(res); 
          this.attendanceEdit = res[0]; 
         },
        err => { 
          console.log(err);            
        }
      );
  }

  onValueChange(att_date: Date): void {  
  }

  onTimeInValueChange(timeIn): void {
    let selDateIn = this.attend_dates.value;  
    selDateIn.setHours(timeIn.getHours(), timeIn.getMinutes(), timeIn.getSeconds());
    this.timein_data = selDateIn;
  }

  onTimeOutValueChange(timeOut): void {    
    let selDateout = this.data;
    this.timeout_data = selDateout;
  }

  onSubmit(form: NgForm) {
    let selDate = form.value["attend_date"];
    let selTimeIn = form.value["time_in"];
    let selTimeOut = form.value["time_out"];
    form.value["newtimein"] =selTimeIn; 
    form.value["newtimeOut"] =selTimeOut; 
    var newtimein  = new Date(selTimeIn);
    var newtimeout = new Date(selTimeOut);
    var diffMs     = (newtimeout.getTime() - newtimein.getTime());
    var diffHrs    = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins   = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    form.value["slug"]   = this.userService.getusername();
    form.value["total_hours"] =diffHrs +":"+ diffMins; 

      this.attendanceService.postupdateAttendance(form.value).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => {
              this.showSucessMessage = false;
             // this.router.navigateByUrl('/add-attendance');
          }, 4000);         
        },
        err => {
        }
      );  
  }

}
