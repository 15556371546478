import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../user/service/user.service';
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { RoleService } from '../service/role.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-list-roles',
  templateUrl: './list-roles.component.html',
  styleUrls: ['./list-roles.component.css']
})

export class ListRolesComponent implements OnInit {
    title='List Roles';
    roleDetails;slug;showDelSucessMessage;serverErrorMessages;showSucessMessage;
    modalRef: BsModalRef;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    constructor(private roleService: RoleService, private router: Router, private modalService: BsModalService, private http: HttpClient, private titleService: Title) { }

    ngOnInit(): void {
	    this.dtOptions = {
		      pagingType: 'full_numbers',
		      pageLength: 10
		};
		this.slug=this.roleService.getusername();
	    this.roleService.listRoles(this.slug).pipe(map(this.extractData)
	      ).subscribe(roleDetails => {
	        this.roleDetails = roleDetails;
	        console.log(this.roleDetails);
	        this.dtTrigger.next();
	    });
	}

	private extractData(res: Response) {
	    const body = res;
	    return body || {};
	}

	deleteRoles( id) {
	    const initialState = {
	            del_id: id,
	            page: 'roles',
	        };
	    this.modalRef = this.modalService.show(ConfirmBoxComponent, { initialState });
	    this.modalRef.content.closeBtnName = 'Close';
	}

	setTitle( newTitle: string) {
	    this.titleService.setTitle( newTitle );
	  }

}
