<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <!-- Success message -->
            <div class="success" *ngIf="showDelSucessMessage">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong> Deleted Successfully</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
            </div>
            <!-- Error message -->
            <div class="alert" *ngIf="serverErrorMessages">
              <!-- Error message -->
                  <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                    <strong> {{serverErrorMessages}}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>  
            </div>

            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Employee Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>                                
            </div>


            <div class="row">
                <div class="col-12">                               
                    <div class="card"> 
                        <div class="card-header">
                           <h3><i class="fa fa-users"></i> Employee Leave Request </h3>
                        </div>

                        <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" autocomplete="off">

                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="attendanceSearch.employeeName" name="employeeName" placeholder="Employee Name">
                                        </div>                                
                                        <div class="form-group">
                                            <select class="se lect2 form-control custom-select"  [(ngModel)]="attendanceSearch.department" name="department">
                                            <option value="">Select</option>                                       
                                            <option  *ngFor="let department of departmentDetails" value="{{department.department_code}}">{{department.department_name}}</option>                                                         
                                    </select>  

                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="attendanceSearch.employeeEmail" name="employeeEmail" placeholder="Employee Email">
                                        </div>
                                        <div class="form-group">
                                            <label></label>
                                            <input type="submit" role="button" class="btn btn-primary" value="Search">
                                            &nbsp;&nbsp;
                                            <button  type="button" (click)="clearFilter(signUpForm)"  class="btn btn-primary" > Reset </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>


                        <div class="card-body"> 
                            <table datatable [dtOptions]="dtOptions" class="row-border hover"></table>                
                            <div class="table-responsive">
                                <table id="example2" class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                    <thead>
                                        <tr>
                                            <th>Full name</th>
                                            <th>Department</th>                                          
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let employee of employeesList" id="row{{employee._id}}">
                                            <td>{{employee.fullName}}</td>
                                            <td>{{employee.departmentname[0].department_name}}</td>
                                            <td >
                                               <span *ngIf="employee.emp_leave_req.length > 0"> 
                                               <a routerLink="/employee-leaves/{{employee._id}}">Leave Details</a>
                                                </span>
                                            </td>                                                
                                        </tr>
                                        <tr *ngIf="employeesList?.length == 0"  align="center">
                                            <td colspan="3" class="no-data-available">No data Found!</td>
                                        </tr> 
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Full name</th>
                                            <th>Department</th>                                          
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>