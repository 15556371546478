import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { LeaveRequestService } from '../service/leaverequest.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveApproveComponent } from '../leave-approve/leave-approve.component';
import { UserService } from '../../user/service/user.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DepartmentService } from '../../admin/department/service/department.service';

@Component({
  selector: 'app-leave-balance',
  templateUrl: './leave-balance.component.html',
  styleUrls: ['./leave-balance.component.css']
})
export class LeaveBalanceComponent implements OnInit {

    title='Employee Leave';
    employeeDetails;id;employeeleaveDetails;leavebalance=[];leaverequest;leavedetails;attendanceSearch;departmentDetails;employeesList;slug;showDelSucessMessage;
    modalRef: BsModalRef;   
    showSucessMessage: boolean;
    serverErrorMessages: string;
    sucessMessage: string;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    constructor(private leaverequestService: LeaveRequestService, private route: ActivatedRoute,private router: Router, private modalService: BsModalService, private userService: UserService,private departmentService: DepartmentService) { }

    ngOnInit() {
	    this.dtOptions = {
	        pagingType: 'full_numbers',
	        pageLength: 10
	    };
        this.slug=this.userService.getusername();
	    this.attendanceSearch={employeeName:'',department:'',employeeEmail:''}; 
        this.departmentService.listEmployeesDepartment(this.slug).subscribe(
	        res => {
	          this.departmentDetails = res;
	        }
	    );

	    this.leaverequestService.getEmployees().pipe(map(this.extractData)).subscribe(
	        res => {
	            this.employeeDetails  = res;
	            for(let empdetails of this.employeeDetails) {
	                this.leaverequest = empdetails.emp_leave_req;
                    let i = 0;let j = 0;
	  	            for(let leave of this.leaverequest) {
	  	            	if(leave.leave_status==5 && leave.leave_type_id=='Casual Leave'){
	  		                i=i+1;
	  		            }else if(leave.leave_status==5 && leave.leave_type_id=='Sick Leave'){
                            j=j+1;
	  		            }
	      			}
	      			let leavebal =  {fullName: empdetails.fullName,department: empdetails.department, casual_leave: i,sick_leave: j};
  		            this.leavebalance.push(leavebal);	      			
		        } 
		        this.dtTrigger.next();
	        }
	    );
    }


    onSubmit(form: NgForm) {

        this.leavebalance=[];
	    this.dtOptions = {
	        pagingType: 'full_numbers',
	        pageLength: 10,
	        retrieve: true,
	    };
	   
	    this.dtTrigger.unsubscribe(); 
	     this.leaverequestService.searchEmployees(form.value).pipe(map(this.extractData)
	      ).subscribe(employeesDetails => {     
        
	            this.employeeDetails  = employeesDetails;
	            for(let empdetails of this.employeeDetails) {
	                this.leaverequest = empdetails.emp_leave_req;
                    let i = 0;let j = 0;
	  	            for(let leave of this.leaverequest) {
	  	            	if(leave.leave_status==5 && leave.leave_type_id=='Casual Leave'){
	  		                i=i+1;
	  		            }else if(leave.leave_status==5 && leave.leave_type_id=='Sick Leave'){
                            j=j+1;
	  		            }
	      			}
	      			let leavebal= {fullName: empdetails.fullName,department:empdetails.department,casual_leave:i,sick_leave: j};
  		            this.leavebalance.push(leavebal);	      			
		        }
	        this.dtTrigger.next();
	    });
	}


	clearFilter(form: NgForm) {
	    this.attendanceSearch={employeeName:'',department:'',employeeEmail:''}; 
	    this.leavebalance = [];
	    this.dtTrigger.unsubscribe();
	    this.leaverequestService.getEmployees().pipe(map(this.extractData)).subscribe(
	        res => {
	            this.employeeDetails  = res;
	            for(let empdetails of this.employeeDetails) {
	                this.leaverequest = empdetails.emp_leave_req;
                    let i = 0;let j = 0;
	  	            for(let leave of this.leaverequest) {
	  	            	if(leave.leave_status==5 && leave.leave_type_id=='Casual Leave'){
	  		                i=i+1;
	  		            }else if(leave.leave_status==5 && leave.leave_type_id=='Sick Leave'){
                            j=j+1;
	  		            }
	      			}
	      			let leavebal =  {fullName: empdetails.fullName,department: empdetails.department, casual_leave: i,sick_leave: j};
  		            this.leavebalance.push(leavebal);	      			
		        } 
		        this.dtTrigger.next();
	        }
	    );
  	}

	ngOnDestroy(): void {
	    this.dtTrigger.unsubscribe();
	}

	private extractData(res: Response) {
	    const body = res;
	    return body || {};
	}

}
