import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../user/service/user.service';
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { RoleService } from '../service/role.service';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.css']
})

export class AddRolesComponent implements OnInit {
  title='Add Roles';
  showSucessMessage: boolean;
  serverErrorMessages: string;
  roleForm: FormGroup;
  data: string;
  timeout_data: string;
  timein_data: string;
  loggedUserId: string;

  constructor(public roleService: RoleService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
      if(!this.userService.isLoggedIn()){
         this.router.navigateByUrl('/login');
      }

      this.roleForm = this.formBuilder.group({
            role_name       : ['', Validators.required],
      });
  }

  onSubmit(form: NgForm) {
      form.value["slug"] = this.userService.getusername();
      this.roleService.postRoles(form.value).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => this.showSucessMessage = false, 500);
          this.resetForm(form);
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
  }

  resetForm(form: NgForm) {
      this.roleService.roleForm = {
        role_id    : '',
        role_name  : '',
        status  : '', 
      };
      form.resetForm();
      this.serverErrorMessages = '';
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
