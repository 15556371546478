import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DepartmentService } from '../service/department.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-view-department',
  templateUrl: './view-department.component.html',
  styleUrls: ['./view-department.component.css']
})

export class ViewDepartmentComponent implements OnInit {

  title='Department Details';
  showSucessMessage: boolean;
  serverErrorMessages: string;
  id; departmentDetails;

  constructor(private departmentService: DepartmentService,private route: ActivatedRoute) { }

  ngOnInit() {
  	  this.id = this.route.snapshot.paramMap.get('id');
	  	this.departmentService.editDepartment(this.id).subscribe(
		    res => {
		        this.departmentDetails = res[0];
		    },
		    err => { 
		        console.log(err);	        
		    }
	    );
  }

}
