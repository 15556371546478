// built-in
import { BrowserModule, Title }  from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms'; 
import { Routes,RouterModule } from '@angular/router'; 
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';

import { BsModalService, BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChartsModule } from 'ng2-charts';

// components
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/register/sign-up.component';
import { HomeComponent } from './design/home/home.component';
import { NavbarComponent } from './design/navbar/navbar.component';
import { SidebarComponent } from './design/sidebar/sidebar.component';
import { FooterComponent } from './design/footer/footer.component';

//routes
//import { appRoutes } from './routes';
import { AppRoutingModule } from './app-routing.module';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { SignInComponent } from './user/login/sign-in.component';
import { UserService } from './user/service/user.service';
import { EmployeesService } from './employees/service/employees.service';
import { AttendanceService } from './attendance/service/attendance.service';
import { DepartmentService } from './admin/department/service/department.service';
import { EmployementService } from './admin/employement_types/service/employement.service';
import { LeaveTypesService } from './admin/leave_types/service/leavetypes.service'

//other
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AddEmployeesComponent } from './employees/add-employees/add-employees.component';
import { ListEmployeesComponent } from './employees/list-employees/list-employees.component';
import { EditEmployeesComponent } from './employees/edit-employees/edit-employees.component';

import { ProfileComponent } from './employees/profile/profile.component';
import { ChangePasswordComponent } from './employees/change-password/change-password.component';
import { ForgotComponent } from './user/forgot/forgot.component';
import { ViewEmployeesComponent } from './employees/view-employees/view-employees.component';
import { ResetComponent } from './user/reset/reset.component';
import { AddDepartmentComponent } from './admin/department/add-department/add-department.component';
import { ListDepartmentComponent } from './admin/department/list-department/list-department.component';
import { EditDepartmentComponent } from './admin/department/edit-department/edit-department.component';

import { AddLeaveTypesComponent } from './admin/leave_types/add-leavetypes/add-leavetypes.component';
import { ListLeaveTypesComponent } from './admin/leave_types/list-leavetypes/list-leavetypes.component';
import { EditLeaveTypesComponent } from './admin/leave_types/edit-leavetypes/edit-leavetypes.component';
import { AddAttendanceTypesComponent } from './admin/attendance_types/add-attendancetypes/add-attendancetypes.component';
import { ListAttendanceTypesComponent } from './admin/attendance_types/list-attendancetypes/list-attendancetypes.component';
import { EditAttendanceTypesComponent } from './admin/attendance_types/edit-attendancetypes/edit-attendancetypes.component';

import { AddAttendanceComponent } from './attendance/add-attendance/add-attendance.component';
import { LeaveRequestComponent } from './employees/leave-request/leave-request.component';
import { ConfirmBoxComponent } from './shared/confirm-box.component';
import { ViewDepartmentComponent } from './admin/department/view-department/view-department.component';

import { ListLeaveRequestComponent } from './leave/list-leaverequest/list-leaverequest.component';
import { EmployeeLeaveComponent } from './leave/employee-leave/employee-leave.component';
import { LeaveApproveComponent } from './leave/leave-approve/leave-approve.component';

import { ListAttendanceComponent } from './attendance/list-attendance/list-attendance.component';
import { EditAttendanceComponent } from './attendance/edit-attendance/edit-attendance.component';
import { AddRolesComponent } from './admin/roles/add-roles/add-roles.component';
import { ListRolesComponent } from './admin/roles/list-roles/list-roles.component';
import { EditRolesComponent } from './admin/roles/edit-roles/edit-roles.component';
import { SummaryComponent } from './attendance/summary/summary.component';
import { SummaryDetailsComponent } from './attendance/summary-details/summary-details.component';
import { AddNotificationComponent } from './notification/add-notification/add-notification.component';
import { EditNotificationComponent } from './notification/edit-notification/edit-notification.component';
import { ListNotificationComponent } from './notification/list-notification/list-notification.component';

import { QuickAttendanceComponent } from './shared/quick-attendance.component';

import { TimeAgoPipe } from 'time-ago-pipe';
import { ViewNotificationComponent } from './notification/view-notification/view-notification.component';
import { LeaveApprovalPendingComponent } from './leave/leave-approval-pending/leave-approval-pending.component';
import { LeaveBalanceComponent } from './leave/leave-balance/leave-balance.component';
import { CronComponent } from './cron/cron.component';
import { NotificationViewStatusComponent } from './notification/notification-view-status/notification-view-status.component';
import { DatePipe } from '@angular/common';
import { CompanyPoliciesComponent } from './company-policies/company-policies.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ResetpasswordComponent } from './user/resetpassword/resetpassword.component';
import { Location } from '@angular/common';
import { CalendarComponent } from './notification/calendar/calendar.component';
import { ReportsComponent } from './reports/reports.component';
import { DataTableDirective } from 'angular-datatables';
import { EditAttendanceCalendarComponent } from './shared/editattendancecalendar.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PushNotificationsModule } from 'ng-push';
import { PushnotificationComponent } from './pushnotification/pushnotification.component';

import { AddEmployementTypesComponent } from './admin/employement_types/add-employement-types/add-employement-types.component';
import { EditEmployementTypesComponent } from './admin/employement_types/edit-employement-types/edit-employement-types.component';
import { ListEmployementTypesComponent } from './admin/employement_types/list-employement-types/list-employement-types.component';
import { MiscellaneousComponent } from './admin/miscellaneous/miscellaneous.component';

import { AgeProfileComponent } from './reports/age-profile/age-profile.component';
import { BirthdaysComponent } from './reports/birthdays/birthdays.component';
import { EmployeeStatusHistoryComponent } from './reports/employee-status-history/employee-status-history.component';
import { GenderReportComponent } from './reports/gender-reports/gender-reports.component';
import { YearsServiceComponent } from './reports/years-of-service/years-of-service.component';
import { DailyAttendanceComponent } from './reports/daily-attendance/daily-attendance.component';
import { MonthlyAttendanceComponent } from './reports/monthly-attendance/monthly-attendance.component';
import { TimeoffBalancesComponent } from './reports/timeoff-balances/timeoff-balances.component';
import { TimeoffUsedComponent } from './reports/timeoff-used/timeoff-used.component';


@NgModule({
  declarations: [
    AppComponent,
    UserComponent,SignUpComponent, SignInComponent,UserProfileComponent,   
    HomeComponent,NavbarComponent,SidebarComponent,FooterComponent,
    AddAttendanceComponent, AddEmployeesComponent, ListEmployeesComponent, EditEmployeesComponent, ProfileComponent, ForgotComponent,ViewEmployeesComponent, ResetComponent, AddDepartmentComponent, ListDepartmentComponent, EditDepartmentComponent,
    AddLeaveTypesComponent, ListLeaveTypesComponent, EditLeaveTypesComponent,ConfirmBoxComponent, ViewDepartmentComponent, AddAttendanceTypesComponent, ListAttendanceTypesComponent, EditAttendanceTypesComponent,LeaveRequestComponent,ListLeaveRequestComponent,ChangePasswordComponent,EmployeeLeaveComponent,LeaveApproveComponent,ListAttendanceComponent,EditAttendanceComponent, AddRolesComponent, ListRolesComponent, EditRolesComponent, SummaryComponent, SummaryDetailsComponent,  AddNotificationComponent, EditNotificationComponent, ListNotificationComponent, TimeAgoPipe, ViewNotificationComponent, LeaveApprovalPendingComponent, LeaveBalanceComponent, CronComponent, NotificationViewStatusComponent, CompanyPoliciesComponent, ResetpasswordComponent, CalendarComponent, ReportsComponent, QuickAttendanceComponent,EditAttendanceCalendarComponent,PushnotificationComponent,AddEmployementTypesComponent, EditEmployementTypesComponent, ListEmployementTypesComponent, MiscellaneousComponent,AgeProfileComponent, BirthdaysComponent,EmployeeStatusHistoryComponent,GenderReportComponent,YearsServiceComponent,DailyAttendanceComponent, MonthlyAttendanceComponent, TimeoffBalancesComponent, TimeoffUsedComponent
    ],
    entryComponents: [
      ConfirmBoxComponent,LeaveApproveComponent,QuickAttendanceComponent,EditAttendanceCalendarComponent
    ],

  imports: [
    BrowserModule,DataTablesModule,
    AppRoutingModule,FormsModule,HttpClientModule,ReactiveFormsModule, ModalModule.forRoot(),BsDatepickerModule.forRoot(),TimepickerModule.forRoot(),BrowserAnimationsModule,ImageCropperModule,
   FullCalendarModule,PushNotificationsModule,ChartsModule,
   ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },Title,AuthGuard,UserService,AttendanceService,EmployeesService,DepartmentService,LeaveTypesService,BsModalRef,DatePipe,EmployementService

  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
