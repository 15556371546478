import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttendanceTypesService } from '../service/attendancetypes.service'
import { UserService } from '../../../user/service/user.service';
import { Router } from "@angular/router";
import { Title }     from '@angular/platform-browser';
 
@Component({
  selector: 'app-add-attendancetypes',
  templateUrl: './add-attendancetypes.component.html',
  styleUrls: ['./add-attendancetypes.component.css']
})
export class AddAttendanceTypesComponent implements OnInit {

  title='Add Attendance Types';
  showSucessMessage: boolean;
  serverErrorMessages: string;
  attendancetypeForm: FormGroup;
  constructor(public attendancetypesService: AttendanceTypesService,private userService: UserService,private router: Router, private formBuilder: FormBuilder, private titleService: Title) {
  }

  ngOnInit() {
      if(!this.userService.isLoggedIn()){
        this.router.navigateByUrl('/login');
      }

      this.attendancetypeForm = this.formBuilder.group({
          type_name       : ['', Validators.required],
          break_time      : ['', Validators.required],
          status          : ['', Validators.required],
      }); 
  }

  onSubmit(form: NgForm) {
      form.value["slug"] = this.userService.getusername();
      this.attendancetypesService.postAddTypes(form.value).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => {
              this.showSucessMessage = false;
              this.router.navigateByUrl('/list-attendancetypes');
          }, 500);
          this.resetForm(form);          
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
  }

  resetForm(form: NgForm) {
      this.attendancetypesService.addAttendanceTypes = {
        type_name      : '',
        break_time     : 0,
        is_default     : false,
        status         : true,       
      };
      form.resetForm();
      this.serverErrorMessages = '';
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
