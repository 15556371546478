import { Component, OnInit, OnDestroy, TemplateRef  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EmployeesService } from '../service/employees.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { DepartmentService } from '../../admin/department/service/department.service';
@Component({
  selector: 'app-list-employees',
  templateUrl: './list-employees.component.html',
  styleUrls: ['./list-employees.component.css']
})

export class ListEmployeesComponent implements OnDestroy, OnInit {
  title='List Employees';
	employeesDetails;rowid;departmentDetails;attendanceSearch;slug;showDelSucessMessage;serverErrorMessages;showSucessMessage;role;userdetails;dept;
  modalRef: BsModalRef;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  constructor( private employeesService: EmployeesService, private router: Router, private modalService: BsModalService, private http: HttpClient,private departmentService: DepartmentService) { }

  ngOnInit() {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    
    this.slug = this.employeesService.getusername();
    this.role = this.employeesService.getUserRole();
      this.userdetails = this.employeesService.getUserDtls();
      if(this.role=='Admin'){
         this.dept='Admin';
      }else if(this.role=='Manager'){
         this.dept= this.userdetails.user_dept;
      }else{
         this.dept='User';
      }

    this.attendanceSearch={employeeName:'',department:'',employeeEmail:''}; 
    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
      res => {
        this.departmentDetails = res;
      }
    );
    
    this.employeesService.listEmployee(this.dept,this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();
    });

  }

  onSubmit(form: NgForm) {
     this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
   
    this.dtTrigger.unsubscribe(); 
    form.value["slug"] = this.employeesService.getusername();
     this.employeesService.searchEmployees(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();
    });
  }

  deleteEmployees(id){
      const initialState = {
            del_id: id,
            page: 'employee',
        };
    this.modalRef = this.modalService.show(ConfirmBoxComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';    
  }

  clearFilter(form: NgForm) {
    this.attendanceSearch={employeeName:'',department:'',employeeEmail:''}; 
    this.slug = this.employeesService.getusername();
    this.employeesService.listEmployee(this.dept,this.slug).pipe(map(this.extractData)
    ).subscribe(employeesDetails => {
      this.employeesDetails = employeesDetails;
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

}
