import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { EmployeesService } from '../../employees/service/employees.service';
import { ReportsService } from '../service/reports.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-years-service',
  templateUrl: './years-of-service.component.html',
  styleUrls: ['./years-of-service.component.css']
})
export class YearsServiceComponent implements OnInit {
  title='Years of Service';
  slug;joining_date;rowid;employeesDetails;departmentDetails;roleDetails;reportFilters;months;
  years_service: any[] = new Array;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private titleService: Title, private employeesService: EmployeesService, private reportsService: ReportsService, private departmentService: DepartmentService) { }

  ngOnInit() {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.slug = this.employeesService.getusername();

    this.months ={1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'};

    this.reportFilters={department:'',role:''}; 

    this.reportsService.listEmployeeAge(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        for(let employee of this.employeesDetails) { 
          for(var i: number = 1; i < 13; i++) {
            if(employee.joinng_month == i) {
              let employe =  {name:employee.fullName,joining_date:employee.joining_date,years_service:employee.years_service};

              if (this.years_service[this.months[employee.joinng_month]]) {
                  this.years_service[this.months[employee.joinng_month]].push(employe);
              } else {
                  this.years_service[this.months[employee.joinng_month]]= [employe];
              }
            }
          }          
       }
console.log(this.years_service)
    });

    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.employeesService.listRoles(this.slug).subscribe(
        res => {
          this.roleDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );
    
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  public ageFromDateOfBirthday(dateOfBirth: any): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }


  onSubmit(form: NgForm) {
    this.rowid='';
   
    this.years_service  = new Array;
    form.value["slug"] = this.employeesService.getusername();
     this.reportsService.searchEmployeesAgeProfile(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        for(let employee of this.employeesDetails) { 
          for(var i: number = 1; i < 13; i++) {
            if(employee.joinng_month == i) {
              let employe =  {name:employee.fullName,joining_date:employee.joining_date,years_service:employee.years_service};

              if (this.years_service[this.months[employee.joinng_month]]) {
                  this.years_service[this.months[employee.joinng_month]].push(employe);
              } else {
                  this.years_service[this.months[employee.joinng_month]]= [employe];
              }
            }
          }          
       }
       
    });
  }

  clearFilter(form: NgForm) {
    this.reportFilters={department:'',role:''}; 
    this.slug = this.employeesService.getusername();
    this.years_service  = new Array;
    this.reportsService.listEmployeeAge(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        for(let employee of this.employeesDetails) { 
          for(var i: number = 1; i < 13; i++) {
            if(employee.joinng_month == i) {
              let employe =  {name:employee.fullName,joining_date:employee.joining_date,years_service:employee.years_service};

              if (this.years_service[this.months[employee.joinng_month]]) {
                  this.years_service[this.months[employee.joinng_month]].push(employe);
              } else {
                  this.years_service[this.months[employee.joinng_month]]= [employe];
              }
            }
          }          
       }
    });
  }

}
