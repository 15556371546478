<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

             <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Leave request send successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- Error message -->
                                
            </div>
            <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                <strong> {{serverErrorMessages}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>  




            <form #leaveRequestForm="ngForm" (ngSubmit)="leaveRequestForm.valid && onSubmit(leaveRequestForm)" autocomplete="off"> 

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i> Add {{ title }}</h3>
                        </div>
                            
                        <div class="card-body">

                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 leaves"> 
                                <div class="form-group">
                                    <label>Leave Type</label>                                 
                                    <select class="select2 form-control custom-select" #leave_type_id="ngModel" [(ngModel)]="employeeService.leaveRequest.leave_type_id" name="leave_type_id" required id="leave_type_id">
                                        <option>Select</option>
                                        <option *ngFor="let leaveType of leaveTypesList" [ngValue]="leaveType._id" value="{{leaveType._id}}">{{leaveType.type_name}}</option>
                                    </select>    
                                    <div *ngIf="leaveRequestForm.submitted && leave_type_id.errors">
                                      <label class="validation-message" *ngIf="leave_type_id.errors.required">Please enter the leave type.</label>
                                    </div>                  
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 leaves"> 

                                <div class="form-group">
                                    <label>Leave Mode</label>                                 
                                    <select class="select2 form-control custom-select" #leave_mode="ngModel" [(ngModel)]="employeeService.leaveRequest.leave_mode" name="leave_mode" required id="leave_mode">
                                        <option>Select</option>
                                        <option [ngValue]="1">Full Day</option>
                                        <option [ngValue]="0.5">Half Day</option>
                                    </select> 
                                    <div *ngIf="leaveRequestForm.submitted && leave_mode.errors">
                                      <label class="validation-message" *ngIf="leave_mode.errors.required">Please enter the leave mode.</label>
                                    </div>             
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 leaves"> 
                                <div class="form-group">
                                    <label>No. of Days</label>
                                    <input type="number"  class="form-control" #days_no="ngModel" [(ngModel)]="employeeService.leaveRequest.days_no" name="days_no" placeholder="No. of Days" required  [ngClass]="{'invalid-textbox' :leaveRequestForm.submitted && !days_no.valid }"  (input)="onDaysChange($event.target.value)">
                                    <div *ngIf="leaveRequestForm.submitted && days_no.errors">
                                      <label class="validation-message" *ngIf="days_no.errors.required">Please enter the Number of days.</label>
                                    </div>                
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 leaves"> 
                                <div class="form-group">
                                    <label>Date From</label> 
                                    <input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="loggedUserId">
                                    <input class="form-control" placeholder="Datepicker" [bsValue]="bsInlineValue" bsDatepicker #date_from="ngModel" name="date_from" id="datefrom" [(ngModel)]="employeeService.leaveRequest.date_from" value=""  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }" (bsValueChange)="onDateChange($event)" *ngIf="dateRange; else elseBlock">
                                    <div *ngIf="leavereqDateErr">
                                      <label class="validation-message">Date already taken</label>
                                    </div>
                                    <ng-template #elseBlock><input class="form-control" placeholder="Datepicker" [bsValue]="bsInlineValue" bsDaterangepicker #date_from="ngModel" name="date_from" [(ngModel)]="employeeService.leaveRequest.date_from" value=""  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }"></ng-template>
                                    <div *ngIf="leaveRequestForm.submitted && days_no.errors">
                                      <label class="validation-message" *ngIf="days_no.errors.required">Please enter the date.</label>
                                    </div>          
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 leaves"> 
                                <div class="form-group">
                                     <label>Pre Approved?  </label>
                                    <input type="checkbox" class="col-md-1" #pre_approved="ngModel" [(ngModel)]="employeeService.leaveRequest.pre_approved" name="pre_approved" placeholder="Name" [ngClass]="{'invalid-textbox' :leaveRequestForm.submitted && !pre_approved.valid }" (change)="checkCheckBoxvalue($event)">             
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 leaves"> 
                                <div class="form-group">
                                    <label>Pre Approved By</label>                                 
                                    <select class="select2 form-control custom-select" #pre_approved_by="ngModel" [(ngModel)]="employeeService.leaveRequest.pre_approved_by" name="pre_approved_by">
                                        <option>Select</option>
                                       <option *ngFor="let employee of employeesDetails" value="{{employee._id}}" >{{employee.fullName}}</option>
                                    </select>        
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 leaves">
                                <div class="form-group">
                                    <label>Reason</label>
                                    <textarea class="form-control" #notes="ngModel" [(ngModel)]="employeeService.leaveRequest.notes" name="notes" placeholder="Notes" rows="5"  [ngClass]="" required></textarea>
                                
                                    <div *ngIf="leaveRequestForm.submitted && notes.errors">
                                      <label class="validation-message" *ngIf="notes.errors.required">Please enter the reason.</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="form-group">
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>       
                    </div>  
                </div>
            </div>
            </form>
        </div>
    </div>
</div>



            