<div class="content-page">
  <div class="content">   
    <div class="container-fluid"> 
      <div class="row">
        <div class="col-xl-12">
          <div class="breadcrumb-holder">
            <h1 class="main-title float-left">{{ title }}</h1>
            <ol class="breadcrumb float-right">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active">{{ title }}</li>
            </ol>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div *ngIf="showSucessMessage">                
        <!-- Success message -->                 
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Attendance Added Successfully!</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
          </button>
        </div>            
      </div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
          <strong> {{serverErrorMessages}}</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
          </button>
      </div> 
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class='app'> 
            <full-calendar #fullcalendar [editable]="true" [events]="calendarEvents"  
                [header]="options.header" [customButtons]="options.customButtons"                  
                [plugins]="options.plugins" (eventClick)="eventClick($event)"  
                [columnHeaderHtml]="options.columnHeaderHtml"></full-calendar>  
          </div> 
          <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
            <div class="modal-dialog">
              <div class="modal-content">                            
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">Calendar Details</h4>
                  <button type="button" class="close" data-dismiss="modal" (click) = "hide()">&times;</button>
                </div>
                
                <!-- Modal body -->
                <div class="modal-body">  

                <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">                        
                    <div class="card mb-3">
                                              
                        <div class="card-body">
                           
                            <div class="form-group">
                                <label for="subject">Subject</label> : {{ notificationlist.subject }}
                            </div>
                            <div class="form-group">
                                <label for="subject">Content</label> : {{ notificationlist.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div> 





                     
                </div>                    
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click) = "hide()">Close</button>
                </div>                              
              </div>
            </div>
          </div>
        </div>
  
      </div>           
    </div>
  </div>
</div>
