import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from './user.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userdata: User = {
    username : '', 
    company_name : '', 
    email : '', 
    address : '', 
    city : '', 
    state : '', 
    zipcode : '', 
    fax : '', 
    website : '', 
    fullName : '', 
    phone : '', 
    password : '', 
    confirmpassword : ''
    
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  constructor(private http: HttpClient) { }

  //HttpMethods

  postUser(user: User){
    return this.http.post(environment.apiBaseUrl + '/register',user,this.noAuthHeader);
  }
  registerCompany(user: User){
    console.log(user)
    return this.http.post(environment.apiBaseUrl + '/registerCompany',user,this.noAuthHeader);
  }

  login(authCredentials) {
    return this.http.post(environment.apiBaseUrl + '/authenticate', authCredentials,this.noAuthHeader);
  }

  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userProfile');
  }

  forgot(user: User) {
    return this.http.post(environment.apiBaseUrl + '/forgot',user,this.noAuthHeader);
  }
  resetpassword(user: User){
    return this.http.post(environment.apiBaseUrl + '/resetpassword',user,this.noAuthHeader);
  }

  getLineManagers(manager,hrmanager,empid) {
  if(manager==''){
     manager=null; 
  }else{
     manager=manager;
  }
  
     return this.http.get(environment.apiBaseUrl + '/listManagers/'+ manager + '/' + hrmanager + '/' + empid);
  }


  //Helper Methods

  setToken(token: string) {
    return localStorage.setItem('token', token);
  }
  managers() {
    return localStorage.getItem('managers');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  deleteToken() {    
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
  }

  deleteLoggedUser() {   
    localStorage.removeItem('logged_user');
    localStorage.removeItem('user_role');
    localStorage.removeItem('current_user');
    localStorage.removeItem('current_user_line');
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else
      return null;
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    if (userPayload){
      return userPayload.exp > Date.now() / 6000;
    }else{
      return false;
    }
  }

  updateavatarImage(user) {
    localStorage.removeItem('avatar');
    localStorage.setItem('avatar', user['avatar']);
  }

  
  setLoggedUser(user) {
    localStorage.setItem('logged_user', user['_id']);
    localStorage.setItem('department', user['department']);
    localStorage.setItem('current_user', JSON.stringify(user));
    if((user['user_role'] && user['user_role'] =='admin') || (user['admin_access']=='true')) {
      var role =  'Admin';      
    } 
    else if(user['admin_access']!='true' && user['reporting_person']==true){
      var role =  'Manager'; 
    }else {
      var role =  'User';
    }

    const current_user = {_id: user['_id'], user_role: role, user_email: user['email'], user_dept: user['department'], emp_role: user['role'], rep_person: user['reporting_person'], avatar: user['avatar'], fullname: user['fullName'], manager: user['manager'], hrmanager: user['hrmanager'],admin_access: user['admin_access']}
    localStorage.setItem('current_user', JSON.stringify(current_user));
    localStorage.setItem('avatar', user['avatar']);
    localStorage.setItem('admin_access', user['admin_access']);
    localStorage.setItem('slug', user['slug']);   

  }

  getUserAvatar() {    
    return localStorage.getItem('avatar');
  }
  getusername() {    
    return localStorage.getItem('slug');
  }
 
  getUserID() {    
    return localStorage.getItem('logged_user');
  }
  
  getUserDepartment() {    
    return localStorage.getItem('department');
  }

  getUserRole() {
    const current_user = JSON.parse(localStorage.getItem("current_user"))
    if(current_user){
      return current_user.user_role;
    }
  } 

  getUserDtls() {
    var  current_user = JSON.parse(localStorage.getItem("current_user"))
    return current_user;
  }

  getUserLineDtls() {
    var  current_user = JSON.parse(localStorage.getItem("current_user_line"))
    return current_user;
  }

  sendMail(mailData){
    return this.http.post(environment.apiBaseUrl+'/sendMail',mailData,this.noAuthHeader);
  }

  setRemeberMe(user) {
    localStorage.setItem('username', btoa(user.email));
    localStorage.setItem('userlogg', btoa(user.password));
    localStorage.setItem('remember_me', btoa(user.remember_me));
  }

  getRemeberMeUsername() {
    if(localStorage.getItem('username')) {
      //return atob(localStorage.getItem('username'));
    }     
  }

  getRemeberMePassword() {    
    if(localStorage.getItem('userlogg')) {
      //return atob(localStorage.getItem('userlogg'));
    }
  }

  getRemeberMe() {    
    if(localStorage.getItem('remember_me')) {
      return atob(localStorage.getItem('remember_me'));
    } 
  }
  removeRemeberMe() {
      localStorage.removeItem('username');
      localStorage.removeItem('userlogg');
      localStorage.removeItem('remember_me');
  }
}
