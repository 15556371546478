import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EmployeesService } from '../service/employees.service'
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { DepartmentService } from '../../admin/department/service/department.service';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-employees',
  templateUrl: './add-employees.component.html',
  styleUrls: ['./add-employees.component.css']
})
export class AddEmployeesComponent implements OnInit {

  title='Add Employees';
  fileData: File = null;
  previewUrl:any = null;  
  avatarFile:any = null;  slug;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  departmentDetails;employeesDetails;roleDetails;devManagerList;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  signUpForm: FormGroup;mailData;today;employement;
  constructor(public employeesService: EmployeesService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private departmentService: DepartmentService, private http: HttpClient, private location:Location) {
  }

  ngOnInit() {
       if(!this.userService.isLoggedIn()){
         this.router.navigateByUrl('/login');
       }
       this.slug = this.userService.getusername();
       this.departmentService.listEmployeesDepartment(this.slug).subscribe(
          res => {
            this.departmentDetails = res;
          },
          err => { 
            console.log(err);            
          }
      );
      this.employeesService.getRepPersonByDeptHR(this.slug).subscribe(
          res => {
            this.employeesDetails = res;
          },
          err => { 
            console.log(err);            
          }
      );
      
      this.employeesService.listRoles(this.slug).subscribe(
          res => {
            this.roleDetails = res;
          },
          err => { 
            console.log(err);            
          }
      );
      this.employeesService.listEmployeeType(this.slug).subscribe(
          res => {
            this.employement = res;
          },
          err => { 
            console.log(err);            
          }
      );

       this.signUpForm = this.formBuilder.group({
            fullName       : ['', Validators.required],
            display_name   : ['', Validators.required],
            gender         : ['', Validators.required],
            dob            : ['', Validators.required],            
            email          : ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            address        : ['', Validators.required],
            locality       : ['', Validators.required],            
            emp_code       : ['', Validators.required],
            joining_date   : ['', Validators.required],
            department     : ['', Validators.required], 
            pincode        : ['', [Validators.required, Validators.pattern("^[0-9]*$")]] 
        }); 
  }

  onSubmit(form: NgForm) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);

    if(this.fileData) {
        const formData = new FormData();
        formData.append('file', this.fileData);
        this.http.post(environment.apiBaseUrl + '/upload/', formData).subscribe(
            res => {         
                  form.value["avatar"] = res['uploadedFile'];
                  form.value["slug"] = this.userService.getusername();
                  form.value["username"] = form.value["username"]+'_'+this.userService.getusername();

                  this.employeesService.postUser(form.value).subscribe(
                      res => {

                      console.log(res);

                          this.showSucessMessage = true;
                          setTimeout(() => {
                              this.showSucessMessage = false;
                              this.router.navigateByUrl('/list-employees');
                          }, 500);  //5s

                          this.resetForm(form);
                          //send mail to employee

                          var url=document.getElementsByTagName('base')[0].href +'resetpassword/'+ btoa(form.value['email']);
                          let mail_msg = "Hi " + form.value['fullName'] +", <br/><br/>";
                          mail_msg  += "Welcome to Subhosting Team. Please follow the link provided below to reset your password. <br/><br/>";
                          mail_msg  +="<a href="+ url +">Reset your account password.</a>";
                          mail_msg  += " <br/><br/> Regards ,<br/> Insight Team";
                          this.mailData = {'to':form.value['email'],'from':'admin@subhosting.net','subject':'Password Reset link from Insight','text':mail_msg};
                          
                          this.userService.sendMail(this.mailData).subscribe(
                            res => {
                             console.log(res); 
                            }, 
                          );

                      },
                      err => {
                          if (err.status === 422) {
                            this.serverErrorMessages = err.error.join('<br/>');
                          }
                          else{
                            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
                          }
                      }
                  );
            }
        );

    } else {
        
        form.value["slug"] = this.userService.getusername();
        form.value["username"] = form.value["display_name"]+'_'+this.userService.getusername();
        form.value["avatar"] = 'profile.png';

        this.employeesService.postUser(form.value).subscribe(
            res => {

                this.showSucessMessage = true;
                setTimeout(() => {
                    this.showSucessMessage = false;
                    this.router.navigateByUrl('/list-employees');
                }, 500);  //5s
                this.resetForm(form);
                
                //send mail to employee

                var url=document.getElementsByTagName('base')[0].href +'resetpassword/'+ btoa(res['email']);
                let mail_msg = "Hi " + res['fullName'] +", <br/><br/>";
                mail_msg  += "Welcome to " + res['slug'] +" Team. Please follow the link provided below to reset your password. <br/><br/>";
                mail_msg  +="<a href="+ url +">Reset your account password.</a>";
                mail_msg  += " <br/><br/> Regards ,<br/> Insight Team";
                this.mailData = {'to':res['email'],'from':'admin@subhosting.net','subject':'Password Reset link from Insight','text':mail_msg};
                
                this.userService.sendMail(this.mailData).subscribe(
                  res => {
                   console.log(res); 
                  }, 
                );

            },
            err => {
                if (err.status === 422) {
                  this.serverErrorMessages = err.error.join('<br/>');
                }
                else{
                  this.serverErrorMessages = 'Something went wrong.Please contact admin.';
                }
            }
        );
    } 
  }

  getDeptManager(dept: String) {    
    this.employeesService.getRepPersonByDeptManager(dept,this.slug).subscribe(
        res => {
            this.devManagerList = res;            
        },
        err => { 
            console.log(err);          
        }
    );
  }

  resetForm(form: NgForm) {
      this.employeesService.addEmployees = {
        fullName      : '',
        display_name  : '',
        gender        : '',
        dob           : new Date(),
        email         : '',
        address       : '',
        locality      : '',
        password      : '',
        emp_code      : '',
        joining_date  : new Date(),
        chat_name     : '',
        department    :'',
        role          : '',
        description   : '',
        manager       : '',
        hrmanager     : '',
        status        : '',
        employee_type : '',
        pincode       : '',
        reporting_person : false,
        admin_access : false,
      };
      form.resetForm();
      this.serverErrorMessages = '';
  }

  fileProgress(fileInput: any) {
      this.fileData = <File>fileInput.target.files[0];
      this.preview();
  }
   
  preview() {
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }   
      var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        this.previewUrl = reader.result;         
      }
  }

}
