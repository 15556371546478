import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Department } from './department.model';
import { UserService } from '../../../user/service/user.service';

@Injectable({
  providedIn: 'root'
})

export class DepartmentService {
  addDepartment: Department = {
    department_name   : '',
    department_code   : '',
    status            : '',
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  showSucessMessage: boolean;
  serverErrorMessages: string;
  constructor( private http: HttpClient) { }

  //HttpMethods

  postDepartment(department: Department){
    return this.http.post(environment.apiBaseUrl+'/addDepartment',department,this.noAuthHeader);
  }

  listDepartment(slug) {
    return this.http.get(environment.apiBaseUrl + '/listDepartment/'+slug);
  }

  editDepartment(id) {
    return this.http.get(environment.apiBaseUrl + '/editDepartment/'+id);
  }

  UpdateDepartment(department: Department){
    return this.http.post(environment.apiBaseUrl + '/UpdateDepartment',department,this.noAuthHeader);
  }

  deleteDepartment(id){ 
      this.http.get(environment.apiBaseUrl + '/deleteDepartment/'+id, {observe: 'response'})
      .subscribe(resp => {
            this.showSucessMessage = true;   
        },
          err => {
            if (err.status === 422) {
              this.serverErrorMessages = err.error.join('<br/>');
            }
            else
              this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        });
  }

  listEmployeesDepartment(slug) {
    return this.http.get(environment.apiBaseUrl + '/listEmployeesDepartment/'+slug);
  }


}
