<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item"><a routerLink="/list-department/">List Departments</a></li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

             <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9 col-xl-9">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i> {{ title }}</h3>
                        </div>                            
                        <div class="card-body">
                          
                          <div class="form-group">
	                                <label>Department</label>
	                                {{departmentDetails.department_name}}
	                                
	                            </div>
	                            <div class="form-group">
	                                <label>Department Code</label>
	                                {{departmentDetails.department_code}}
	                            </div>
	                            <div class="form-group">
	                                <label class="genderlabel">Status</label>
	                                 {{departmentDetails.status}} 
	                              
	                            </div>

                            <a  [routerLink]="['/list-department']"><button type="button" class="btn btn-primary">Back</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




