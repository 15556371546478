<div class="left main-sidebar" *ngIf="show">    
        <div class="sidebar-inner leftscroll">
            <div id="sidebar-menu">        
                <ul>
                    <li class="submenu">
                        <a class="active" [routerLink]="['/']" (click)="setTitle( 'Dashboard' )"><i class="fa fa-fw fa-bars"></i><span> Dashboard </span> </a>
                    </li>
                    <li class="submenu" *ngIf="role=='Admin'">
                        <a href="javascript:void(0)"><i class="fa fa-users"></i> <span> Employees </span> <span class="menu-arrow"></span></a>
                        <ul class="list-unstyled">
                            <li><a  [routerLink]="['/add-employees']" (click)="setTitle( 'Add Employee' )">Add Employee</a></li>
                            <li><a  [routerLink]="['/list-employees']" (click)="setTitle( 'List Employee' )">List Employee</a></li>
                        </ul>
                    </li>
                    
                    <li class="submenu">
                        <a href="javascript:void(0);"><i class="fa fa-book"></i> <span> Attendance </span> <span class="menu-arrow"></span></a>
                        <ul class="list-unstyled">
                            <li *ngIf="role!='Admin'"><a [routerLink]="['/add-attendance']" (click)="setTitle( 'Add Attendance' )">Add Attendance</a></li>
                            <li *ngIf="role!='Admin'"><a  [routerLink]="['/list-attendance']" (click)="setTitle( 'List Attendance' )"> List Attendance</a></li>
                            <li *ngIf="role=='Admin' || role=='Manager'"><a  [routerLink]="['/summary']" (click)="setTitle( 'Summary of Employee' )"> Summary of Employee</a></li>
                        </ul>
                    </li>
                     <li class="submenu">
                        <a href="javascript:void(0);"><i class="fa fa-power-off"></i> <span> Leave </span> <span class="menu-arrow"></span></a>
                        <ul class="list-unstyled">
                            <li *ngIf="role!='Admin'"><a [routerLink]="['/leave-request']" (click)="setTitle( 'Create Leave ' )">Create Leave  </a></li>
                            <li *ngIf="role=='Admin'"><a  [routerLink]="['/list-leave-request']" (click)="setTitle( 'List Leave' )">List Leaves </a></li> 
                            <li *ngIf="role=='User' || role=='Manager'"><a  [routerLink]="['/employee-leaves',id]" (click)="setTitle( 'Create Leave' )">List Leaves </a></li>  
                            <li *ngIf="role=='Manager'"><a  [routerLink]="['/list-leave-request']" (click)="setTitle( 'List Employee Leave' )">List Employee Leaves </a></li> 

                            <!-- <li><a [routerLink]="['/']" (click)="setTitle( 'Configuration of Leave' )">Configuration of Leave </a></li> -->
                            <li *ngIf="role=='Admin'"><a  [routerLink]="['/leave-balance']" (click)="setTitle( 'Leave Balance' )"> Leave Balance</a></li>  
                            <li *ngIf="role=='Admin'"><a [routerLink]="['/leave-approval-pending']" (click)="setTitle( ' Leave Approval' )"> Leave Approval</a></li>
                        </ul>
                    </li>
                    <li class="submenu">
                        <a href="javascript:void(0);"><i class="fa fa-sticky-note-o"></i> <span> Notification </span> <span class="menu-arrow"></span></a>
                        <ul class="list-unstyled">
                            <li  *ngIf="role=='Admin'"><a  [routerLink]="['/add-notification']" (click)="setTitle( 'Add Notification' )"> Add Notification</a></li> 
                             <li><a  [routerLink]="['/list-notification']" (click)="setTitle( 'List Notification' )"> List Notification</a></li> 
                            <li><a [routerLink]="['/calendar']" (click)="setTitle( 'Calendar' )">Calendar  </a></li>
                         <!--   <li><a  [routerLink]="['/holiday']" (click)="setTitle( 'Upcoming Holiday ' )"> Upcoming Holiday </a></li>
                            <li><a [routerLink]="['/custom-announcements']" (click)="setTitle( 'Custom Announcement ' )"> Custom Announcements  </a></li> -->                             
                        </ul>
                    </li>
                    <li class="submenu" *ngIf="role=='Admin'">
                        <a  [routerLink]="['/reports']"><i class="fa fa-check-square-o"></i><span> Reports </span> </a>
                    </li>
                    <li class="submenu">
                        <a  [routerLink]="['/userprofile']"><i class="fa fa-fw fa-area-chart"></i><span> Profile </span> </a>
                    </li>
                    <li class="submenu"  *ngIf="role=='Admin'">
                        <a  [routerLink]="['/miscellaneous']"><i class="fa fa-fw fa-area-chart"></i><span> Miscellaneous </span> </a>
                    </li>
                    <li class="submenu">
                        <a  [routerLink]="['/company-policy']"><i class="fa fa-fw fa-area-chart"></i><span> Company Policies </span> </a>
                    </li> 
                </ul>
            <div class="clearfix"></div>
            </div>        
            <div class="clearfix"></div>
        </div>
    </div>