import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { EmployeesService } from '../../employees/service/employees.service';
import { ReportsService } from '../service/reports.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';

@Component({
  selector: 'app-gender-report',
  templateUrl: './gender-reports.component.html',
  styleUrls: ['./gender-reports.component.css']
})
export class GenderReportComponent implements OnInit {
  title='Gender Reports';
  slug;age;rowid;employeesDetails;departmentDetails;roleDetails;reportFilters;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  doughnutChartLabels: Label[];
  doughnutChartData: MultiDataSet;
  doughnutChartType: ChartType = 'doughnut';
  chartReady;
  gender  = [];
  genders  = [];
  genders1  = [];
  gendercount  = [];

  constructor(private titleService: Title, private employeesService: EmployeesService, private reportsService: ReportsService, private departmentService: DepartmentService) { }

  ngOnInit() {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.slug = this.employeesService.getusername();
    
    this.reportFilters={department:'',role:''}; 

    this.reportsService.listEmployeeAge(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {

        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();

        for(let employee of this.employeesDetails) {
          this.genders.push(employee.gender);
        }
        
        //make array unique
        this.gender = Array.from(new Set(this.genders));

        for(let gndr of this.gender) {
          //get count of each gender
          const result = this.genders.filter(i => i === gndr).length;
          this.gendercount.push(result);
        }

        this.doughnutChartLabels = this.gender;
        this.doughnutChartData = [
          this.gendercount
        ];

        this.chartReady = true;
       
    });

    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.employeesService.listRoles(this.slug).subscribe(
        res => {
          this.roleDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }


  onSubmit(form: NgForm) {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.dtTrigger.unsubscribe(); 
    form.value["slug"] = this.employeesService.getusername();
     this.reportsService.searchEmployeesAgeProfile(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.genders  = [];
        this.gender   = [];
        this.gendercount   = [];
        for(let employee of this.employeesDetails) {
          this.genders.push(employee.gender);
        }
        //make array unique
        this.gender = Array.from(new Set(this.genders));

        for(let gndr of this.gender) {
          //get count of each gender
          const result = this.genders.filter(i => i === gndr).length;
          this.gendercount.push(result);
        }

        this.doughnutChartLabels = this.gender;
        this.doughnutChartData = [
          this.gendercount
        ];

        this.chartReady = true;
       
        this.dtTrigger.next();
    });
  }

  clearFilter(form: NgForm) {
    this.reportFilters={department:'',role:''}; 
    this.slug = this.employeesService.getusername();
    this.dtTrigger.unsubscribe(); 
    this.reportsService.listEmployeeAge(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.genders  = [];
        this.gender   = [];
        this.gendercount   = [];
        for(let employee of this.employeesDetails) {
          this.genders.push(employee.gender);
        }
        //make array unique
        this.gender = Array.from(new Set(this.genders));

        for(let gndr of this.gender) {
          //get count of each gender
          const result = this.genders.filter(i => i === gndr).length;
          this.gendercount.push(result);
        }

        this.doughnutChartLabels = this.gender;
        this.doughnutChartData = [
          this.gendercount
        ];

        this.chartReady = true;
        this.dtTrigger.next();
    });
  }

}
