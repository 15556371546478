import {Component, Directive, Input, ViewChild,OnInit,ChangeDetectionStrategy} from '@angular/core'; 
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttendanceService } from '../service/attendance.service'
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { Subject } from 'rxjs';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarComponent } from '@fullcalendar/angular';  
import { EventInput } from '@fullcalendar/core';  
import dayGridPlugin from '@fullcalendar/daygrid'; 
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditAttendanceCalendarComponent } from '../../shared/editattendancecalendar.component';

@Component({
  selector: 'app-add-attendance',
  templateUrl: './add-attendance.component.html',
  styleUrls: ["./add-attendance.component.css"],
})

export class AddAttendanceComponent implements OnInit {
  attendanceTypesList;attend_dates;Date;departmentDetails;employeesDetails=[];attendance_typename;  showModal: boolean;  attendanceEdit;show;modalRef;initialState;slug;
  title='Add Attendance';
  bsInlineValue;today;time_in;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  attendanceForm: FormGroup;   
  data: string;
  timeout_data: string;
  timein_data: string;
  loggedUserId: string;
  @ViewChild('calendar',null) calendarComponent: FullCalendarComponent; 
  options: any;  
  eventsModel: any[] = [];  
  calendarVisible = true;  
  calendarWeekends = true;  
  calendarEvents: EventInput[] = [  
    { title: 'Event Now', start: new Date() }  
  ];  
  toggleVisible() {  
    this.calendarVisible = !this.calendarVisible;  
  } 

  constructor(public attendanceService: AttendanceService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private modalService: BsModalService) {
  }

  ngOnInit() {  
  this.show =  this.userService.isLoggedIn();
    this.loggedUserId = this.userService.getUserID();
    this.options = { 
      default: true,
      editable: true,  
      disableDragging: false,  
      selectable: true,  
      theme: 'bootstrap', 
      hiddenDays: [ 6,7], 
      header: {  
        right: 'prev,next, today',
        center: '',  
        left: 'title', 
      },  
      validRange: {  
        start: '2017-05-01',  
        end: '2019-06-01'  
      },  
      plugins: [dayGridPlugin]  
    };  

     this.attendanceForm = this.formBuilder.group({
      attend_date       : ['', Validators.required],
      time_in           : ['', Validators.required],
      time_out          : ['', Validators.required],
      attend_type_id    : ['', Validators.required],
    });

    this.attendanceService.listEmployeeAttendance(this.loggedUserId).subscribe(departmentDetails => {
      this.departmentDetails = departmentDetails;
        for(let attendance of this.departmentDetails) {
                var str1 = new String(attendance.attendancetype[0].type_name) + ' - '; 
                var str2 = new String(attendance.total_hours); 
                var str3 = str1.concat(str2.toString());
                let attend =  {title:str3,start: attendance.attend_date,groupId: attendance._id, id:this.loggedUserId};
                this.employeesDetails.push(attend);
        }
        this.calendarEvents = this.employeesDetails;  
    });

    this.slug=this.userService.getusername();
    this.attendanceService.getAttendanceTypes(this.slug).subscribe(
        res => {
          this.attendanceTypesList = res;                      
          },
        err => { 
          console.log(err);            
        }
      );
    this.attendanceEdit={attend_date:'',time_in:'',time_out:'', attend_type_id:''};  
  }  


  onSubmit(form: NgForm) {
    let selDate    = form.value["attend_date"];
    let selTimeIn  = form.value["time_in"];
    let selTimeOut = form.value["time_out"];
    form.value["newtimein"] =selTimeIn; 
    form.value["newtimeOut"] =selTimeOut;
    var newtimein  = new Date(selTimeIn);
    var newtimeout = new Date(selTimeOut);
    var diffMs     = (newtimeout.getTime() - newtimein.getTime());
    var diffHrs    = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins   = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    form.value["slug"] = this.userService.getusername(); 
    form.value["total_hours"] =diffHrs +":"+ diffMins;

    this.attendanceService.postAddAttendance(form.value).subscribe(
      res => {
        this.showSucessMessage = true;
        setTimeout(() => {
            this.showSucessMessage = false;
            window.location.reload();
        }, 1000);
        this.resetForm(form);          
      },
      err => {
        if (err.status === 422) {
          this.serverErrorMessages = err.error.join('<br/>');
        }
        else
          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
      }
    );     
  }



  toggleWeekends() {  
    this.calendarWeekends = !this.calendarWeekends;  
  }  

  eventClick(model) { 
    const config = {
                      initialState: {
                          notid: model.event.groupId, 
                      }
                    }
    this.modalRef = this.modalService.show(EditAttendanceCalendarComponent, config);

  } 

  hide(){
    this.showModal = false;
  } 

  dateClick(model) {  
  }  

  eventDragStop(model) {  
  }  

  gotoPast() {  
    let calendarApi = this.calendarComponent.getApi();  
    calendarApi.gotoDate('2000-01-01');
  }  
  
  handleDateClick(arg) {  
    if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {  
      this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array  
        title: 'New Event',  
        start: arg.date,  
        allDay: arg.allDay  
      })  
    }  
  }  

  onValueChange(att_date: Date): void {}

  onTimeInValueChange(timeIn): void {    
    let selDateIn = this.attend_dates.value;
    console.log(this.attend_dates.value);   
    selDateIn.setHours(timeIn.getHours(), timeIn.getMinutes(), timeIn.getSeconds());
    this.timein_data = selDateIn;
  }

  onTimeOutValueChange(timeOut): void {    
    let selDateout = this.data;
    this.timeout_data = selDateout;
  }

  resetForm(form: NgForm) {
      this.attendanceService.addAttendance = {
        userId         : this.loggedUserId,
        attend_date     : '',
        time_in         : '',
        time_out        : '',
        attend_type_id  : '', 
      };
      form.resetForm();
      this.serverErrorMessages = '';
  }

}
