<div class="content-page">
    <div class="content">            
        <div class="container-fluid">
        	<div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item"><a routerLink="/list-department/">List Departments</a></li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <!-- Success message -->
             <!-- Success message -->
           <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong> Department Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>      
            </div>
              <!-- Error message -->
            <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                <strong> {{serverErrorMessages}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 

	        <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" autocomplete="off"> 
		        <div class="row">
		            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9 col-xl-9">                        
		                <div class="card mb-3">
		                    <div class="card-header">
		                        <h3><i class="fa fa-check-square-o"></i> {{ title }}</h3>
		                    </div>                            
		                    <div class="card-body">
		                        <div class="form-group">
		                            <label>Department</label>
		                            <input type="text"  class="form-control" #department_name="ngModel" [(ngModel)]="departmentDetails.department_name" name="department_name" placeholder="Department Name" required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !department_name.valid }">
		                            <div *ngIf="signUpForm.submitted && department_name.errors">
		                              <label class="validation-message" *ngIf="department_name.errors.required">Please enter the Department name.</label>
		                            </div>                  
		                        </div>

		                        <div class="form-group">
		                            <label>Department Code</label>
		                            <input type="text" class="form-control" #department_code="ngModel" [(ngModel)]="departmentDetails.department_code" name="department_code" placeholder="Department Code" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !department_code.valid }">
		                            <div *ngIf="signUpForm.submitted && department_code.errors">
		                              <label class="validation-message" *ngIf="department_code.errors.required">Please enter the Display name.</label>
		                            </div>          
		                        </div>                           

		                        <div class="form-group">
		                                <label class="genderlabel">Status</label>
		                              <div class="radiocon">
		                                <div class="custom-control custom-radio radiobutton">
		                                    <input type="radio" class="custom-control-input" id="customControlValidation1" name="status" #status="ngModel" [(ngModel)]="departmentDetails.status" name="status" value="Active" [checked]='true'>
		                                    <label class="custom-control-label" for="customControlValidation1">Enable</label>                                
		                                </div>
		                                <div class="custom-control custom-radio radiobutton">
		                                    <input type="radio" class="custom-control-input" id="customControlValidation2" #status="ngModel" [(ngModel)]="departmentDetails.status" name="status" value="Inactive">
		                                    <label class="custom-control-label" for="customControlValidation2">Disable</label>
		                                </div>
		                              </div> 
		                              <div *ngIf="signUpForm.submitted && status.errors">
		                                  <label class="validation-message"  *ngIf="status.errors.required">Please select the status.</label>
		                              </div>
		                        </div>
		                        <input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="departmentDetails._id">
		                        <button type="submit" class="btn btn-primary">Submit</button>
		                    </div>
		                </div>
		            </div>
		        </div> 
	       </form> 
        </div>
    </div>
</div>
