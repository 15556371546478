<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active"><a routerLink="/list-attendancetypes/">List Attendance Types</a></li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            
             <!-- Success message -->
            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Attendance Types Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>      
            </div>
              <!-- Error message -->
            <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                <strong> {{serverErrorMessages}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 


             <form #attendancetypeForm="ngForm" (ngSubmit)="attendancetypeForm.valid && onSubmit(attendancetypeForm)" autocomplete="off">  

                <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9 col-xl-9">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i> {{ title }}</h3>
                        </div>                            
                        <div class="card-body">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" class="form-control" #type_name="ngModel" [(ngModel)]="attendancetypeDetails.type_name" name="type_name" required placeholder="Name" [ngClass]="{'invalid-textbox' :attendancetypeForm.submitted && !type_name.valid }">
                                <div *ngIf="attendancetypeForm.submitted && type_name.errors">
                                  <label class="validation-message"  *ngIf="type_name.errors.required">Please enter the name.</label>
                                </div>                  
                            </div>
                             <input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="attendancetypeDetails._id"   placeholder="id">
                            <div class="form-group">
                                <label>Is Default?  </label>
                                <input type="checkbox" class="col-md-1" #is_default="ngModel" [(ngModel)]="attendancetypeDetails.is_default" name="is_default" placeholder="Name" [ngClass]="{'invalid-textbox' :attendancetypeForm.submitted && !is_default.valid }">             
                            </div>

                            <div class="form-group">
                                <label>Break Time</label>
                                <input type="number" class="form-control" #break_time="ngModel" [(ngModel)]="attendancetypeDetails.break_time" name="break_time" required placeholder="Break Time" [ngClass]="{'invalid-textbox' :attendancetypeForm.submitted && !break_time.valid }">

                                <div *ngIf="attendancetypeForm.submitted && break_time.errors">
                                  <label class="validation-message"  *ngIf="break_time.errors.required">Please enter Break Time.</label>
                                </div>                 
                            </div>

                          <div class="form-group">
                              <label class="statuslabel statuslabel">Status</label>
                              <div class="radiocon">
                                <div class="custom-control custom-radio radiobutton">
                                    <input type="radio" class="custom-control-input" id="customControlValidation1" name="status" #status="ngModel" [(ngModel)]="attendancetypeDetails.status" required name="status" [value]="true">
                                    <label class="custom-control-label" for="customControlValidation1">Active</label>                                
                                </div>
                                <div class="custom-control custom-radio radiobutton">
                                    <input type="radio" class="custom-control-input" id="customControlValidation2" #status="ngModel" [(ngModel)]="attendancetypeDetails.status" required name="status" [value]="false">
                                    <label class="custom-control-label" for="customControlValidation2">Inactive</label>
                                </div>
                                <div *ngIf="attendancetypeForm.submitted && status.errors">
                                  <label class="validation-message"  *ngIf="status.errors.required">Please select status.</label>
                                </div>
                              </div> 
                              <div *ngIf="attendancetypeForm.submitted && status.errors">
                                  <label class="validation-message"  *ngIf="gender.errors.required">Please select the status.</label>
                              </div>
                          </div>
                          <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>


            </form>







        </div>
    </div>
</div>






  