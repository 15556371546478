<div id="quick-attendance" >
   <div *ngIf="showSucessMessage">                
        <!-- Success message -->                 
        <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Attendance Added Successfully!</strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>            
    </div>
	 
    <form #addQattendanceForm="ngForm" (ngSubmit)="addQattend(addQattendanceForm)" autocomplete="off" *ngIf="!isTodayIn; else elseBlock">													
		<div class="modal-header">
		   <h5 class="modal-title">Add Attendance</h5>	
		   <button type="button" class="close"  (click)="decline()">
			<span aria-hidden="true">&times;</span>	<span class="sr-only">Close</span>
		</button>	         	
		</div>			
		<div class="modal-body">
			<input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="loggedUserId">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label>Attendance Type</label>						
						<select class="select2 form-control custom-select" #attend_type_id="ngModel" [(ngModel)]="quickAttendance.attend_type_id" name="attend_type_id" required>
                            <option value=''>Select</option>  
                            <option *ngFor="let attendancetype of attendanceTypesList" value="{{attendancetype._id}}">{{attendancetype.type_name}}</option>
                        </select>
                        <div *ngIf="addQattendanceForm.submitted && attend_type_id.errors">
                          <label class="validation-message" *ngIf="attend_type_id.errors.required">Please select the attedance type.</label>
                        </div> 
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label>Time In</label>						
						<timepicker [(ngModel)]="quickAttendance.newtimein" name="newtimein" #timein="ngModel" ></timepicker>
					</div>
				</div>
			</div>			
		</div>    
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary">Submit</button>
			<button type="button" class="btn btn-danger" data-dismiss="modal" (click) = "hide()">Close</button>
		</div>
	</form>	
	<ng-template #elseBlock>
	<form #updateQattendanceForm="ngForm" (ngSubmit)="updateQattend(updateQattendanceForm)" autocomplete="off">													
		<div class="modal-header">
		<h5 class="modal-title">Edit Attendance</h5>
		         	
		</div>			
		<div class="modal-body">
			<input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="loggedUserId">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label>Attendance Type</label>						
						<select class="select2 form-control custom-select" #attend_type_id="ngModel" [(ngModel)]="quickAttendance.attend_type_id" name="attend_type_id" required>
                                    <option value=''>Select</option>  
                                    <option *ngFor="let attendancetype of attendanceTypesList" value="{{attendancetype._id}}">{{attendancetype.type_name}}</option>
                                </select>
                                <div *ngIf="addQattendanceForm.submitted && attend_type_id.errors">
                          <label class="validation-message" *ngIf="attend_type_id.errors.required">Please select the attedance type.</label>
                        </div> 
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label>Time OUT</label>
						<timepicker [(ngModel)]="quickAttendance.time_out" name="time_out" #time_out="ngModel" ></timepicker>
					</div>
				</div>
			</div>	
		</div>  
		<input type="hidden" class="form-control" name="attendance_id" #attendance_id="ngModel" [(ngModel)]="quickAttendance._id"> 
		<input type="hidden" class="form-control" [(ngModel)]="quickAttendance.time_in" name="time_in" #timein="ngModel">
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary">Submit</button>
			<button type="button" class="btn btn-danger" data-dismiss="modal" (click) = "hide()">Close</button>
		</div>
	</form>	
	</ng-template>
</div>