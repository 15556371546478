<div  id="myModal">         
    <div class="modal-header">
      <h4 class="modal-title">Edit Attendance</h4>
      <button type="button" class="close" data-dismiss="modal" (click) = "hide()">&times;</button>
    </div>
    <form #updateattendanceForm="ngForm" (ngSubmit)="updateattend(updateattendanceForm)" autocomplete="off">         
	    <div class="modal-body">     
	        <div class="row">
	            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
	                <div class="card-body">                 
		                <div class="form-group">
		                    <label for="exampleInputEmail1">Date {{data | date}}</label>
		                    <input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="loggedUserId">
		                    <input class="form-control" placeholder="Enter Date" [bsValue]="bsInlineValue" bsDatepicker (bsValueChange)="onValueChange($event)" #attend_date="ngModel" name="attend_date" [(ngModel)]="attendanceEdit.attend_date" value="attendanceEdit.attn_date"  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">
		                </div>
		                <div class="form-group leftsidebar">
		                    <label for="exampleInputEmail1">Time In</label>
		                    <timepicker [(ngModel)]="attendanceEdit.time_in" name="time_in" #timein="ngModel"></timepicker>
		                </div>                               

		                <div class="form-group rightsidebar">
		                    <label for="exampleInputEmail1">Time Out</label>
		                    <timepicker  [(ngModel)]="attendanceEdit.time_out" name="time_out" #time_out="ngModel"></timepicker>
		                </div>  

		                <div class="form-group">
		                    <label for="exampleInputEmail1">Attendance Type</label>
		                    <select class="select2 form-control custom-select" #attend_type_id="ngModel" [(ngModel)]="attendanceEdit.attend_type_id" name="attend_type_id" required>
		                        <option>Select</option>  
		                        <option *ngFor="let attendancetype of attendanceTypesList" value="{{attendancetype._id}}">{{attendancetype.type_name}}</option>
		                    </select>                   
		                </div>                    
	                    <input type="hidden" class="form-control" name="attendance_id" #id="ngModel" [(ngModel)]="attendanceEdit._id">
	                </div>                                                
	            </div>
	        </div>            
	    </div>        
	    <div class="modal-footer">
	      <button type="submit" class="btn btn-primary">Submit</button>
	      <button type="button" class="btn btn-danger" data-dismiss="modal" (click) = "hide()">Close</button>
	    </div>                              
    </form> 
</div>