import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AttendanceTypesService } from '../service/attendancetypes.service'
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: 'app-edit-attendancetypes',
  templateUrl: './edit-attendancetypes.component.html',
  styleUrls: ['./edit-attendancetypes.component.css']
})

export class EditAttendanceTypesComponent implements OnInit {
    title='Edit Attendance Types';
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    showSucessMessage: boolean;
    serverErrorMessages: string;

    constructor(private attendancetypesService: AttendanceTypesService,private route: ActivatedRoute,private router: Router) { }

    id; attendancetypeDetails;

    ngOnInit() {
	  	this.id = this.route.snapshot.paramMap.get('id');
	  	this.attendancetypesService.editAttendancetypes(this.id).subscribe(
		    res => {
		        this.attendancetypeDetails = res[0];
		         console.log(this.attendancetypeDetails);
		    },
		    err => { 
		        console.log(err);	        
		    }
	    );
	}

    onSubmit(form: NgForm) {
	    this.attendancetypesService.updateAttendancetypes(form.value).subscribe(
	        res => {
		        this.showSucessMessage = true;
		         setTimeout(() => {
		              this.showSucessMessage = false;
		              this.router.navigateByUrl('/list-attendancetypes');
		          }, 500);
		    },
	        err => {
		        if (err.status === 422) {
		          this.serverErrorMessages = err.error.join('<br/>');
		        }
		        else
		          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
		    }
	    );
	}

}
