import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../user/service/user.service';
import { Router } from "@angular/router";
import { EmployementService } from '../service/employement.service';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-add-employement-types',
  templateUrl: './add-employement-types.component.html',
  styleUrls: ['./add-employement-types.component.css']
})
export class AddEmployementTypesComponent implements OnInit {
  title='Add Employement Types';
  showSucessMessage: boolean;
  serverErrorMessages: string;
  roleForm: FormGroup;

  constructor(public employementService: EmployementService,private userService: UserService,private router: Router, private formBuilder: FormBuilder, private titleService: Title) { }

  ngOnInit() {
  }

   onSubmit(form: NgForm) {
      form.value["slug"] = this.userService.getusername();
      this.employementService.postEmployement(form.value).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => this.showSucessMessage = false, 500);
          this.resetForm(form);
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
  }

  resetForm(form: NgForm) {
      this.employementService.employementForm = {
        employement_id    : '',
        employement_name  : '',
        status  : '', 
      };
      form.resetForm();
      this.serverErrorMessages = '';
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
