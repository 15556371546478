import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NotificationService } from '../service/notification.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-notification-view-status',
  templateUrl: './notification-view-status.component.html',
  styleUrls: ['./notification-view-status.component.css']
})
export class NotificationViewStatusComponent implements OnInit {
  
  title='View User Notification';
  id;notificationDetails;employees;showDelSucessMessage;serverErrorMessages;showSucessMessage;
  dtOptions: DataTables.Settings = {"order": [[ 2, "desc" ]]};
  dtTrigger: Subject<any> = new Subject();

  constructor(private notificationService: NotificationService,private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {

        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 10,
          order: []
        };

        this.id = this.route.snapshot.paramMap.get('id');
        this.notificationService.viewUserNotificationStatus(this.id).pipe(map(this.extractData)
          ).subscribe(
		    res => {
		        this.notificationDetails = res[0];		       
		        this.employees=res[0].employees;
		         console.log(this.employees);
		        this.dtTrigger.next();
		    },
		    err => { 
		        console.log(err);	        
		    }
	    );
  }


    private extractData(res: Response) {
	    const body = res;
	    return body || {};
	}

}
