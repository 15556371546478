<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <!-- Success message -->
            <div class="success" *ngIf="showDelSucessMessage">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong> Deleted Successfully</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
            </div>
            <!-- Error message -->
            <div class="alert" *ngIf="serverErrorMessages">
              <!-- Error message -->
                  <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                    <strong> {{serverErrorMessages}}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>  
            </div>

            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Employee Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>                                
            </div>


            <div class="row">
                <div class="col-12">                               
                    <div class="card"> 
                        <div class="card-header">
                           <h3><i class="fa fa-users"></i> Employee Leave Request </h3>
                        </div>
                        <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" autocomplete="off">

                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="leaveApprovalSearch.employeeName" name="employeeName" placeholder="Employee Name">
                                        </div>                                
                                        <div class="form-group">                                           
                                           <input class="form-control" placeholder="Start Date" bsDatepicker #start_date="ngModel" name="start_date" id="start_date" [(ngModel)]="leaveApprovalSearch.start_date" value=""  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }"  >
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <select class="select2 form-control custom-select"  [(ngModel)]="leaveApprovalSearch.department" name="department">
                                                <option value="">Select Department</option>                                      
                                                <option  *ngFor="let department of departmentDetails" value="{{department._id}}">{{department.department_name}}</option>
                                            </select> 
                                        </div>
                                        <div class="form-group">
                                           <input class="form-control" placeholder="End Date" bsDatepicker #end_date="ngModel" name="end_date" id="end_date" [(ngModel)]="leaveApprovalSearch.end_date" value=""  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }" >
                                        </div> 
                                    </div>
                                </div>


                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"> 
                                    <div class="card-body"> 
                                         <div class="form-group">
                                            <select class="select2 form-control custom-select"  [(ngModel)]="leaveApprovalSearch.leavetypes" name="leavetypes">
                                                <option value="">Select Leave Type</option>                                       
                                                <option *ngFor="let leaveType of leaveTypesList" [ngValue]="leaveType.type_name" value="{{leaveType._id}}">{{leaveType.type_name}}</option>
                                            </select> 
                                        </div>
                                        <div class="form-group">
                                            <label></label>
                                            <input type="submit" role="button" class="btn btn-primary" value="Search">&nbsp;&nbsp;
                                            <button  type="button" (click)="clearFilter(signUpForm)"  class="btn btn-primary" > Reset </button>
                                        </div> 
                                    </div>
                                </div>

                            </div>
                        </form>


                        <div class="card-body"> 
                            <table datatable [dtOptions]="dtOptions" class="row-border hover"></table>                
                            <div class="table-responsive">
                                <table id="example2" class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                    <thead>
                                        <tr>
                                            <th>Full name</th>
                                            <th>Department</th>
                                            <th>Leave Type</th>  
                                            <th>Date</th> 
                                            <th>Status</th>                                              
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let employee of sortedArray" id="row{{employee.id}}">
                                            <td>{{employee.fullName}}</td>
                                            <td>{{employee.department}}</td>
                                            <td>{{employee.leave_type}}</td>
                                            <td>{{employee.date_from | date: 'yyyy-MM-dd'}}</td> 
                                            <td> 
                                                <span *ngIf="employee.leave_status==0;else lmapprove_content">Request Received</span>
                                                <ng-template #lmapprove_content> 
                                                    <span *ngIf="employee.leave_status==1;else hrapprove_content">Line Manager Approved</span>
                                                </ng-template>
                                                <ng-template #hrapprove_content> 
                                                    <span *ngIf="employee.leave_status==2;else approved_content">HR Approved</span>
                                                </ng-template>
                                                <ng-template #approved_content> 
                                                    <span *ngIf="employee.leave_status==3;else reject_content">Approved</span>
                                                </ng-template>
                                                <ng-template #reject_content> 
                                                    <span *ngIf="employee.leave_status==4;else cancel_content">Rejected</span>
                                                </ng-template>
                                                <ng-template #cancel_content> 
                                                    Cancelled
                                                </ng-template>
                                            </td>
                                            <td>
                                               <div *ngIf="(this.role =='User' && (employee.leave_status == 0 || employee.leave_status == 1 || employee.leave_status == 2)); then cancelBlock "></div>

                                                <ng-template #cancelBlock><a (click)="cancelLeave(employee._id)">Cancel</a></ng-template>

                                                <div *ngIf="(((employee.leave_status == 0 || employee.leave_status == 2 ) && (this.logged_user.manager !='' && this.logged_user.rep_person==true)) || ((employee.leave_status == 0 || employee.leave_status == 1 ) && (this.logged_user.manager =='' && this.logged_user.rep_person==true))); then approveBlock"></div>
                                                <ng-template #approveBlock><a (click)="approveLeave(employee._id,employee.emp_id)"> Approve / Reject</a></ng-template>

                                                <div *ngIf="(employee.leave_status == 3); then show_content "></div>
                                                <ng-template #show_content><a (click)="approveLeave(employee._id,employee.emp_id)"> Show Details</a></ng-template>
                                            </td>                                             
                                        </tr> 
                                        <tr  align="center" *ngIf="employee?.length == 0">
                                            <td colspan="6" class="no-data-available">No data Found!</td>
                                        </tr> 
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Full name</th>
                                            <th>Department</th>  
                                            <th>Leave Type</th>
                                            <th>Date</th> 
                                            <th>Status</th>                                               
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>