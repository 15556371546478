<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <!-- Success message -->
           <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong></strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>      
            </div>
              <!-- Error message -->
            <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                <strong> {{serverErrorMessages}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 

              <form #attendancetypeForm="ngForm"  autocomplete="off">  

                <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i> {{ title }}</h3>
                        </div>                            
                        <div class="card-body">
                            <div class="form-group">
                               

                              <p> Business policies establish the rules of conduct for a company. They not only describe the responsibilities for the employer and the employees, they also create a frame of reference for handling the countless issues that arise in an organization.</p>

<strong>Benefits of Policies</strong>
<p>
Well-written policies give lower level employees a way to handle problems and issues with having to constantly involve upper management every time they need to make a decision. Policies define the limits of decision-making and outline the alternatives. Employees understand the constraints of their jobs.</p>

<p>Policies communicate the individual and team responsibilities; this enables everyone to work together toward the company's objectives. Employees understand how to perform their tasks quickly and easily. In this way, managers are able to apply control by exception rather than having to micro-manage their employees' activities.</p>

<p>Clearly written policies give a company better protection from potential legal problems. When policies can be easily understood by a layperson, the company is on better legal ground in case of court challenges.</p>

<strong>Substance Abuse Policies</strong>
<p>Substance abuse can have adverse effects on an employee's attendance and job productivity. It can even be dangerous in certain jobs, such as construction or when handling hazardous materials.</p>

<p>A common policy is to prohibit the use of drug, alcohol or tobacco on company property during work hours or at company functions. If smoking is permitted, policies will describe the procedures that must be followed. Substance abuse policies also outline the requirements for drug-testing as a prerequisite to employment and the possibility of random drug-testing after being hired.</p>

<strong>Performance Review Policies</strong>
<p>Employee compensation and development policies outline the frequency and content of performance reviews. The objective of these policies is to identify areas for improvement and motivate workers to achieve better results.</p>

<p>Performance reviews clarify an employee's responsibilities and set goals. These reviews describe the basis for measuring performance and the steps needed to receive a bonus or a raise in salary.</p>

<strong>Dress Code Policies</strong>
<p>Some companies require employees to dress in a particular way when on the job. In a professional environment, men may be required to wear a tie, and women could be asked to dress in an appropriate business manner. If workers are dealing with the public, they could be required to wear uniforms that promote the company's image.</p>

<p>Businesses have the right to set guidelines for their employees' dress code, providing the rules are not discriminatory. Some dress policies may be required for safety reasons, such as no dangling jewelry for workers on an assembly line.</p>

<strong>Equal Opportunity Policies</strong>
<p>The company should state very clearly that their organization promotes fair treatment in the workplace. These policies prohibit discriminatory behavior from supervisors and employees on the basis of race, gender, religious beliefs or sexual orientation.</p>

<p>Anti-discriminatory policies help employees feel better and more comfortable on their jobs. The workers have more positive attitudes toward their work and are more productive.</p>

<strong>Safe Computer Use Policies</strong>
<p>Since computers are now used in almost every job, companies need policies that govern the use of computers for personal activities. These policies would place limits on receiving and sending personal emails and prohibit internet surfing. For security purposes, companies may ask employees to sign a release that lets a business monitor their workers' internet activity and emails.</p>

<p>Effective business policies establish a structured and organized work environment. They eliminate uncertainty in the workplace and define the ways to handle many of the issues that frequently arise.</p>


                            </div>

                                                 

                      

                            
                        </div>
                    </div>
                </div>
            </div>


            </form>
        </div>
    </div>
</div>

