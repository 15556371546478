import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NotificationService } from '../service/notification.service'
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { DepartmentService } from '../../admin/department/service/department.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.css']
})
export class AddNotificationComponent implements OnInit {

  title='Add Notifications';deptlist=[];
  departmentDetails;departmentUsers;emplist=[];
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  signUpForm: FormGroup;
  mailData;logged_user;slug;
  currdate : string;
  schedule_mail: string;
  dropdownList = {};
  selectedItems = [];
 // dropdownSettings = {};
  today;department;

  constructor(public notificationService: NotificationService,private userService: UserService,private router: Router,private formBuilder: FormBuilder,private http: HttpClient,private departmentService: DepartmentService,private datePipe: DatePipe) { }

  ngOnInit() {
        if(!this.userService.isLoggedIn()){
         this.router.navigateByUrl('/login');
        }

        this.signUpForm = this.formBuilder.group({
            notification_type : ['', Validators.required],
            name              : ['', Validators.required],
            from_name         : ['', Validators.required],
            from_email        : ['', Validators.required],            
            department        : ['', Validators.required],
            subject           : ['', Validators.required],
            content           : ['', Validators.required],            
            mail_status       : ['', Validators.required], 
            expire_date       : '',
        }); 
        this.slug=this.userService.getusername();
        
        this.departmentService.listDepartment(this.slug).subscribe(
          res => {
            this.departmentDetails = res;
          },
          err => { 
            console.log(err);            
          }
        );

        
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }


    onSubmit(form: NgForm) {       
        
      for(let departments of form.value.department) {
         this.deptlist.push(departments._id);
      }
      form.value["slug"] = this.userService.getusername();
      console.log(this.deptlist);
      this.notificationService.listDepartmentUsers(this.deptlist).subscribe(
        departmentUsers => {
            this.departmentUsers = departmentUsers;
            console.log(this.departmentUsers)
            for(let employee of this.departmentUsers) {
                let empids =  {emp_id:employee._id,fullName:employee.fullName,department:employee.department,emp_email:employee.email,viewstatus:'no',viewed_date:''};
                this.emplist.push(empids);
            }

            this.schedule_mail=this.datePipe.transform(form.value["schedule_mail"],'yyyy-MM-dd')

            form.value["notification_type"] = form.value["notification_type"]; 
            form.value["name"]          = form.value["name"];
            form.value["from_name"]     = form.value["from_name"];
            form.value["from_email"]    = form.value["from_email"];
            form.value["department"]    = this.deptlist;
            form.value["subject"]       = form.value["subject"];
            form.value["content"]       = form.value["content"];
            form.value["schedule_mail"] = this.schedule_mail;
            form.value["expire_date"]   = this.datePipe.transform(form.value["expire_date"],'yyyy-MM-dd');
            form.value["employee_id"]   = this.emplist;

            this.currdate = this.datePipe.transform(Date.now(),'yyyy-MM-dd');

            this.notificationService.postNotification(form.value).subscribe(
                  res => { 
                       let mail_msg = "Hi All, <br/><br/>";
                       let maillist = [];
                        for(let employee of this.emplist) {                     
                            maillist.push(employee.emp_email);
                        }

                        maillist.toString();

                        if(this.schedule_mail==this.currdate){
                            mail_msg  += form.value["content"];
                            mail_msg  += " <br/><br/> Regards ,<br/> Insight Team";
                            this.mailData = {'to':maillist,'from':form.value["from_email"],'subject':form.value["subject"],'text':mail_msg};

                            this.userService.sendMail(this.mailData).subscribe(
                              res => {
                               console.log(res); 
                              }, 
                            );
                        }                  

                        this.showSucessMessage = true;
                        setTimeout(() => {
                            this.showSucessMessage = false;
                        }, 4000);  //5s
                        this.resetForm(form);          
                  },
                  err => {
                    if (err.status === 422) {
                      this.serverErrorMessages = err.error.join('<br/>');
                    }
                    else
                      this.serverErrorMessages = 'Something went wrong.Please contact admin.';
                  }
              );











        }
      );

      
    }


    resetForm(form: NgForm) {
      this.notificationService.addNotification = {
            notification_type : '',
            name              : '',
            from_name         : '',
            from_email        : '',            
            department        : '',
            subject           : '',
            content           : '',            
            mail_status       : '', 
            expire_date       : '',
            schedule_mail     : '', 
            is_custom_mail    : '',

      };
      form.resetForm();
      this.serverErrorMessages = '';
  }


}
