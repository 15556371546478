<div class="content-page">
    <div class="content">            
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <!-- Success message -->
           <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong> Roles Added Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>      
            </div>
              <!-- Error message -->
            <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                <strong> {{serverErrorMessages}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 
            <form #roleForm="ngForm" (ngSubmit)="roleForm.valid && onSubmit(roleForm)" autocomplete="off">  
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9 col-xl-9">                        
                        <div class="card mb-3">
                            <div class="card-header">
                                <h3><i class="fa fa-check-square-o"></i> {{ title }}</h3>
                                <a [routerLink]="['/list-roles']" (click)="setTitle( 'List Roles' )" class="pull-right"><button type="button" class="btn btn-primary btn-sm ">List Roles</button></a>
                            </div>                            
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Role</label>
                                    <input type="text"  class="form-control" #role_name="ngModel" [(ngModel)]="roleService.roleForm.role_name" name="role_name" placeholder="Role Name" required>                                       
                                </div>                                                
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

