<div class="content-page">
    <div class="content">   
       <div class="container-fluid"> 
            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Attendance Added Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>            
            </div>
            <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                <strong> {{serverErrorMessages}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 

            <form #attendanceForm="ngForm" (ngSubmit)="attendanceForm.valid && onSubmit(attendanceForm)" autocomplete="off">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">                        
                        <div class="card mb-3">
                            <div class="card-header">
                                <h3><i class="fa fa-check-square-o"></i>Personal Info</h3>
                            </div>                            
                            <div class="card-body">          
                
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date {{data | date}}</label>
                                        <input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="loggedUserId">
                                        <input class="form-control" placeholder="Enter Date" [bsValue]="bsInlineValue" bsDatepicker (bsValueChange)="onValueChange($event)" #attend_date="ngModel" name="attend_date" [(ngModel)]="attendanceEdit.attend_date" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">                   
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Time In</label>
                                        <timepicker [(ngModel)]="attendanceEdit.time_in" name="time_in" #timein="ngModel"></timepicker>
                                    </div>                               

                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Time Out</label>
                                        <timepicker  [(ngModel)]="attendanceEdit.time_out" name="time_out" #time_out="ngModel"></timepicker>
                                    </div>  

                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Attendance Type</label>
                                        <select class="select2 form-control custom-select" #attend_type_id="ngModel" [(ngModel)]="attendanceEdit.attend_type_id" name="attend_type_id" required >
                                            <option>Select</option>  
                                            <option *ngFor="let attendancetype of attendanceTypesList" value="{{attendancetype._id}}">{{attendancetype.type_name}}</option>
                                        </select>                   
                                    </div>                    
                                    <input type="hidden" class="form-control" name="attendance_id" #id="ngModel" [(ngModel)]="attendanceEdit._id">
                                    <button type="submit" class="btn btn-primary">Submit</button>                  
                                                                
                            </div>                                                      
                        </div>                
                    </div>
                </div> 
            </form> 
        </div>
    </div>
</div>
