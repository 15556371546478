import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaveTypesService } from '../service/leavetypes.service'
import { UserService } from '../../../user/service/user.service';
import { Router } from "@angular/router";
import { Title }     from '@angular/platform-browser';
 
@Component({
  selector: 'app-add-leavetypes',
  templateUrl: './add-leavetypes.component.html',
  styleUrls: ['./add-leavetypes.component.css']
})

export class AddLeaveTypesComponent implements OnInit {

  title='Add Leave Types';
  showSucessMessage: boolean;slug;employementDetails;
  serverErrorMessages: string;
  leavetypeForm: FormGroup;

  constructor(public leavetypesService: LeaveTypesService,private userService: UserService,private router: Router, private formBuilder: FormBuilder, private titleService: Title) {
  }

  ngOnInit() {
      if(!this.userService.isLoggedIn()){
        this.router.navigateByUrl('/login');
      }
       this.slug = this.userService.getusername();
       this.leavetypesService.listEmployement(this.slug).subscribe(
          res => {
            this.employementDetails = res;
            console.log(this.employementDetails);
          },
      );

      this.leavetypeForm = this.formBuilder.group({
          type_name       : ['', Validators.required],
          employement     : ['', Validators.required],
          description     : ['', Validators.required],
          totalleaves     : ['', Validators.required],
          status          : ['', Validators.required],
      }); 
  }

  onSubmit(form: NgForm) {
      form.value["slug"] = this.userService.getusername();
      this.leavetypesService.postAddTypes(form.value).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => {
              this.showSucessMessage = false;
              this.router.navigateByUrl('/list-leavetypes');
          }, 500); 
          this.resetForm(form);          
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
  }

  resetForm(form: NgForm) {
      this.leavetypesService.addLeaveTypes = {
        type_name      : '',
        description    : '',
        employement    : '',
        duration       : '',
        totalleaves    : '',
        status         : true,       
      };
      form.resetForm();
      this.serverErrorMessages = '';
  }

 setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
