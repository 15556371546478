import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Employement } from './employement.model';
import { UserService } from '../../../user/service/user.service';

@Injectable({
  providedIn: 'root'
})

export class EmployementService {
  employementForm: Employement = {
    employement_id   : '',
    employement_name : '',
    status    : ''
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  showSucessMessage: boolean;
  serverErrorMessages: string;
  constructor( private http: HttpClient) { }

  postEmployement(employement: Employement){
    return this.http.post(environment.apiBaseUrl+'/addEmployement',employement,this.noAuthHeader);
  }

  listEmployement(slug) {
    return this.http.get(environment.apiBaseUrl + '/listEmployement/'+slug);
  }

  editEmployement(id) {
    return this.http.get(environment.apiBaseUrl + '/editEmployement/'+id);
  }

  updateEmployement(employement: Employement){
    return this.http.post(environment.apiBaseUrl + '/updateEmployement',employement,this.noAuthHeader);
  }

  deleteEmployement(id){ 
      this.http.get(environment.apiBaseUrl + '/deleteEmployement/'+id, {observe: 'response'})
      .subscribe(resp => {
            this.showSucessMessage = true;   
        },
        err => {
            if (err.status === 422) {
              this.serverErrorMessages = err.error.join('<br/>');
            }
            else
              this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        });
  }

  getusername() {    
    return localStorage.getItem('slug');
  }

}
