import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { UserService } from '../../user/service/user.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
constructor(private userService: UserService,private router : Router) { }

  model ={
    email :'',
  };
  serverErrorMessages: string;
  showSucessMessage: boolean;
  mailData;

  ngOnInit() {    
      if(this.userService.isLoggedIn()){
        this.router.navigateByUrl('/login');
      }
  }

onSubmit(form: NgForm) {

      this.userService.forgot(form.value).subscribe(
        res => {
            this.userService.setToken(res['token']); 
            var url=document.getElementsByTagName('base')[0].href +'resetpassword/'+ btoa(res['email']);

            let mail_msg = "Hi " + res['fullName'] +", <br/><br/>";
            mail_msg += 'Please reset your password using the following link.';
            mail_msg  +="<a href="+ url +">Reset your account password.</a>";
            mail_msg  += " <br/><br/> Regards ,<br/> Insight Team";

            this.mailData = {'to':res['email'],'from':'admin@subhosting.net','subject':'Password Reset link from Insight','text':mail_msg};
             
            this.userService.sendMail(this.mailData).subscribe(
              res => {
                if(res) {
                  this.showSucessMessage = true;
                  setTimeout(() => {                     
                        this.router.navigateByUrl('/login');
                  }, 1000);
                }
              },
              err => { 
                console.log(err);            
              }
            );
            this.showSucessMessage = true;
            this.serverErrorMessages = '';
        },
        err => {
          this.serverErrorMessages = err.error.message;
          this.showSucessMessage = false;
        }
    );
  }

}
