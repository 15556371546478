import { Component, OnInit, OnDestroy, TemplateRef  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AttendanceTypesService } from '../service/attendancetypes.service'
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-list-attendancetypes',
  templateUrl: './list-attendancetypes.component.html',
  styleUrls: ['./list-attendancetypes.component.css']
})

export class ListAttendanceTypesComponent implements  OnDestroy, OnInit {

  title='List Attendance Types';
	attendancetypeDetails;slug;
  modalRef: BsModalRef;
  message: string;
  showDelSucessMessage: Boolean;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor( private attendancetypesService: AttendanceTypesService, private router: Router, private modalService: BsModalService, private http: HttpClient, private titleService: Title) { }

  ngOnInit() {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10
      };
      this.slug=this.attendancetypesService.getusername();
      this.attendancetypesService.listAttendancetypes(this.slug).pipe(map(this.extractData)
        ).subscribe(attendancetypeDetails => {
          this.attendancetypeDetails = attendancetypeDetails;
          this.dtTrigger.next();
      });
  }

  deleteAttendancetype( id) {
    const initialState = {
            del_id: id,
            page: 'attendance_type',
        };
    this.modalRef = this.modalService.show(ConfirmBoxComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }   

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
