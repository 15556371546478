<div class="content-page">
    <div class="content">            
        <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active"><a routerLink="/list-employees/">List Employees</a></li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
              </div>
              <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Employee Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>      
              </div>
              <!-- Error message -->
              <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                    <strong> {{serverErrorMessages}}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
              </div>   

  
          <div class="row userprofile">  
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">                     
                <div class="card mb-3">
                    <div class="card-header">
                        <h3><i class="fa fa-user"></i> Personal Info</h3>                             
                    </div>
                        
                    <div class="card-body">    
                        <div class="row">                      
                            <div class="col-lg-9 col-xl-9">                            
                                <div class="row">               
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Full name</label>
                                            <input class="form-control" value="{{employeesDetails.fullName}}"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Display Name</label>
                                            <input class="form-control" value="{{employeesDetails.display_name}}" required />
                                        </div>
                                    </div>  
              
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                           <label>Gender</label>
                                           <input class="form-control" value="{{employeesDetails.gender}}" />
                                        </div>
                                    </div>                                                  
                                    
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Date of Birth</label>
                                            <input class="form-control"  value="{{employeesDetails.dob | date: 'dd/MM/yyyy'}}" />
                                        </div>
                                    </div>   
            
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input class="form-control" value="{{employeesDetails.email}}" />
                                        </div>
                                    </div>                                                          
                                    
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Address</label>
                                            <input class="form-control"  value="{{employeesDetails.address}}" />
                                        </div>
                                    </div>   
     
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Locality</label>
                                            <input class="form-control"  value="{{employeesDetails.locality}}" />
                                        </div>
                                    </div>   
                                </div>                               
                            </div> 
                            <div class="col-lg-3 col-xl-3 border-left">
                                <b>Register date : </b> {{employeesDetails.joining_date | date: 'dd-MM-yyyy'}} 
                                <br />                            
                                <div class="m-b-10"></div>                            
                                <div id="avatar_image">
                                    <img alt="image" style="max-width:200px; height:auto;" src="{{employeesAvatar}}" />
                                </div>           
                            </div>
                        </div>                       
                    </div>   
                </div>

                <div class="card mb-3">
                    <div class="card-header">
                        <h3><i class="fa fa-user"></i> Employee Office details</h3>                             
                    </div>
                        
                    <div class="card-body">    
                        <div class="row">                      
                            <div class="col-lg-12 col-xl-12">                            
                                <div class="row">               
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Employee Code</label>
                                            <input class="form-control" value="{{employeesDetails.emp_code}}"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Joining date</label>
                                            <input class="form-control" value="{{employeesDetails.joining_date}}" required />
                                        </div>
                                    </div>  
             
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                           <label>Chat name</label>
                                           <input class="form-control" value="{{employeesDetails.chat_name}}" />
                                        </div>
                                    </div>                                                          
                                    
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Department</label>
                                            <input class="form-control"  value="{{employeesDetails.departmentname[0].department_name}}" />
                                        </div>
                                    </div>   
             
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Role</label>
                                            <input class="form-control" value="{{employeesDetails.role}}" />
                                        </div>
                                    </div>                                                          
                                    
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Description</label>
                                            <input class="form-control"  value="{{employeesDetails.description}}" />
                                        </div>
                                    </div>   
     
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Manager</label>
                                            <input class="form-control"  value="{{employeesDetails.manager.fullName}}" />
                                        </div>
                                    </div>   
    
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Reporting Person</label>
                                            <input class="form-control"  value="{{employeesDetails.reporting_person}}" />
                                        </div>
                                    </div>   
                                </div>        
                            
                            </div>                           
                        </div>          
                    </div>   
                </div>
            </div>
          </div>
    </div>
  </div>
</div>