<div class="content-page">
    <div class="content">            
        <div class="container-fluid"> 
            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i> Notification</h3>
                        </div>                            
                        <div class="card-body">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Fullname</label> : {{ notificationDetails.name }}
                            </div>
                            <div class="form-group">
                                <label for="fromname">From Name</label> : {{ notificationDetails.from_name }}
                            </div>
                            <div class="form-group">
                                <label for="from_email">From Email</label> : {{ notificationDetails.from_email }}
                            </div>
                            <div class="form-group">
                                <label for="subject">Subject</label> : {{ notificationDetails.subject }}
                            </div>
                            <div class="form-group">
                                <label for="subject">Subject</label> : {{ notificationDetails.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
