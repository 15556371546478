import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user/service/user.service';
import { ActivatedRoute, Params, Router} from '@angular/router';
import { Subject } from 'rxjs';
import { EmployeesService } from '../../employees/service/employees.service';
import { map } from 'rxjs/operators';
import { LeaveRequestService } from '../../leave/service/leaverequest.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	title='Dashboard';
	employeesDetails;rowid;loggedUserId;emp_leave_req;display_name;department;employeeDetails;id;role;userdetails;dept;
	numCasualleavetaken;casualLeaveBal;numSickleavetaken;sickLeaveBal;slug;
	dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

	constructor(private userService: UserService, private router: Router,private employeesService: EmployeesService,private leaverequestService: LeaveRequestService) {}

	ngOnInit() {
		if(!this.userService.isLoggedIn()){
	      this.router.navigateByUrl('/login');
	    }
	    this.loggedUserId = this.userService.getUserDtls();
	    this.id = this.loggedUserId._id;	    
	    this.dtOptions = {
	      pagingType: 'full_numbers',
	      pageLength: 10
	    };
	    this.slug = this.employeesService.getusername();
	    this.role = this.userService.getUserRole();
	      this.userdetails = this.userService.getUserDtls();
	      if(this.role=='Admin'){
	         this.dept='Admin';
	      }else if(this.role=='Manager'){
	         this.dept= this.userdetails.user_dept;
	      }else{
	        this.dept= this.userdetails.user_dept;
	      }

	    this.employeesService.listEmployee(this.dept,this.slug).pipe(map(this.extractData)
	      ).subscribe(employeesDetails => {
	        this.employeesDetails = employeesDetails;
	        this.dtTrigger.next();
	    });


	    this.leaverequestService.getEmployee(this.id).subscribe(
	        res => {
	            this.employeeDetails  = res; 
	            this.emp_leave_req    = this.employeeDetails.emp_leave_req;
	            this.display_name     = this.employeeDetails.display_name;
	            this.department       = this.employeeDetails.department;
		    
	        },
	        err => { 
	                     
	        }
        );
	} 

	private extractData(res: Response) {
	    const body = res;
	    return body || {};
	}

}
