<div class="content-page">
    <div class="content">   
       <div class="container-fluid"> 
        <div class="row">
            <div class="col-xl-12">
                <div class="breadcrumb-holder">
                    <h1 class="main-title float-left">{{ title }}</h1>
                    <ol class="breadcrumb float-right">
                        <li class="breadcrumb-item">Home</li>
                        <li class="breadcrumb-item active">{{ title }}</li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div *ngIf="showSucessMessage">                
               <!-- Success message -->                 
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>Leave Request Added Successfully!</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
              </div>            
        </div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
            <strong> {{serverErrorMessages}}</strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div> 
        <!--  <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                       <h3><i class="fa fa-users"></i> Employees Details</h3>
                    </div>
                    <div class="card-body">                        
                        <div class="row">
                            <div class="col-6">
                                <p><label>Name:</label> {{display_name}}</p>
                                <p><label>Leave Balance:</label> </p>
                                <p>Sick: {{ sickLeaveBal }}</p>
                                <p>Casual: {{ casualLeaveBal }}</p>
                            </div>
                            <div class="col-6">
                                <p><label>Department:</label> {{department}}</p>
                                <p><label>Leave Already Taken:</label> </p>
                                <p>Sick: {{numSickleavetaken}}</p>
                                <p>Casual: {{numCasualleavetaken}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/> -->
        <div class="row">
            <div class="col-12">                       
                    <div class="card">
                        <div class="card-header">
                           <h3><i class="fa fa-users"></i> Leave Requests</h3>
                        </div>
                        <form #filterForm="ngForm" (ngSubmit)="filterForm.valid && onSubmit(filterForm)" autocomplete="off">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <select class="se lect2 form-control custom-select"  [(ngModel)]="filtersearch.leavetype" name="leavetype">
                                                <option value="">Select Leave Type</option>
                                                <option  *ngFor="let leaveType of leaveTypesList" value="{{leaveType._id}}">{{leaveType.type_name}}</option>
                                            </select>
                                        </div>                   
                                        <div class="form-group">                                           
                                           <input class="form-control" placeholder="Start Date" bsDatepicker #start_date="ngModel" name="start_date" id="start_date" [(ngModel)]="filtersearch.start_date" value=""  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }"  >
                                        </div> 
                                        
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <div class="form-group">
                                            <select class="select2 form-control custom-select" [(ngModel)]="filtersearch.leavestatus" name="leavestatus">
                                                <option value="">Select Leave Status</option>                           
                                                <option value="0">Send Only</option>                                     
                                                <option value="1">Line Manger Approved</option>
                                                <option value="2">HR Approved</option>
                                                <option value="3">Approved</option>
                                                <option value="4">Rejected</option>
                                                <option value="5">Canceled</option>
                                            </select>
                                        </div>
                                        </div>

                                        <div class="form-group">
                                           <input class="form-control" placeholder="End Date" bsDatepicker #end_date="ngModel" name="end_date" id="end_date" [(ngModel)]="filtersearch.end_date" value=""  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }" >
                                        </div> 
                                        <div class="form-group">
                                            <label></label>
                                            <input type="submit" role="button" class="btn btn-primary" value="Search">&nbsp;&nbsp;
                                            <button  type="button" (click)="clearFilter(filterForm)"  class="btn btn-primary" > Reset </button>
                                        </div>            
                                        
                                    </div>
                                </div>
                            </div>
                        </form>

                        
                        <div class="card-body">
                            <!-- <h5 class="card-title">Leave Request List</h5> -->
                            <div class="table-responsive">
                                <table id="example2" class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                    <thead>
                                        <tr>
                                            <th>Leave Type</th>
                                            <th>Leave Mode</th>                                                
                                            <th>Days No</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="emp_leave_req">
                                       <tr *ngFor="let leave of leaves" id="row{{leave._id}}">
                                            <td>{{leave.leave_type}}</td>
                                            <td>
                                                <span *ngIf="leave.leave_mode==1;else halfday_cont">Full Day</span>
                                                <ng-template #halfday_cont> Half Day</ng-template>
                                            </td>
                                            <td>{{leave.days_no}}</td>
                                            <td>{{leave.date_from  | date: 'yyyy-MM-dd'}} 
                                                <span *ngIf="leave.date_to">- {{leave.date_to  | date: 'yyyy-MM-dd'}} </span>
                                            </td>
                                                                   
                                            <td> 
                                                <span *ngIf="leave.leave_status==0;else lmapprove_content">Request Send</span>
                                                <ng-template #lmapprove_content> 
                                                    <span *ngIf="leave.leave_status==1;else hrapprove_content">Line Manager Approved</span>
                                                </ng-template>
                                                <ng-template #hrapprove_content> 
                                                    <span *ngIf="leave.leave_status==2;else approved_content">HR Approved</span>
                                                </ng-template>
                                                <ng-template #approved_content> 
                                                    <span *ngIf="leave.leave_status==3;else reject_content">Approved</span>
                                                </ng-template>
                                                <ng-template #reject_content> 
                                                    <span *ngIf="leave.leave_status==4;else cancel_content">Rejected</span>
                                                </ng-template>
                                                <ng-template #cancel_content> 
                                                    Cancelled
                                                </ng-template>
                                            </td>
                                            <td>
                                               <div *ngIf="(this.role =='User' && (leave.leave_status == 0 || leave.leave_status == 1 || leave.leave_status == 2)); then cancelBlock "></div>

                                                <ng-template #cancelBlock><a (click)="cancelLeave(leave._id)">Cancel</a></ng-template>

                                                <div *ngIf="(((leave.leave_status == 0 || leave.leave_status == 2 ) && (this.logged_user.manager !='' && this.logged_user.rep_person==true)) || ((leave.leave_status == 0 || leave.leave_status == 1 ) && (this.logged_user.manager =='' && this.logged_user.rep_person==true))); then approveBlock"></div>
                                                <ng-template #approveBlock><a (click)="approveLeave(leave._id)"> Approve / Reject</a></ng-template>

                                                <div *ngIf="(leave.leave_status == 3); then show_content"></div>
                                                <ng-template #show_content><a (click)="approveLeave(leave._id)"> Show Details</a></ng-template>
                                            </td>
                                       </tr>
                                       <tr *ngIf="emp_leave_req?.length == 0"  align="center">
                                            <td colspan="6" class="no-data-available">No data Found!</td>
                                        </tr> 
                                    </tbody>                                        
                                    <tfoot>
                                        <tr>
                                            <th>Leave Type</th>
                                            <th>Leave Mode</th>                                                
                                            <th>Days No</th>
                                            <th>Date</th>
                                            <!-- <th>Notes</th>
                                            <th>Pre Approved</th>
                                            <th>Pre Approved By</th> -->
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
   </div>
</div>