import { Component, OnInit, TemplateRef  } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AttendanceService } from '../attendance/service/attendance.service'
import { UserService } from '../user/service/user.service';

import { NgForm } from '@angular/forms';
@Component({
  selector: 'quick-attendance',
  templateUrl: './quick-attendance.component.html',
  styles: ['#quick-attendance.modal-body {  height: 450px; overflow-y: none; }']   
})

export class QuickAttendanceComponent implements OnInit {
  quickAttendance; loggedUserId;attendanceTypesList;isTodayIn;isTodayOut;id;todayAttendance; slug;
  message: string;
  showDelSucessMessage: Boolean;
  showSucessMessage: Boolean;
  serverErrorMessages: string;
  page:string;
  curtime: Date = new Date();
  updateQattendanceForm: FormGroup;
  addQattendanceForm: FormGroup;
  attendanceRes;  

  constructor(  private router: Router, private modalRef: BsModalRef, private modalService: BsModalService, public attendanceService: AttendanceService, public userService: UserService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.quickAttendance={attend_date:'',newtimein:this.curtime,attend_type_id:''};
      if(!this.userService.isLoggedIn()){
        this.router.navigateByUrl('/login');
      }
      this.loggedUserId = this.userService.getUserID();

      this.isTodayIn= false;
      this.isTodayOut= false;

      this.updateQattendanceForm = this.formBuilder.group({
         attend_type_id    : ['', Validators.required],
      });

      this.addQattendanceForm = this.formBuilder.group({
         attend_type_id    : ['', Validators.required],
      });
      this.attendanceService.getTodaysAttendance(this.loggedUserId).subscribe(
        res => {
          this.attendanceRes = res;
          if(!this.attendanceRes || !this.attendanceRes.length) {  
            this.isTodayIn= false;
            this.isTodayOut= false; 
          } else {
            this.isTodayIn= true;
            this.isTodayOut= true;
            this.quickAttendance = res[0];  
            this.quickAttendance['time_out'] =  this.curtime;
          }         
        },
        err => { 
          console.log(err);            
        }
        
    );

    this.slug=this.userService.getusername();
    this.attendanceService.getAttendanceTypes(this.slug).subscribe(
        res => {
          this.attendanceTypesList = res;                      
          },
        err => { 
          console.log(err);            
        }
    );
  }


  addQattend(addattendanceForm: NgForm) {

    if(addattendanceForm.value['attend_type_id']) {
      addattendanceForm.value["attend_date"] =this.curtime;
      addattendanceForm.value["slug"] = this.userService.getusername(); 
      this.attendanceService.postAddAttendance(addattendanceForm.value).subscribe(
      res => {
        this.showSucessMessage = true;
        setTimeout(() => {
            this.showSucessMessage = false;
            window.location.reload();
        }, 1000); 
      },
      err => {
        if (err.status === 422) {
          this.serverErrorMessages = err.error.join('<br/>');
        }
        else
          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
      }
    ); 
  }
      
  } 

  updateQattend(updateattendanceForm: NgForm) {
    updateattendanceForm.value["attend_date"] =this.curtime;
    let selTimeIn = updateattendanceForm.value["time_in"];
    let selTimeOut = updateattendanceForm.value["time_out"];
    updateattendanceForm.value["newtimein"] =selTimeIn; 
    updateattendanceForm.value["newtimeOut"] =selTimeOut;
    updateattendanceForm.value["slug"] = this.userService.getusername();  
    var newtimein  = new Date(selTimeIn);
    var newtimeout = new Date(selTimeOut);
    var diffMs     = (newtimeout.getTime() - newtimein.getTime());
    var diffHrs    = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins   = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    updateattendanceForm.value["total_hours"] =diffHrs +":"+ diffMins;

    this.attendanceService.postupdateAttendance(updateattendanceForm.value).subscribe(
      res => {
        this.showSucessMessage = true;
        setTimeout(() => {
            this.showSucessMessage = false;
            window.location.reload();
        }, 1000); 
      },
      err => {
        if (err.status === 422) {
          this.serverErrorMessages = err.error.join('<br/>');
        }
        else
          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
      }
    ); 
  }
    
  hide(): void {
    this.modalRef.hide();
    this.showDelSucessMessage = false;
  }
 

}
