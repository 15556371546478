 <div class="modal-lg">
 	    <div class="success" *ngIf="showSucessMessage">
	      {{ sucessMessage }}
	    </div>
	    <div class="success" *ngIf="showDelSucessMessage">
	      Deleted Successfully
	    </div>

    <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" autocomplete="off">
	<div class="modal-content1">
		<div class="modal-header">
		<h5 class="modal-title">Employee Details</h5>
		<button type="button" class="close" data-dismiss="modal" (click)="closeM()" aria-label="Close">
		  <span aria-hidden="true">&times;</span>
		</button>
		</div>
		<div class="modal-body">
		    <div class="row">
		        <div class="col-12 leavetype" >
		            <p><label>Name:</label> {{display_name}}</p>
		            <p><label>Leave Date from:</label> {{date_from  | date: 'dd-MM-yyyy'}}</p>
		            <p *ngIf="date_to"><label>Leave Date to:</label> {{date_to  | date: 'dd-MM-yyyy'}}</p>
		            <p><label>Leave Type:</label> {{ leave_type }}</p>
		            <p><label>Description:</label> {{ notes ?  notes : ''}}</p>		            
		            <p><label>Available Leave:</label> {{leaveBal}}</p>		        
		            <p><label>Last Leave:</label>
		            <span *ngIf="lastLeave; else lastLeaveElseBlock"> 
		            	<span *ngIf="leaveReqDetails.lastLeave[0].date_from">{{ leaveReqDetails.lastLeave[0].date_from | date: 'dd/MM/yyyy'}}</span> 
		            	<span *ngIf="leaveReqDetails.lastLeave[0].date_to"> - {{ leaveReqDetails.lastLeave[0].date_to | date: 'dd/MM/yyyy'}}</span>
		            </span>
		             <ng-template #lastLeaveElseBlock>No Leave Taken</ng-template>
		            </p>
		            <p><label>Days since last leave taken:</label> {{ leavdiffdays }}</p>
		            <p><label id="exculde">Exculde other Manager Approvals:</label> <input type="checkbox" class="form-control" [(ngModel)]="isActive" name="exculde" id="exculdediv" value='yes' ></p>	
		            
		        </div>
	    	</div>
		</div>
		<div class="modal-footer" *ngIf="show">
			<input type="hidden" class="form-control" [(ngModel)]="emp_id" name="emp_id">
			<input type="hidden" class="form-control" [(ngModel)]="display_name" name="display_name">
			<input type="hidden" class="form-control" [(ngModel)]="user_email" name="user_email">
			<input type="hidden" class="form-control" [(ngModel)]="mail_list" name="mail_list">
			<input type="hidden" class="form-control" [(ngModel)]="leave_app" name="leave_app">
			<input type="hidden" class="form-control" [(ngModel)]="page" name="page">

		<button type="submit" class="btn btn-primary">Approve</button>
		<!-- <button type="submit" class="btn btn-primary" (click)="approve(emp_id,display_name,user_email,mail_list,leave_app,this.page)" >Approve</button> -->
	    <button type="button" class="btn btn-secondary" (click)="reject(emp_id,leave_app)" >Reject</button>
		<button type="button" class="btn btn-danger" (click)="closeM()">Close</button>
	  </div>
	</div>
   </form>
  </div>