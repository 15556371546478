import { Component, OnInit } from '@angular/core';
import { LeaveRequestService } from '../service/leaverequest.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { LeaveApproveComponent } from '../leave-approve/leave-approve.component';
import { map } from 'rxjs/operators';
import { UserService } from '../../user/service/user.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { EmployeesService } from '../../employees/service/employees.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-leave-approval-pending',
  templateUrl: './leave-approval-pending.component.html',
  styleUrls: ['./leave-approval-pending.component.css']
})
export class LeaveApprovalPendingComponent implements OnInit {
  title='List LeaveRequest';
  employeesList;employee;leaverequest;leave;leavedetails=[];sortedArray;id;logged_user;showSucessMessage;attendanceSearch;departmentDetails;leaveTypesList;employeesDetails;leaveApprovalSearch;slug;leavedetail;department;department_name;leave_typename;role;emp_leave_req;fullname;
  serverErrorMessages;sucessMessage;showDelSucessMessage;
  modalRef: BsModalRef;   
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private leaverequestService: LeaveRequestService, private router: Router, private modalService: BsModalService, private userService: UserService,private departmentService: DepartmentService,private employeesService: EmployeesService, private datePipe: DatePipe) { }

  ngOnInit() {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        order: [ 3, 'desc' ],
      };
      this.role = this.userService.getUserRole();
      this.slug=this.userService.getusername();
      this.logged_user = this.userService.getUserDtls(); 
      this.leaveApprovalSearch={employeeName:'',department:'',employeeEmail:'',start_date:'',end_date:'',leavetypes:''}; 
      this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        }
      );
      
      this.employeesService.getLeaveTypes(this.slug).subscribe(
          res => {
            this.leaveTypesList = res; 
          },
      );      
      this.leaverequestService.listLeaveApprovalPending(this.slug).subscribe(
          res => { 
            this.employeesList  = res;
            this.emp_leave_req = new Array();
            this.dtTrigger.unsubscribe(); 
            for(let employeeList of this.employeesList) {
              if(employeeList.emp_leave_req.length>0) {
                for(let emp_leavedetails of employeeList.emp_leave_req) {              
                    for(let leavesrequest of employeeList.leavedetails) {
                      if(leavesrequest._id==emp_leavedetails.leave_type_id){
                        var leave_typename=leavesrequest.type_name;
                      }
                    }
                  let aryy_elemnst = {_id: emp_leavedetails._id, emp_id: employeeList._id, fullName: employeeList.fullName, department: employeeList.departmentname[0].department_name, leave_type: leave_typename, leave_typename_id:emp_leavedetails.leave_type_id, days_no:emp_leavedetails.days_no,date_from: emp_leavedetails.date_from, date_to: emp_leavedetails.date_to,leave_status: emp_leavedetails.leave_status };
                    this.emp_leave_req.push(aryy_elemnst);
                }
              }
            }

            if(this.emp_leave_req.length>0){   
              this.sortedArray = this.emp_leave_req.sort(function(a, b) {
                  let dateA = new Date(a.date_from).valueOf() ; let dateB = new Date(b.date_from).valueOf() ;
                  return dateA - dateB;
              });                   
            }

            console.log(this.sortedArray)
            this.dtTrigger.next();  
          }
      );
  }

  approveLeave(leave_id,emp_id){
    const initialState = {
          emp_id: emp_id,
          leave_app:leave_id,
          page:'empleave',
    };
    this.modalRef = this.modalService.show(LeaveApproveComponent, { initialState });
    this.modalRef.content.user = initialState;
    this.modalRef.content.closeBtnName = 'Close';
    
  }

  cancelLeave(leave_id) {
    if(confirm("Are you sure to delete "+leave_id)) {
      var leaveStatus = 5;
      let res_data = {empid:this.id,leaveapp:leave_id,leave_status:leaveStatus};
   
      this.leaverequestService.leaveRequestResponse(res_data).subscribe(
            res => {            
              this.showSucessMessage = true;
              this.sucessMessage = 'Leave Cancelled'; 
               setTimeout(() => {
                    this.showSucessMessage = false;
                    this.modalService.hide(1);
                    this.router.navigateByUrl('/list-leave-request');
                }, 4000);             
            },
            err => {
             
              if (err.status === 422) {
                this.serverErrorMessages = err.error.join('<br/>');
              }
              else
                this.serverErrorMessages = 'Something went wrong.Please contact admin.'; 
                this.modalService.hide(1);
          }
      ); 
    } 
  }


  onSubmit(form: NgForm) {   

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [ 3, 'desc' ],
    };   

    form.value["start_date"]      = this.datePipe.transform(form.value["start_date"],'yyyy-MM-dd');    
    form.value["end_date"]        = this.datePipe.transform(form.value["end_date"],'yyyy-MM-dd');
    form.value["slug"]            = this.userService.getusername();
    this.dtTrigger.unsubscribe(); 
    this.leaverequestService.searchLeaveApprovalPending(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        

        this.leavedetails=[];
 
            this.emp_leave_req = new Array();
            this.sortedArray = new Array();
            this.employeesList = employeesDetails;
            this.dtTrigger.unsubscribe(); 
            if(this.employeesList.length>0) {
              for(let employeeList of this.employeesList) {
              if(employeeList.emp_leave_req.length>0) {
                for(let emp_leavedetails of employeeList.emp_leave_req) {              
                    for(let leavesrequest of employeeList.leavedetails) {
                      if(leavesrequest._id==emp_leavedetails.leave_type_id){
                        var leave_typename=leavesrequest.type_name;
                      }
                    }
                  let aryy_elemnst = {_id: emp_leavedetails._id, fullName: employeeList.fullName, department: employeeList.departmentname[0].department_name, leave_type: leave_typename, leave_typename_id:emp_leavedetails.leave_type_id, days_no:emp_leavedetails.days_no,date_from: emp_leavedetails.date_from, date_to: emp_leavedetails.date_to,leave_status: emp_leavedetails.leave_status };
                    this.emp_leave_req.push(aryy_elemnst);
                }
              }
            }

             if(this.emp_leave_req.length>0){   
              this.sortedArray = this.emp_leave_req.sort(function(a, b) {
                  let dateA = new Date(a.date_from).valueOf() ; let dateB = new Date(b.date_from).valueOf() ;
                  return dateA - dateB;
              });                   
            }
          }
        this.dtTrigger.next();   
    });
  }

  clearFilter(form: NgForm) {
    this.leaveApprovalSearch={employeeName:'',department:'',employeeEmail:'',start_date:'',end_date:'',leavetypes:''}; 
   //dateA = [];
    this.dtTrigger.unsubscribe(); 
    this.leaverequestService.listLeaveApprovalPending(this.slug).subscribe(
        res => { 
          this.employeesList  = res;
          this.emp_leave_req = new Array();
          this.sortedArray = new Array();
          for(let employeeList of this.employeesList) {
            if(employeeList.emp_leave_req.length>0) {
              for(let emp_leavedetails of employeeList.emp_leave_req) {              
                  for(let leavesrequest of employeeList.leavedetails) {
                    if(leavesrequest._id==emp_leavedetails.leave_type_id){
                      var leave_typename=leavesrequest.type_name;
                    }
                  }
                let aryy_elemnst = {_id: emp_leavedetails._id, fullName: employeeList.fullName, department: employeeList.departmentname[0].department_name, leave_type: leave_typename, leave_typename_id:emp_leavedetails.leave_type_id, days_no:emp_leavedetails.days_no,date_from: emp_leavedetails.date_from, date_to: emp_leavedetails.date_to,leave_status: emp_leavedetails.leave_status };
                  this.emp_leave_req.push(aryy_elemnst);
              }
            }
          }

          if(this.emp_leave_req.length>0){   
            this.sortedArray = this.emp_leave_req.sort(function(a, b) {
                let dateA = new Date(a.date_from).valueOf() ; let dateB = new Date(b.date_from).valueOf() ;
                return dateA - dateB;
            });                   
          }


          this.dtTrigger.next();  
        }
    );         
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
  }

}
