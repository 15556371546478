import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { EmployeesService } from '../../employees/service/employees.service';
import { ReportsService } from '../service/reports.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-daily-attendance',
  templateUrl: './daily-attendance.component.html',
  styleUrls: ['./daily-attendance.component.css']
})
export class DailyAttendanceComponent implements OnInit {
  title='Daily Attendance';
  slug;age;rowid;employeesDetails;departmentDetails;roleDetails;reportFilters;
  employeeAttendance;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private titleService: Title, private employeesService: EmployeesService, private reportsService: ReportsService, private departmentService: DepartmentService) { }

  ngOnInit() {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.slug = this.employeesService.getusername();
    
    this.reportFilters={department:'',role:'',attend_date:new Date()}; 

    this.reportsService.listAttendanceByDate(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        console.log(employeesDetails)
        this.dtTrigger.next();
    });

    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.employeesService.listRoles(this.slug).subscribe(
        res => {
          this.roleDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );
    
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  onSubmit(form: NgForm) {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.employeeAttendance  = new Array;
    this.employeesDetails  = new Array;
    this.dtTrigger.unsubscribe(); 
    form.value["slug"] = this.employeesService.getusername();
    this.reportsService.searchAttendanceByDate(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeeAttendance = employeesDetails;
        for(let employeeAtt of this.employeeAttendance) { 
          if(employeeAtt.employee.length >0 ) {
            this.employeesDetails.push(employeeAtt);
          }
        }        
        this.dtTrigger.next();
    });
  }

  clearFilter(form: NgForm) {
    this.reportFilters={department:'',role:'',attend_date:new Date()}; 
    this.slug = this.employeesService.getusername();
    this.dtTrigger.unsubscribe(); 
    this.reportsService.listAttendanceByDate(this.slug).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.dtTrigger.next();
    });
  }

}
