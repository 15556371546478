import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { EmployeesService } from '../../employees/service/employees.service';
import { LeaveRequestService } from '../../leave/service/leaverequest.service';
import { ReportsService } from '../service/reports.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-timeoff-balances',
  templateUrl: './timeoff-balances.component.html',
  styleUrls: ['./timeoff-balances.component.css']
})
export class TimeoffBalancesComponent implements OnInit {
  title='Timeoff Balances';
  slug;age;rowid;employeesDetails;departmentDetails;roleDetails;reportFilters;leaveTypesList;
  employeeLeaves;def_leavtype;def_leavtype_totleave;def_employement;sel_leavtypes;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private titleService: Title, private employeesService: EmployeesService, private reportsService: ReportsService, private departmentService: DepartmentService, private leaveRequestService: LeaveRequestService) { }

  ngOnInit() {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.slug = this.employeesService.getusername();

    this.employeesService.getLeaveTypes(this.slug).subscribe(
      res => {
        this.leaveTypesList         = res; 
        this.def_leavtype           = this.leaveTypesList[0]._id;
        this.def_employement        = this.leaveTypesList[0].employement;
        this.def_leavtype_totleave  = this.leaveTypesList[0].totalleaves;
        this.employeeLeaves         = new Array();
        this.reportsService.listTimeOffBalances(this.slug,this.def_leavtype,this.def_employement).pipe(map(this.extractData)
            ).subscribe(employeesDetails => {
              this.employeesDetails = employeesDetails;
              for(let employeeAtt of this.employeesDetails) { 
                //if(employeeAtt.emp_leave_req.length >0) {
                  var leave_bal       = this.def_leavtype_totleave - employeeAtt.leave_taken.length;
                  let leaveVals       = {fullName:employeeAtt.fullName, begining_bal: this.def_leavtype_totleave, used_leave: employeeAtt.leave_taken.length, end_bal : leave_bal, departmentname:employeeAtt.departmentname, roles:employeeAtt.roles }
                  this.employeeLeaves.push(leaveVals);
                //}

              }
              this.dtTrigger.next();
          });
      },
      err => { 
        console.log(err);            
      }
    );

   

    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.employeesService.listRoles(this.slug).subscribe(
        res => {
          this.roleDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.reportFilters={leavetype:'',department:'',role:'',start_date:'',end_date:''}; 
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  onSubmit(form: NgForm) {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.employeeLeaves  = new Array;
    this.employeesDetails  = new Array;
    this.dtTrigger.unsubscribe(); 
    form.value["slug"] = this.employeesService.getusername();
    
    this.leaveRequestService.getLeaveByID(form.value['leavetype']).subscribe(
      res => {
        
          this.sel_leavtypes           = res;
          this.def_leavtype           = this.sel_leavtypes._id;
          this.def_leavtype_totleave  = this.sel_leavtypes.totalleaves;
          form.value["employement"] = this.sel_leavtypes.employement;
          this.reportsService.searchTimeOffBalances(form.value).pipe(map(this.extractData)
          ).subscribe(employeesDetails => { 
            this.employeesDetails = employeesDetails;
            for(let employeeAtt of this.employeesDetails) { 
                //if(employeeAtt.emp_leave_req.length >0) {
                  var leave_bal       = this.def_leavtype_totleave - employeeAtt.leave_taken.length;
                  let leaveVals       = {fullName:employeeAtt.fullName, begining_bal: this.def_leavtype_totleave, used_leave: employeeAtt.leave_taken.length, end_bal : leave_bal, departmentname:employeeAtt.departmentname, roles:employeeAtt.roles }
                  this.employeeLeaves.push(leaveVals);
                //}

              }

            this.dtTrigger.next();
          });


        },
      err => { 
          console.log(err);            
        }
    );


  }

  clearFilter(form: NgForm) {
    this.reportFilters={leavetype:'',department:'',role:'',start_date:'',end_date:''}; 
    this.slug = this.employeesService.getusername();
    this.dtTrigger.unsubscribe(); 
    this.employeesService.getLeaveTypes(this.slug).subscribe(
      res => {
        this.leaveTypesList         = res; 
        this.def_leavtype           = this.leaveTypesList[0]._id;
        this.def_leavtype_totleave  = this.leaveTypesList[0].totalleaves;
        this.def_employement        = this.leaveTypesList[0].employement;
        this.employeeLeaves = new Array();

        this.reportsService.listTimeOffBalances(this.slug,this.def_leavtype,this.def_employement).pipe(map(this.extractData)
            ).subscribe(employeesDetails => {
              this.employeesDetails = employeesDetails;
              for(let employeeAtt of this.employeesDetails) { 
                //if(employeeAtt.emp_leave_req.length >0) {
                  var leave_bal       = this.def_leavtype_totleave - employeeAtt.leave_taken.length;
                  let leaveVals       = {fullName:employeeAtt.fullName, begining_bal: this.def_leavtype_totleave, used_leave: employeeAtt.leave_taken.length, end_bal : leave_bal, departmentname:employeeAtt.departmentname, roles:employeeAtt.roles }
                  this.employeeLeaves.push(leaveVals);
                //}

              }
              this.dtTrigger.next();
          });
      },
      err => { 
        console.log(err);            
      }
    );
  }

}
