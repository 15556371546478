import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  title='Reports';
  showSucessMessage;serverErrorMessages;
  constructor(private titleService: Title) { }

  ngOnInit() {
  }


    public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
