import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LeaveTypesService } from '../service/leavetypes.service'
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: 'app-edit-leavetypes',
  templateUrl: './edit-leavetypes.component.html',
  styleUrls: ['./edit-leavetypes.component.css']
})

export class EditLeaveTypesComponent implements OnInit {

	title='Edit Leave Types';slug;employementDetails;
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    showSucessMessage: boolean;
    serverErrorMessages: string;
    id; leavetypeDetails;

    constructor(private leavetypesService: LeaveTypesService,private route: ActivatedRoute,private router: Router) { }    

    ngOnInit() {
	   	this.id = this.route.snapshot.paramMap.get('id');
	  	this.slug = this.leavetypesService.getusername();
        this.leavetypesService.listEmployement(this.slug).subscribe(
          res => {
            this.employementDetails = res;
            console.log(this.employementDetails);
          },
        );

	  	this.leavetypesService.editLeavetypes(this.id).subscribe(
		    res => {
		        this.leavetypeDetails = res[0];
		        console.log(this.leavetypeDetails);
		    },
		    err => { 
		        console.log(err);	        
		    }
	    );
	}

    onSubmit(form: NgForm) {
	    this.leavetypesService.updateLeavetypes(form.value).subscribe(
	        res => {
		        this.showSucessMessage = true;
		         setTimeout(() => {
		              this.showSucessMessage = false;
		              this.router.navigateByUrl('/list-leavetypes');
		          }, 4000);
		    },
	        err => {
		        if (err.status === 422) {
		          this.serverErrorMessages = err.error.join('<br/>');
		        }
		        else{
		          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
		        }
		    }
	    );
	}

}
