<div class="content-page">
    <div class="content">            
        <div class="container-fluid">                    
            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">Dashboard</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>              
                           
                            
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">                      
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-users"></i> List Employees</h3>
                             
                        </div>                                
                        <div class="card-body">                                
                            <div class="table-responsive">
                                    <table id="example2" class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                        <thead>
                                            <tr>
                                                <th>Full name</th>
                                                <th>Employee Code</th>
                                                <th>Department</th>
                                                <th>Role</th>
                                                <th>Reporting Person</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let employee of employeesDetails" id="row{{employee._id}}">
                                                <td>{{employee.fullName}}</td>
                                                <td>{{employee.emp_code}}</td>
                                                <td>{{employee.departmentname[0].department_name}}</td>
                                                <td>{{employee.roles[0].role_name}}</td>
                                                <td>{{employee.reporting_person}}</td>
                                                <td>                                      
                                                    <a routerLink="/view-employees/{{employee._id}}"><i class="fa fa-eye medfonts" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="View"></i>  </a> 
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Full name</th>
                                                <th>Employee Code</th>                                                
                                                <th>Department</th>
                                                <th>Role</th>
                                                <th>Reporting Person</th>
                                                <th>Action</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>                               
                        </div>                                                      
                    </div><!-- end card-->                  
                </div>                    
                    
            
                              
            </div>     
        </div>
    </div>
</div>


    <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.4.0/Chart.min.js"></script>

    <script>
        var ctx1 = document.getElementById("lineChart").getContext('2d');
        var lineChart = new Chart(ctx1, {
            type: 'bar',
            data: {
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [{
                        label: 'Dataset 1',
                        backgroundColor: '#3EB9DC',
                        data: [10, 14, 6, 7, 13, 9, 13, 16, 11, 8, 12, 9] 
                    }, {
                        label: 'Dataset 2',
                        backgroundColor: '#EBEFF3',
                        data: [12, 14, 6, 7, 13, 6, 13, 16, 10, 8, 11, 12]
                    }]
                    
            },
            options: {
                            tooltips: {
                                mode: 'index',
                                intersect: false
                            },
                            responsive: true,
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                }],
                                yAxes: [{
                                    stacked: true
                                }]
                            }
                        }
        });


        var ctx2 = document.getElementById("pieChart").getContext('2d');
        var pieChart = new Chart(ctx2, {
            type: 'pie',
            data: {
                    datasets: [{
                        data: [12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        label: 'Dataset 1'
                    }],
                    labels: [
                        "Red",
                        "Orange",
                        "Yellow",
                        "Green",
                        "Blue"
                    ]
                },
                options: {
                    responsive: true
                }
         
        });


        var ctx3 = document.getElementById("doughnutChart").getContext('2d');
        var doughnutChart = new Chart(ctx3, {
            type: 'doughnut',
            data: {
                    datasets: [{
                        data: [12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        label: 'Dataset 1'
                    }],
                    labels: [
                        "Red",
                        "Orange",
                        "Yellow",
                        "Green",
                        "Blue"
                    ]
                },
                options: {
                    responsive: true
                }
         
        });
    </script>