import {Component, Directive, Input, ViewChild,OnInit} from '@angular/core'; 
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../service/notification.service';
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { Subject } from 'rxjs';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarComponent } from '@fullcalendar/angular';  
import { EventInput } from '@fullcalendar/core';  
import dayGridPlugin from '@fullcalendar/daygrid'; 
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  attendanceTypesList;attend_dates;Date;notificationDetails;employeesDetails=[];attendance_typename;  showModal: boolean; slug; notificationlist;departmentDetails=[];
  title='Calendar';
  showSucessMessage: boolean;
  serverErrorMessages: string;
  attendanceForm: FormGroup;
  data: string;
  timeout_data: string;
  timein_data: string;
  loggedUserId: string;
  @ViewChild('calendar',null) calendarComponent: FullCalendarComponent; 
  options: any;  
  eventsModel: any[] = [];  
  calendarVisible = true;  
  calendarWeekends = true;  
  calendarEvents: EventInput[] = [  
    { title: 'Event Now', start: new Date() }  
  ];  
  toggleVisible() {  
    this.calendarVisible = !this.calendarVisible;  
  } 

  constructor(public notificationService: NotificationService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private datePipe: DatePipe) { }

  ngOnInit() {

    this.loggedUserId = this.userService.getUserID();
    this.options = { 
      default: true,
      editable: true,  
      disableDragging: false,  
      selectable: true,  
      theme: 'bootstrap', 
      hiddenDays: [ 6,7], 
      header: {  
        right: 'prev,next, today',
        center: '',  
        left: 'title', 
      },  
      validRange: {  
        start: '2017-05-01',  
        end: '2019-06-01'  
      },  
      plugins: [dayGridPlugin]  
    };  

    this.notificationlist={name:'',subject:'',content:'', from_name:'', from_email:''};
    this.slug=this.userService.getusername();
    this.notificationService.listNotification('HR',this.slug).subscribe(notificationDetails => {
            this.notificationDetails = notificationDetails; 
            for(let notification of this.notificationDetails) {
                    let attend =  {title:notification.subject,start: notification.schedule_mail,id:notification._id};
                    this.employeesDetails.push(attend);
            }
        this.calendarEvents = this.employeesDetails;

    });

  }


  eventClick(model) { 
    this.attendanceForm = this.formBuilder.group({
      attend_date       : ['', Validators.required],
      time_in           : ['', Validators.required],
      time_out          : ['', Validators.required],
      attend_type_id    : ['', Validators.required],
    });

    this.notificationService.editNotification(model.event.id).subscribe(
      res => {
        this.notificationlist = res[0]; 
       },
      err => { 
        console.log(err);            
      }
    );
    this.showModal = true;
  }

  hide(){
    this.showModal = false;
  }  

}
