<div class="content-page">
    <div class="content">            
        <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active"><a routerLink="/list-employees/">List Employees</a></li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
              </div>
              <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Employee Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>      
              </div>
              <!-- Error message -->
              <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                    <strong> {{serverErrorMessages}}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
              </div>   

            <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" autocomplete="off"> 

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i>Personal Info</h3>
                        </div>                            
                        <div class="card-body">                
            
                            <div class="form-group">
                                <label for="exampleInputEmail1">Fullname</label>
                                <input type="text"  class="form-control" #fullName="ngModel" [(ngModel)]="employeesDetails.fullName" name="fullName" placeholder="Full Name" required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !fullName.valid }">
                                <div *ngIf="signUpForm.submitted && fullName.errors">
                                  <label class="validation-message" *ngIf="fullName.errors.required">Please enter the fullname.</label>
                                </div>                   
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Display Name</label>
                                <input type="text" class="form-control" #display_name="ngModel" [(ngModel)]="employeesDetails.display_name" name="display_name" placeholder="Display Name" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !display_name.valid }">
                                <div *ngIf="signUpForm.submitted && display_name.errors">
                                  <label class="validation-message" *ngIf="display_name.errors.required">Please enter the Display name.</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Gender</label>
                                <div class="form-check">
                                    <div class="radiocon">
                                      <div class="custom-control custom-radio radiobutton">
                                          <input type="radio" class="custom-control-input" id="customControlValidation1" name="gender" #gender="ngModel" [(ngModel)]="employeesDetails.gender" name="gender" value="male">
                                          <label class="custom-control-label" for="customControlValidation1">Male</label>                                
                                      </div>
                                      <div class="custom-control custom-radio radiobutton">
                                          <input type="radio" class="custom-control-input" id="customControlValidation2" #gender="ngModel" [(ngModel)]="employeesDetails.gender" name="gender" value="female">
                                          <label class="custom-control-label" for="customControlValidation2">Female</label>
                                      </div>
                                      <div class="custom-control custom-radio radiobutton">
                                          <input type="radio" class="custom-control-input" id="customControlValidation3" #gender="ngModel" [(ngModel)]="employeesDetails.gender" name="gender" value="unspecified">
                                          <label class="custom-control-label" for="customControlValidation3">Unspecified</label>
                                      </div>
                                    </div>  
                                </div>
                                <div *ngIf="signUpForm.submitted && gender.errors">
                                      <label class="validation-message"  *ngIf="gender.errors.required">Please select the gender.</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Date of Birth</label>
                                <input type="text" class="form-control date-inputmask" id="date-mask" #dob="ngModel" [(ngModel)]="employeesDetails.dob" name="dob" placeholder="Date of Birth" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !dob.valid }" [maxDate]="today" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">

                                <div *ngIf="signUpForm.submitted && dob.errors">
                                  <label class="validation-message"  *ngIf="dob.errors.required">Please enter the date of birth.</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="text"  class="form-control" #email="ngModel" [(ngModel)]="employeesDetails.email" name="email" placeholder="Email"  required [pattern]="emailRegex"  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !email.valid }" autocomplete="off">
                                <div *ngIf="signUpForm.submitted && email.errors">
                                  <label *ngIf="email.errors.required" class="validation-message">Email field is required.</label>
                                  <label *ngIf="email.errors.pattern" class="validation-message">Please enter a valid email address.</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Address</label>
                                <textarea class="form-control" #address="ngModel" [(ngModel)]="employeesDetails.address" name="address" required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !address.valid }"></textarea>
                                <div *ngIf="signUpForm.submitted && address.errors">
                                  <label class="validation-message" *ngIf="address.errors.required">Please enter the address.</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputpincode">Pincode</label>
                                <input type="number" class="form-control" #pincode="ngModel" [(ngModel)]="employeesDetails.pincode" name="pincode" placeholder="Pincode"  required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !pincode.valid }" autocomplete="off">
                                <div *ngIf="signUpForm.submitted && pincode.errors">
                                  <label class="validation-message" *ngIf="pincode.errors.required">Please enter the pincode.</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Locality</label>
                                <input type="text" class="form-control" #locality="ngModel" [(ngModel)]="employeesDetails.locality" name="locality" required placeholder="Locality" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !locality.valid }">
                                <div *ngIf="signUpForm.submitted && locality.errors">
                                  <label class="validation-message"  *ngIf="locality.errors.required">Please enter the address.</label>
                                </div>
                            </div>                                        
                            <button type="submit" class="btn btn-primary">Submit</button>      
                        </div>                                                      
                    </div>                
                </div>


                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i>Employee Office details</h3>
                        </div>                            
                        <div class="card-body">             
                            <div class="form-group">
                                <label for="exampleInputEmail1">Employee Code</label>
                                <input type="text" class="form-control" #emp_code="ngModel" [(ngModel)]="employeesDetails.emp_code" name="emp_code" placeholder="Employee Code" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !emp_code.valid }">
                                <div *ngIf="signUpForm.submitted && emp_code.errors">
                                  <label class="validation-message" *ngIf="emp_code.errors.required">Please enter the employee code.</label>
                                </div>                   
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Joining date</label>
                                <input type="text" class="form-control" #joining_date="ngModel" [(ngModel)]="employeesDetails.joining_date" name="joining_date" placeholder="Joining date" required [maxDate]="today" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !joining_date.valid }">
                                <div *ngIf="signUpForm.submitted && joining_date.errors">
                                  <label class="validation-message" *ngIf="joining_date.errors.required">Please enter the joining date.</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Chat Name</label>
                                <input type="text" class="form-control" [(ngModel)]="employeesDetails.chat_name" name="chat_name" placeholder="Chat name">
                            </div>

                            <div class="form-group">
                                <label for="exampleInputPassword1">Department</label>
                                <select class="select2 form-control custom-select" #department="ngModel" [(ngModel)]="employeesDetails.department" name="department" required (change)="getDeptManager($event.target.value)" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !department.valid }">
                                      <option value="">Select</option>                                          
                                      <option  *ngFor="let department of departmentDetails" value="{{department._id}}">{{department.department_name}}</option>
                                </select>   
                                <div *ngIf="signUpForm.submitted && department.errors">
                                  <label class="validation-message" *ngIf="department.errors.required">Please select the department.</label>
                                </div>  
                            </div>

                            <div class="form-group">
                                <label for="exampleInputPassword1">Role</label>
                                <select class="select2 form-control custom-select" #role="ngModel" [(ngModel)]="employeesDetails.role" name="role" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !role.valid }">
                                        <option value="">Select</option>                                          
                                        <option  *ngFor="let role of roleDetails" value="{{role._id}}">{{role.role_name}}</option>                                    
                                </select>
                                <div *ngIf="signUpForm.submitted && role.errors">
                                  <label class="validation-message" *ngIf="role.errors.required">Please select the Role.</label>
                                </div> 
                            </div>

                            <div class="form-group">
                                <label for="exampleInputPassword1">Description</label>
                                <textarea class="form-control" #description="ngModel" [(ngModel)]="employeesDetails.description" name="description" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !description.valid }"></textarea>
                                <div *ngIf="signUpForm.submitted && description.errors">
                                  <label class="validation-message" *ngIf="description.errors.required">Please enter the description.</label>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="exampleInputPassword1">Line Manager</label>
                                <select class="select2 form-control custom-select"  #manager="ngModel" [(ngModel)]="employeesDetails.manager" name="manager" >
                                        <option value="">Select</option>                                          
                                        <option *ngFor="let employee of devManagerList" value="{{employee._id}}" >{{employee.fullName}}</option>                                
                                </select> 
                            </div>

                            <div class="form-group">
                                <label for="exampleInputPassword1">HR Manager</label>
                                <select class="select2 form-control custom-select"  #hrmanager="ngModel" [(ngModel)]="employeesDetails.hrmanager" name="hrmanager" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !hrmanager.valid }">
                                        <option value="">Select</option>                                          
                                        <option *ngFor="let employee of employeesListDetails" value="{{employee._id}}" >{{employee.fullName}}</option>                                
                                </select>
                                <div *ngIf="signUpForm.submitted && hrmanager.errors">
                                  <label class="validation-message" *ngIf="hrmanager.errors.required">Please select the hrmanager.</label>
                                </div>  
                            </div>

                            <div class="form-group ">                                                              
                                <input type="checkbox" class="col-md-1" #reporting_person="ngModel" [(ngModel)]="employeesDetails.reporting_person" name="reporting_person" placeholder="Name">
                                <label >Reporting Person</label>
                            </div>
                             <div class="form-group ">                                                              
                                <input type="checkbox" class="col-md-1" #admin_access="ngModel" [(ngModel)]="employeesDetails.admin_access" name="admin_access" >
                                <label >Admin access</label>
                            </div>


                            <div class="form-group ">
                                <label>Employee Type</label>                              
                                <select class="select2 form-control custom-select"  #employee_type="ngModel" [(ngModel)]="employeesDetails.employee_type" name="employee_type" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !hrmanager.valid }">
                                    <option>Select</option>                                          
                                    <option *ngFor="let employementtype of employement" value="{{employementtype._id}}" >{{employementtype.employement_name}}</option>
                                </select>
                                <div *ngIf="signUpForm.submitted && employee_type.errors">
                                  <label class="validation-message" *ngIf="employee_type.errors.required">Please select the employee_type.</label>
                                </div>                               
                            </div>
                            <div class="form-group ">
                                <label>Status</label>                              
                                <select class="select2 form-control custom-select"  #status="ngModel" [(ngModel)]="employeesDetails.status" name="status" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !status.valid }">
                                        <option value="">Select</option>                                          
                                        <option value="A">Active</option>                                          
                                        <option value="I">Inactive</option>                                          
                                        <option value="R">Resigned</option>                                         
                                </select>
                                <div *ngIf="signUpForm.submitted && status.errors">
                                  <label class="validation-message" *ngIf="status.errors.required">Please select the status.</label>
                                </div>                               
                            </div>   


                            <input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="employeesDetails._id"> 

                           <div class="form-group">
                              <label>File Upload</label>                           
                                <div class="custom-file">
                                    <!--<input type="file" class="custom-file-input" id="validatedCustomFile" required=""> -->
                                    <input type="file" name="image" (change)="fileProgress($event)" />
                                   <input  *ngIf="employeesDetails.avatar" type="hidden" class="form-control" #old_avatar="ngModel" [(ngModel)]="employeesDetails.avatar" name="old_avatar" placeholder="Employee Code" required> 
                                </div> 
                                <div class="image-preview mb-3" *ngIf="employeesAvatar">
                                    <img src="{{employeesAvatar}}"  height="200">
                                </div>                                  
                            </div>
                            <div *ngIf="fileUploadProgress">
                                Upload progress: {{ fileUploadProgress }}
                            </div>
                            <div class="image-preview mb-3" *ngIf="previewUrl">
                                <img [src]="previewUrl" height="300" />                 
                            </div>
                            <div class="mb-3" *ngIf="uploadedFilePath">
                                {{uploadedFilePath}}
                            </div>                        
                                                            
                        </div>                                                      
                    </div>                
              </div>
          </div>   
       </form>  
    </div>
  </div>
</div>