<div class="content-page">
    <div class="content">            
        <div class="container-fluid"> 
            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                                      
                <div class="col-12">
                  <div class='app'> 
                    <full-calendar #fullcalendar [editable]="true" [events]="calendarEvents"  
                        [header]="options.header" [customButtons]="options.customButtons" 
                        [plugins]="options.plugins" [columnHeaderHtml]="options.columnHeaderHtml" (click)="eventClick($event)"></full-calendar>  
                  </div>
                    <div class="card">
                        <div class="card-header">
                           <h3><i class="fa fa-users"></i> Monthly Attendance</h3>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table id="example2" class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  >
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Time In</th>                                            
                                            <th>Time Out</th>
                                            <th>Total</th>
                                            <th>Job Title</th>
                                            <th>Department</th> 
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr *ngFor="let employee of employeesDetails" id="row{{employee._id}}">
                                            <td>{{employee.employee[0].fullName}}</td>
                                            <td>{{employee.attend_date | date: 'yyyy-MM-dd'}}</td>
                                            <td>{{employee.time_in| date: 'HH:mm'}}</td>
                                            <td>{{employee.time_out| date: 'HH:mm'}}</td>
                                            <td>{{employee.total_hours}}</td>
                                            <td>{{employee.roles[0].role_name}}</td>
                                            <td>{{employee.departmentname[0].department_name}}</td>
                                        </tr>
                                        
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Time In</th>                                            
                                            <th>Time Out</th>
                                            <th>Total</th>
                                            <th>Job Title</th>
                                            <th>Department</th> 
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
         </div>
    </div>
</div>                                
                               

