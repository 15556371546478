<div class="content-page">
    <div class="content">            
        <div class="container-fluid"> 

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <!-- Success message -->
            <div class="success" *ngIf="showDelSucessMessage">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong> Deleted Successfully</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
            </div>
            <!-- Error message -->
            <div class="alert" *ngIf="serverErrorMessages">
              <!-- Error message -->
                  <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                    <strong> {{serverErrorMessages}}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>  
            </div>

            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>                                
            </div>


            <div class="row">
                <div class="col-12">                       
                    <div class="card">
                         <div class="card-header">
                           <h3><i class="fa fa-users"></i> List Notificaton</h3>
                        </div>
                        <form #filterForm="ngForm" (ngSubmit)="filterForm.valid && onSubmit(filterForm)" autocomplete="off">

                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="notifiSearch.noti_name" name="noti_name" placeholder="Name">
                                        </div>                                
                                       <div class="form-group">
                                            <input type="text" class="form-control mydatepicker" id="date-mask1" #start_date="ngModel" [(ngModel)]="notifiSearch.start_date" name="start_date" placeholder="Start Date" [ngClass]="{'invalid-textbox' :filterForm.submitted && !start_date.valid }" [maxDate]="today" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">
                                        </div>
                                         
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <select class="se lect2 form-control custom-select"  [(ngModel)]="notifiSearch.notification_type" name="notification_type">
                                               <option value="">Select</option>   
                                               <option value="birthday">Birthday</option>
                                               <option value="holiday">Holiday</option>
                                               <option value="announcements">Custom Announcements</option>
                                            </select>  

                                        </div> 
                                        <div class="form-group">
                                            <input type="text" class="form-control mydatepicker" id="date-mask1" #end_date="ngModel" [(ngModel)]="notifiSearch.end_date" name="end_date" placeholder="End Date" [ngClass]="{'invalid-textbox' :filterForm.submitted && !end_date.valid }" [maxDate]="today" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">
                                        </div>
                                         
                                        <div class="form-group">
                                            <label></label>
                                            <input type="submit" role="button" class="btn btn-primary" value="Search">
                                            &nbsp;&nbsp;
                                            <button  type="button" (click)="clearFilter(filterForm)"  class="btn btn-primary" > Reset </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-body">
                           
                            <div class="table-responsive">
                                <table id="example2" class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Notificaton Type</th>
                                            <th>Name</th>
                                            <th>Schedule Date</th>                               
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let notification of notificationDetails; let i = index;" id="row{{notification._id}}">
                                            <td>{{i+1}}</td>
                                            <td>{{notification.notification_type}} </td>
                                            <td>{{notification.name}}</td>
                                            <td>{{notification.schedule_mail | date: 'yyyy-MM-dd'}}</td>  
                                            <td>
                                                <a routerLink="/edit-notification/{{notification._id}}" *ngIf="role=='Admin'"><i class="fa fa-pencil medfonts" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Edit" ></i> </a> 
                                                <a (click)="deleteNotification(notification._id)" *ngIf="role=='Admin'"><i class="fa fa-trash medfonts" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Delete"></i>  </a>
                                                 <a routerLink="/notification-status/{{notification._id}}" *ngIf="role=='Admin'"><i class="fa fa-list medfonts" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="View Notification Status" ></i> </a>  
                                                  <a routerLink="/view-notification/{{notification._id}}"><i class="fa fa-eye medfonts" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Detail View" ></i> </a>                                  
                                            </td>
                                        </tr>
                                        <tr *ngIf="notificationDetails?.length == 0"  align="center">
                                            <td colspan="5" class="no-data-available">No data Found!</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Notificaton Type</th>
                                            <th>Name</th>
                                            <th>Schedule Date</th>                               
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>






