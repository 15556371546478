<div class="wrapper">
    <div id="formContent">
    	<h2 class="underlineHover"  routerLink="/login"  routerLinkActive="active"> Reset password </h2>
    	<div>
      <img src="../../assets/img/users.png" id="icon" alt="User Icon" />
    </div>
    <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)">
      <input type="password" #password="ngModel" [(ngModel)]="userService.userdata.password" name="password" placeholder="Enter new password"
  required  minlength="6" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !password.valid }">
      <div *ngIf="signUpForm.submitted && password.errors">
        <label *ngIf="password.errors.required" class="validation-message">This field is required.</label>
        <label *ngIf="password.errors.minlength" class="validation-message">Enter atleast 6 characters.</label>
      </div>
  
      <input type="password" #confirmpassword="ngModel" [(ngModel)]="userService.userdata.confirmpassword" name="confirmpassword" placeholder="Confirm Password"
  minlength="6" required [ngClass]="{'invalid-textbox':signUpForm.submitted && !confirmpassword.valid}">
      <div *ngIf="signUpForm.submitted && confirmpassword.errors">
        <label *ngIf="confirmpassword.errors.required" class="validation-message">This field is required.</label>
        <label *ngIf="confirmpassword.errors.minlength" class="validation-message">Enter atleast 6 characters.</label>
      </div>

      <input type="hidden" #id="ngModel" [(ngModel)]="employeesDetails" name="id">

      <input type="submit" value="Reset">
    </form>

    <!-- Success message -->
    <div class="success" *ngIf="showSucessMessage">
      Updated successfully
    </div>

    <!-- Error message -->
    <div class="alert" *ngIf="serverErrorMessages">
      {{serverErrorMessages}}
    </div>
  </div>
</div>