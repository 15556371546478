<div class="content-page">
    <div class="content">   
      <div class="container-fluid"> 

        <div class="row">
            <div class="col-xl-12">
                <div class="breadcrumb-holder">
                    <h1 class="main-title float-left">{{ title }}</h1>
                    <ol class="breadcrumb float-right">
                        <li class="breadcrumb-item">Home</li>
                        <li class="breadcrumb-item active">{{ title }}</li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <div *ngIf="showSucessMessage">                
               <!-- Success message -->                 
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>Password Changed Successfully!</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
              </div>            
        </div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
            <strong> {{serverErrorMessages}}</strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" autocomplete="off">  
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">                        
                <div class="card mb-3">
                    <div class="card-header">
                        <h3><i class="fa fa-check-square-o"></i>Personal Info</h3>
                    </div>                            
                    <div class="card-body">                
                      <input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="loggedUserId">

                        <div class="form-group">
                            <label for="exampleInputEmail1">New Password</label>
                            <input type="password" class="form-control" #password="ngModel" [(ngModel)]="userService.userdata.password" name="password"  minlength="6" required placeholder="Enter New Password" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !password.valid }">
                            <div *ngIf="signUpForm.submitted && password.errors">
                              <label *ngIf="password.errors.required" class="validation-message">This field is required.</label>
                              <label *ngIf="password.errors.minlength" class="validation-message">Enter atleast 6 characters.</label>
                            </div>                   
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Confirm Password</label>
                            <input type="password" class="form-control" #confirmpassword="ngModel" [(ngModel)]="userService.userdata.confirmpassword" name="confirmpassword"  minlength="6" required placeholder="Confirm Password" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !confirmpassword.valid }">
                            <div *ngIf="signUpForm.submitted && confirmpassword.errors">
                              <label *ngIf="confirmpassword.errors.required" class="validation-message">This field is required.</label>
                              <label *ngIf="confirmpassword.errors.minlength" class="validation-message">Enter atleast 6 characters.</label>
                            </div>
                        </div>                       
                       
                        <button type="submit" class="btn btn-primary">Submit</button>      
                    </div>                                                      
                </div>                
            </div>
        </div>
      </form>
    </div>
  </div>
</div>    