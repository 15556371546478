import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { ActivatedRoute } from "@angular/router";
import { NgForm } from '@angular/forms';
import { Router } from "@angular/router";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

	employeesDetails;
	showSucessMessage: boolean;
  	serverErrorMessages: string;

	constructor(public userService: UserService,private route: ActivatedRoute,private router: Router) { }

	ngOnInit() {
	    if(this.userService.isLoggedIn()){
            location.reload();
		    this.userService.deleteToken();
		    this.router.navigate(['/login']);
        }
		this.employeesDetails = this.route.snapshot.paramMap.get('id');
	}

	onSubmit(form : NgForm){
		if(form.value.password != form.value.confirmpassword) {
			this.serverErrorMessages = 'Password Mismatch';
		} else {

		    form.value["email"] = atob(form.value["id"]); 
			this.userService.resetpassword(form.value).subscribe(
			    res => {
			    	if(res['error']) {
			    		this.serverErrorMessages = res['error'];
			    		this.showSucessMessage = false;
			    	} else {
			    	    this.showSucessMessage = true;
			    		this.serverErrorMessages = '';
			    		setTimeout(() => {				              
				              this.router.navigateByUrl('/login');
				        }, 1000);
			    		
			    	}	       
			    },
			    err => {
			        this.serverErrorMessages = err.error.message;
			    }
		    );
		}	    
	}

}
