import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { LeaveRequestService } from '../service/leaverequest.service'
import { EmployeesService } from '../../employees/service/employees.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveApproveComponent } from '../leave-approve/leave-approve.component';
import { UserService } from '../../user/service/user.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-employee-leave',
  templateUrl: './employee-leave.component.html',
  styleUrls: ['./employee-leave.component.css']
})

export class EmployeeLeaveComponent implements OnInit {
  title='Employee Leave';
	employeeDetails;id;numCasualleavetaken;numSickleavetaken;casualLeaveBal;employeeleaveDetails;sickLeaveBal;logged_user;line_approve;hr_approve;emp_leave_req;display_name;department;filtersearch;leaveTypesList;rowid;slug;role;leavedetails=[];leave_typename;leaves;
  modalRef: BsModalRef;   
  showSucessMessage: boolean;
  serverErrorMessages: string;
  sucessMessage: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor( private leaverequestService: LeaveRequestService, private route: ActivatedRoute,private router: Router, private modalService: BsModalService, private userService: UserService, public employeeService: EmployeesService,private datePipe: DatePipe) { }

  ngOnInit() {
      this.rowid='';
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        order: [ 3, 'desc' ],
      };

      this.filtersearch={leavetype:'',start_date:'',end_date:'',leavestatus:'',emp_id:''}; 
      this.role = this.userService.getUserRole();
      this.id = this.route.snapshot.paramMap.get('id');
      this.logged_user = this.userService.getUserDtls();     
      this.slug=this.userService.getusername();
      this.employeeService.getLeaveTypes(this.slug).subscribe(
          res => {
            this.leaveTypesList = res; 
          },
          err => { 
            console.log(err);            
          }
      );

      this.leaverequestService.getEmployeesLeaves(this.id).subscribe(
        res => {
        this.leaves = [];
            this.employeeDetails  = res[0]; 
            console.log(this.employeeDetails);
            this.emp_leave_req    = this.employeeDetails.emp_leave_req;
            this.leavedetails     = this.employeeDetails.leavedetails;
            this.display_name     = this.employeeDetails.display_name;
            this.department       = this.employeeDetails.department;
              // console.log(this.emp_leave_req);


            for(let leavedetails of this.emp_leave_req) {
                  for(let leavesrequest of this.leavedetails) {
                      if(leavesrequest._id==leavedetails.leave_type_id){
                          this.leave_typename=leavesrequest.type_name;
                      }
                  }
                  let leave =  {_id: leavedetails._id,leave_type:this.leave_typename, days_no:leavedetails.days_no,date_from: leavedetails.date_from, date_to: leavedetails.date_to,leave_status: leavedetails.leave_status};
                  this.leaves.push(leave);              
            }



           // this.leaverequestService.numLeavesTaken(this.id,'Casual Leave').subscribe(
           // res => {
           //     this.leaverequestService.getLeaveByName('Casual Leave').subscribe(
           //       res1 => {                             
           //         if(res) {                      
           //           this.numCasualleavetaken = res[0]['num_leave'];              
           //           this.casualLeaveBal = res1['emp_type'][this.employeeDetails.employee_type] - res[0]['num_leave'];
           //         } else {                     
           //           this.numCasualleavetaken = 0;
                     // this.casualLeaveBal = res1['emp_type'][this.employeeDetails.employee_type];
           //         }                             
           //       },
           //       err => { 
           //           //console.log(err);          
           //       }
           //     )        
           // },
           // err => { 
           //     console.log(err);          
           // }
         // )

         //   this.leaverequestService.numLeavesTaken(this.id,'Sick Leave').subscribe(
         //       res => {
          //        this.leaverequestService.getLeaveByName('Sick Leave').subscribe(
         //               res2 => {                    
          //                if(res) { 
          //                  this.numSickleavetaken = res[0]['num_leave'];              
           //                 this.sickLeaveBal = res2['emp_type'][this.employeeDetails.employee_type] - res[0]['num_leave'];
            //                this.dtTrigger.next();
            //              } else {
             //               this.numSickleavetaken = 0;
             //               this.sickLeaveBal = res2['emp_type'][this.employeeDetails.employee_type];
              //              this.dtTrigger.next();
             //             }                             
             //           },
             //           err => { 
                                     
             //           }                  
             //     )                       
             //   },
            ////    err => {                        
             //   }
            //)   
        },
        err => { 
                     
        }
      );
  }

  approveLeave(leave_id){
  console.log(leave_id);
    const initialState = {
          emp_id: this.id,
          leave_app:leave_id,
          page:'empleave',
    };
    this.modalRef = this.modalService.show(LeaveApproveComponent, { initialState });
    this.modalRef.content.user = initialState;
    this.modalRef.content.closeBtnName = 'Close';
    
  }

  cancelLeave(leave_id) {
    if(confirm("Are you sure to delete "+leave_id)) {
      var leaveStatus = 5;
      let res_data = {empid:this.id,leaveapp:leave_id,leave_status:leaveStatus};
   
     this.leaverequestService.leaveRequestResponse(res_data).subscribe(
          res => {            
            this.showSucessMessage = true;
            this.sucessMessage = 'Leave Cancelled'; 
             setTimeout(() => {
                  this.showSucessMessage = false;
                  this.modalService.hide(1);
                  this.router.navigateByUrl('/list-leave-request');
              }, 4000);             
          },
          err => {
           
            if (err.status === 422) {
              this.serverErrorMessages = err.error.join('<br/>');
            }
            else
              this.serverErrorMessages = 'Something went wrong.Please contact admin.'; 
              this.modalService.hide(1);
        }
      ); 
    } 
  }

  onSubmit(form: NgForm) { 
    form.value["emp_id"] =this.id;
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.dtTrigger.unsubscribe(); 


    form.value["start_date"]      = this.datePipe.transform(form.value["start_date"],'yyyy-MM-dd');    form.value["end_date"]        = this.datePipe.transform(form.value["end_date"],'yyyy-MM-dd'); 
    form.value["leavetype"]       = form.value["leavetype"];
    form.value["emp_id"]          = form.value["emp_id"];
    form.value["leavestatus"]     = form.value["leavestatus"];
    form.value["slug"]            = this.userService.getusername();
    this.leaverequestService.filterEmployeeLeaves(form.value).subscribe(employeesDetails => {
      this.employeeDetails  = employeesDetails[0]; 
      console.log(this.employeeDetails);
      this.leaves = [];
      this.emp_leave_req    = this.employeeDetails.emp_leave_req;
            this.leavedetails    = this.employeeDetails.leavedetails;
            this.display_name     = this.employeeDetails.display_name;
            this.department       = this.employeeDetails.department;

            for(let leavedetails of this.emp_leave_req) {
                for(let leavesrequest of this.leavedetails) {
                    if(leavesrequest._id==leavedetails.leave_type_id){
                        this.leave_typename=leavesrequest.type_name;
                    }
                }
                let leave =  {_id: leavedetails._id,leave_type:this.leave_typename, days_no:leavedetails.days_no,date_from: leavedetails.date_from, date_to: leavedetails.date_to,leave_status: leavedetails.leave_status};
                this.leaves.push(leave);              
            }

      this.dtTrigger.next();
    });
  }

  clearFilter(form: NgForm) {
    form.reset();
    this.dtTrigger.unsubscribe(); 
    this.leaverequestService.getEmployee(this.id).subscribe(
        res => {
          //this.employeeDetails  = res; 
          //this.emp_leave_req    = this.employeeDetails.emp_leave_req;

          this.leaves = [];
            this.employeeDetails  = res[0]; 
            this.emp_leave_req    = this.employeeDetails.emp_leave_req;
            this.leavedetails    = this.employeeDetails.leavedetails;
            this.display_name     = this.employeeDetails.display_name;
            this.department       = this.employeeDetails.department;


            for(let leavedetails of this.emp_leave_req) {
                  for(let leavesrequest of this.leavedetails) {
                      if(leavesrequest._id==leavedetails.leave_type_id){
                          this.leave_typename=leavesrequest.type_name;
                      }
                  }
                  let leave =  {_id: leavedetails._id,leave_type:this.leave_typename, days_no:leavedetails.days_no,date_from: leavedetails.date_from, date_to: leavedetails.date_to,leave_status: leavedetails.leave_status};
                  this.leaves.push(leave);              
            }


          this.dtTrigger.next();
        }
      );         
  }

}
