
<form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" class="signup">   
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card-header">
            <h3><i class="fa fa-check-square-o"></i>Sign UP</h3>
        </div> 
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 leftcolumn">                        
                <div class="card mb-3">                  
                    <div class="card-body">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Username</label>
                            <input type="text"  class="form-control" #username="ngModel" [(ngModel)]="userService.userdata.username" name="username" placeholder="Username" required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !username.valid }">
                            <div *ngIf="signUpForm.submitted && username.errors">
                              <label class="validation-message" *ngIf="username.errors.required">Please enter the username.</label>
                            </div>                   
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Company Name</label>
                            <input type="text" class="form-control" #company_name="ngModel" [(ngModel)]="userService.userdata.company_name" name="company_name" placeholder="Company Name" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !company_name.valid }">
                            <div *ngIf="signUpForm.submitted && company_name.errors">
                              <label class="validation-message" *ngIf="company_name.errors.required">Please enter the Company name.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email</label>
                            <input type="text"  class="form-control" #email="ngModel" [(ngModel)]="userService.userdata.email" name="email" placeholder="Email"  required [pattern]="emailRegex"  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !email.valid }" autocomplete="off">
                            <div *ngIf="signUpForm.submitted && email.errors">
                              <label *ngIf="email.errors.required" class="validation-message">Email field is required.</label>
                              <label *ngIf="email.errors.pattern" class="validation-message">Please enter a valid email address.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Address</label>
                            <input type="text" class="form-control" #address="ngModel" [(ngModel)]="userService.userdata.address" name="address" placeholder="Display Name" required [ngClass]="{'invalid-textbox' :signUpForm.submitted && !address.valid }">
                            <div *ngIf="signUpForm.submitted && address.errors">
                              <label class="validation-message" *ngIf="address.errors.required">Please enter the Address.</label>
                            </div>
                        </div>                  
                        <div class="form-group">
                            <label for="exampleInputEmail1">City</label>
                           <input type="text" class="form-control" #city="ngModel" [(ngModel)]="userService.userdata.city" name="city" required placeholder="City" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !city.valid }">
                            <div *ngIf="signUpForm.submitted && city.errors">
                              <label class="validation-message" *ngIf="city.errors.required">Please enter the city.</label>
                            </div>
                        </div>

                       

                     


                        <button type="submit" class="btn btn-primary">Submit</button>      
                                                        
                    </div>                                                      
                </div>                
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 rightcolumn">                        
                <div class="card mb-3">                 
                    <div class="card-body">

                          <div class="form-group">
                            <label for="exampleInputEmail1">State</label>
                            <input type="text" class="form-control" #state="ngModel" [(ngModel)]="userService.userdata.state" name="state" required placeholder="State" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !state.valid }">
                            <div *ngIf="signUpForm.submitted && state.errors">
                              <label class="validation-message"  *ngIf="state.errors.required">Please enter the State.</label>
                            </div>
                        </div> 

                           <div class="form-group">
                            <label for="exampleInputEmail1">Zipcode</label>
                            <input type="text" class="form-control" #zipcode="ngModel" [(ngModel)]="userService.userdata.zipcode" name="zipcode" required placeholder="Zipcode" [pattern]="numberonly"  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !zipcode.valid }">
                            <div *ngIf="signUpForm.submitted && zipcode.errors">
                              <label class="validation-message"  *ngIf="zipcode.errors.required">Please enter the zipcode.</label>
                              <label *ngIf="zipcode.errors.pattern" class="validation-message">Please enter a valid zipcode.</label>
                            </div>
                        </div> 
                      

                            <div class="form-group">
                                <label for="exampleInputEmail1">Phone Number</label>
                                <input type="text" class="form-control" #phone="ngModel" [(ngModel)]="userService.userdata.phone" name="phone" placeholder="Phone Number" [pattern]="mobNumberPattern" required  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !phone.valid }">
                                <div *ngIf="signUpForm.submitted && phone.errors">
                                  <label class="validation-message" *ngIf="phone.errors.required">Please enter the Phone Number.</label>
                                  <label *ngIf="phone.errors.pattern" class="validation-message">Please enter a valid phone number.</label>
                                </div>                   
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Fax</label>
                                <input type="text" class="form-control" #fax="ngModel" [(ngModel)]="userService.userdata.fax" name="fax" placeholder="Fax"  [ngClass]="{'invalid-textbox':signUpForm.submitted && !fax.valid }" [pattern]="numberonly" >
                                <div *ngIf="signUpForm.submitted && fax.errors">
                                  <label class="validation-message" *ngIf="fax.errors.required">Please enter the fax.</label>
                                  <label *ngIf="fax.errors.pattern" class="validation-message">Please enter a valid fax.</label>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="exampleInputPassword1">Website</label>
                                <input type="text" class="form-control" #website="ngModel" [(ngModel)]="userService.userdata.website" name="website" placeholder="Website" required  [pattern]="websiteexp"  [ngClass]="{'invalid-textbox' :signUpForm.submitted && !website.valid }">
                                <div *ngIf="signUpForm.submitted && website.errors">
                                  <label class="validation-message" *ngIf="website.errors.required">Please enter the website.</label>
                                  <label *ngIf="website.errors.pattern" class="validation-message">Please enter a valid website.</label>
                                </div>
                            </div>

                      

                    </div>
                </div>
            </div>           
        </div>
    </div>
</form>

<!-- Success message -->
<div class="success" *ngIf="showSucessMessage">
  Saved successfully
</div>

<!-- Error message -->
<div class="alert" *ngIf="serverErrorMessages">
  {{serverErrorMessages}}
</div>