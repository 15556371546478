<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>


              
                <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i> {{ title }}</h3>
                        </div>                            
                        <div class="card-body">

                            <a  [routerLink]="['/add-department']" (click)="setTitle( 'Add Department' )">
                                <div class="fa-hover col-md-3 col-sm-4">
                                <img src="../assets/images/avatars/employees.png"/> Add Department
                            </div></a>

                        	<a  [routerLink]="['/add-roles']" (click)="setTitle( 'Roles' )"><div class="fa-hover col-md-3 col-sm-4">
                        		<img src="../assets/images/avatars/att.png"/> Roles
                        	</div></a>

                        	<a  [routerLink]="['/add-attendancetypes']" (click)="setTitle( 'Attendance Types' )">
                        		<div class="fa-hover col-md-3 col-sm-4">
                        		<img src="../assets/images/avatars/leaves.png"/> Attendance Types
                        	</div></a>

                        	<a  [routerLink]="['/add-leavetypes']" (click)="setTitle( 'Leave Types' )"><div class="fa-hover col-md-3 col-sm-4">
                        		<img src="../assets/images/avatars/leave.png"/> Leave Types
                        	</div></a>

                        	 <a  [routerLink]="['/add-employement-types']" (click)="setTitle( 'Employement Types' )">
                                <div class="fa-hover col-md-3 col-sm-4">
                        		<i class="fa fa-id-card-o bigfonts" aria-hidden="true"></i> Employement Types
                        	</div></a>

                        <!--	<a  [routerLink]="['/summary']" (click)="setTitle( 'Summary' )">
                                <div class="fa-hover col-md-3 col-sm-4">
                        		<i class="fa fa-id-card-o bigfonts" aria-hidden="true"></i> id-card-o
                        	</div></a> -->
                      

                              
                        </div>
                    </div>
                </div>
            </div>


            
        </div>
    </div>
</div>

