<div class="content-page">
    <div class="content">            
        <div class="container-fluid"> 
            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <!-- Success message -->
            <div class="success" *ngIf="showDelSucessMessage">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong> Deleted Successfully</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <!-- Error message -->
            <div class="alert" *ngIf="serverErrorMessages">
              <!-- Error message -->
                <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                    <strong> {{serverErrorMessages}}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                    </button>
                </div>  
            </div>
            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Updated Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>                                
            </div>
            <div class="row">
                <div class="col-12">                       
                    <div class="card">
                        <div class="card-header">
                           <h3><i class="fa fa-users"></i> List Attendance</h3>                           
                        </div>
                        <form #signUpForm="ngForm" (ngSubmit)="signUpForm.valid && onSubmit(signUpForm)" autocomplete="off">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <input type="text" class="form-control mydatepicker" id="date-mask1" #start_date="ngModel" [(ngModel)]="attendanceSearch.start_date" name="start_date" placeholder="Start Date" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !start_date.valid }" [maxDate]="today" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">
                                        </div>                                
                                        <div class="form-group">
                                            <select class="se lect2 form-control custom-select"  [(ngModel)]="attendanceSearch.attendancetype" name="attendancetype">
                                                    <option value="">Select</option>                                       
                                                    <option *ngFor="let attendancetype of attendancetypeDetails" value="{{attendancetype._id}}">{{attendancetype.type_name}}</option>
                                            </select>  
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <input type="text" class="form-control mydatepicker" id="date-mask" #end_date="ngModel" [(ngModel)]="attendanceSearch.end_date" name="end_date" placeholder="End Date" [ngClass]="{'invalid-textbox' :signUpForm.submitted && !end_date.valid }" [maxDate]="today" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">
                                        </div>
                                        <div class="form-group">
                                            <label></label>
                                            <input type="submit" role="button" class="btn btn-primary" value="Search">
                                            &nbsp;&nbsp;<button type="button" (click)="clearFilter(signUpForm)" class="btn btn-primary" > Reset </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-body">                           
                            <div class="table-responsive">                               
                                <table  class="table table-bordered table-hover display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Attendance Type</th>
                                            <th>Time In</th>
                                            <th>Time Out</th>
                                            <th>Total Hours</th>                                   
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let attendance of attendanceDetails; let i=index;" id="row{{attendance._id}}">
                                            <td>{{attendance.attend_date | date: 'yyyy-MM-dd'}} </td>
                                            <td>{{attendance.attendancetype[0].type_name}}</td>
                                            <td>{{attendance.time_in | date: 'HH:mm'}}</td>
                                            <td>{{attendance.time_out | date: 'HH:mm'}}</td>   
                                            <td>{{attendance.total_hours}}</td> 
                                            <td>
                                                <a routerLink="/edit-attendance/{{attendance._id}}/{{loggedUserId}}"><i class="fa fa-pencil medfonts" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Edit" ></i> </a>                                              
                                            </td>
                                        </tr>
                                        <tr *ngIf="attendanceDetails?.length == 0"  align="center">
                                            <td colspan="6" class="no-data-available">No data Found!</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Date</th>
                                            <th>Attendance Type</th>
                                            <th>Time In</th>
                                            <th>Time Out</th>
                                            <th>Total Hours</th>                                       
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>






