import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Roles } from './role.model';
import { UserService } from '../../../user/service/user.service';

@Injectable({
  providedIn: 'root'
})

export class RoleService {
  roleForm: Roles = {
    role_id    : '',
    role_name  : '',
    status  : '',
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  showSucessMessage: boolean;
  serverErrorMessages: string;
  constructor( private http: HttpClient) { }

  postRoles(role: Roles){
    return this.http.post(environment.apiBaseUrl+'/addRoles',role,this.noAuthHeader);
  }

  listRoles(slug) {
    return this.http.get(environment.apiBaseUrl + '/listRoles/'+slug);
  }

  editRoles(id) {
    return this.http.get(environment.apiBaseUrl + '/editRoles/'+id);
  }

  updateRoles(role: Roles){
    return this.http.post(environment.apiBaseUrl + '/updateRoles',role,this.noAuthHeader);
  }

  deleteRoles(id){ 
      this.http.get(environment.apiBaseUrl + '/deleteRoles/'+id, {observe: 'response'})
      .subscribe(resp => {
            this.showSucessMessage = true;   
        },
        err => {
            if (err.status === 422) {
              this.serverErrorMessages = err.error.join('<br/>');
            }
            else
              this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        });
  }

  getusername() {    
    return localStorage.getItem('slug');
  }

}
