import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Attendancetypes } from './attendancetypes.model';

@Injectable({
  providedIn: 'root'
})

export class AttendanceTypesService {
  addAttendanceTypes: Attendancetypes = {
    type_name: '',
    is_default: false,
    break_time: 0,    
    status: true,
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  showSucessMessage: boolean;
  serverErrorMessages: string;

  constructor(private http: HttpClient) { }

  //HttpMethods

  postAddTypes(attendancetypes: Attendancetypes){   
    return this.http.post(environment.apiBaseUrl+'/addattendancetypes',attendancetypes,this.noAuthHeader);
  }

  listAttendancetypes(slug) {
    return this.http.get(environment.apiBaseUrl + '/listAttendancetypes/'+slug);
  }

  editAttendancetypes(id) {
    return this.http.get(environment.apiBaseUrl + '/editAttendancetypes/'+id);
  }

  updateAttendancetypes(attendancetypes: Attendancetypes){
    
    return this.http.post(environment.apiBaseUrl + '/updateAttendancetypes',attendancetypes,this.noAuthHeader);
  }

  deleteAttendancetype(id){ 
     this.http.get(environment.apiBaseUrl + '/deleteAttendancetype/'+id, {observe: 'response'})
    .subscribe(resp => {
          this.showSucessMessage = true;            
      },
      err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
      });
  }
  getusername() {    
    return localStorage.getItem('slug');
  }

}
