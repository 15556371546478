import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Leavetypes } from './leavetypes.model';

@Injectable({
  providedIn: 'root'
})

export class LeaveTypesService {
  addLeaveTypes: Leavetypes = {
    type_name: '',
    description: '',
    employement:'',
    duration :'',
    totalleaves: '',
    status: true,
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  showSucessMessage: boolean;
  serverErrorMessages: string;

  constructor(private http: HttpClient) { }

  //HttpMethods

  postAddTypes(leavetypes: Leavetypes){   
    return this.http.post(environment.apiBaseUrl+'/addleavetypes',leavetypes,this.noAuthHeader);
  }

  listLeavetypes(slug) {
    return this.http.get(environment.apiBaseUrl + '/listLeavetypes/'+slug);
  }

  editLeavetypes(id) {
    return this.http.get(environment.apiBaseUrl + '/editLeavetypes/'+id);
  }

  updateLeavetypes(leavetypes: Leavetypes){    
    return this.http.post(environment.apiBaseUrl + '/updateLeavetypes',leavetypes,this.noAuthHeader);
  }

  deleteLeavetype(id){ 
       this.http.get(environment.apiBaseUrl + '/deleteLeavetype/'+id, {observe: 'response'})
      .subscribe(resp => {
            this.showSucessMessage = true;            
        },
        err => {
            if (err.status === 422) {
              this.serverErrorMessages = err.error.join('<br/>');
            }
            else
              this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        });
  }

  getusername() {    
    return localStorage.getItem('slug');
  }

   listEmployement(slug) {
    return this.http.get(environment.apiBaseUrl + '/listEmployement/'+slug);
  }

}
