import { Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DepartmentService } from '../service/department.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { UserService } from '../../../user/service/user.service';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-list-department',
  templateUrl: './list-department.component.html',
  styleUrls: ['./list-department.component.css']
})

export class ListDepartmentComponent implements OnDestroy, OnInit {
	title='List Departments';
	departmentDetails;slug;showDelSucessMessage;serverErrorMessages;showSucessMessage;
  modalRef: BsModalRef;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  constructor( private departmentService: DepartmentService, private router: Router, private modalService: BsModalService, private http: HttpClient,private userService: UserService, private titleService: Title) { }

  ngOnInit() {
    this.slug = this.userService.getusername();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.departmentService.listDepartment(this.slug).pipe(map(this.extractData)
      ).subscribe(departmentDetails => {
        this.departmentDetails = departmentDetails;
        this.dtTrigger.next();
    });
  }

  deleteDepartment( id) {
    const initialState = {
            del_id: id,
            page: 'department',
        };
    this.modalRef = this.modalService.show(ConfirmBoxComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

   setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
