<div class="headerbar"  *ngIf="show">

    <!-- LOGO -->
    <div class="headerbar-left">
        <a href="index.html" class="logo"><img alt="Logo" src="assets/images/logo.png" /> <span>Admin</span></a>
    </div>
    <nav class="navbar-custom">
        <ul class="list-inline float-right mb-0">         
            <li class="list-inline-item dropdown notif">                            
                <button class="btn btn-primary btn-sm" (click)="quick_attendance()" ><i class="fa fa fa-book" aria-hidden="true"></i> Quick Attendance</button>
            </li>
            <li class="list-inline-item dropdown notif" *ngIf="this.shownotification>0">
                <a class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                    <i class="fa fa-fw fa-bell-o"></i><span class="notif-bullet"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-lg">
                    <!-- item-->
                    <div class="dropdown-item noti-title">
                        <h5><small><span class="label label-danger pull-xs-right">5</span>Notifications</small></h5>
                    </div>     
                    <a class="dropdown-item notify-item" *ngFor="let notification of notificationDetails; let i=index;" href="/view-notification/{{notification._id}}">
                        <div class="notify-icon bg-faded">
                            <img src="assets/images/avatars/avatar2.png" alt="img" class="rounded-circle img-fluid">
                        </div>
                        <p class="notify-details">
                            <b>{{notification.name}}</b>
                            <span>{{notification.subject}}</span>
                            <small class="text-muted">{{ notification.schedule_mail | timeAgo }}</small>
                        </p>
                    </a>
                    <a href="/list-notification/" class="dropdown-item notify-item notify-all">
                        View All Allerts
                    </a>
                </div>
            </li>

            <li class="list-inline-item dropdown notif">
                <a class="nav-link dropdown-toggle nav-user" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                    <img src="{{this.employeesAvatar}}" alt="Profile image" class="avatar-rounded">
                </a>
                <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
                    <!-- item-->
                    <div class="dropdown-item noti-title">
                        <h5 class="text-overflow"><small>Hello, {{this.fullName}}</small> </h5>
                    </div>

                    <!-- item-->
                    <a [routerLink]="['userprofile']" class="dropdown-item notify-item">
                        <i class="fa fa-user"></i> <span>Profile</span>
                    </a>

                    <!-- item-->
                    <a [routerLink]="['changepassword']" (click)="setTitle( 'Change Password' )" class="dropdown-item notify-item">
                        <i class="fa fa-user"></i> <span>Change Password</span>
                    </a>

                    <!-- item-->
                    <a (click)="onLogout()" class="dropdown-item notify-item">
                        <i class="fa fa-power-off"></i> <span>Logout</span>
                    </a>
                    
                </div>
            </li>
        </ul>

        <ul class="list-inline menu-left mb-0">
            <li class="float-left">
                <button class="button-menu-mobile open-left">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </li>                        
        </ul>
    </nav>

</div>
