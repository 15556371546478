import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttendanceService } from '../service/attendance.service'
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { EmployeesService } from '../../employees/service/employees.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DepartmentService } from '../../admin/department/service/department.service';
import { AttendanceTypesService } from '../../admin/attendance_types/service/attendancetypes.service';
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-list-attendance',
  templateUrl: './list-attendance.component.html',
  styleUrls: ['./list-attendance.component.css'],
})

export class ListAttendanceComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  attendanceTypesList;employeesDetails=[];attend_dates;attendanceDetails;attendance_typename;departmentDetails;
  title='List Attendance';
  attendanceSearch;attendancetypeDetails;id;attendancetype;slug;showDelSucessMessage;serverErrorMessages;showSucessMessage;today;
  loggedUserId: string;
  attendanceForm: FormGroup;data: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(public attendanceService: AttendanceService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private employeesService: EmployeesService,private route: ActivatedRoute,private modalService: BsModalService,private attendancetypesService: AttendanceTypesService,private datePipe: DatePipe) {
  }

  ngOnInit() {
      if(!this.userService.isLoggedIn()){
        this.router.navigateByUrl('/login');
      }

      this.loggedUserId = this.userService.getUserID();

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        order: [ 0, 'desc' ],
      };

      this.attendanceSearch={start_date:'',attendancetype:'',end_date:''}; 
      this.slug=this.userService.getusername();
      this.attendancetypesService.listAttendancetypes(this.slug).subscribe(
        res => {
          this.attendancetypeDetails = res;         
        }
      );

      this.employeesService.listEmployeeAttendance(this.loggedUserId).pipe(map(this.extractData)
        ).subscribe(res => {
          this.attendanceDetails=res; 
          console.log(this.attendanceDetails); 
          this.dtTrigger.next();
      });  
  }


  onSubmit(form: NgForm) {
    this.employeesDetails=[];
    this.id = this.userService.getUserID();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      retrieve: true,
      order: [0, 'desc'],
      destroy: true,
    };

    form.value["start_date"]      = this.datePipe.transform(form.value["start_date"],'yyyy-MM-dd');    
    form.value["end_date"]        = this.datePipe.transform(form.value["end_date"],'yyyy-MM-dd'); 
    form.value["attendancetype"]  = form.value["attendancetype"];
    form.value["user_id"]         = this.id;
    form.value["slug"]            = this.userService.getusername();
    this.dtTrigger.unsubscribe();
   
    this.attendanceService.searchAttendances(form.value).pipe(map(this.extractData)
        ).subscribe(employeesDetails => { 
          this.attendanceDetails=employeesDetails;
          console.log(this.attendanceDetails);
          this.dtTrigger.next();
      });
  }


  clearFilter(form: NgForm) {
    this.attendanceSearch={start_date:'',attendancetype:'',end_date:''}; 
    this.employeesDetails = [];
    this.dtTrigger.unsubscribe();
    this.id = this.userService.getUserID();
    this.employeesService.listEmployeeAttendance(this.id).pipe(map(this.extractData)
        ).subscribe(res => {
          this.attendanceDetails=res;

          for(let attendance of this.attendanceDetails) {
              for(let data of attendance.attendance) {
                  for(let attendtypes of attendance.attendancedetails) {
                      if(attendtypes._id==data.attend_type_id){
                          this.attendance_typename=attendtypes.type_name;
                      }
                  }
                 let attend =  {_id: data._id,attn_date: data.attn_date, attendance_type:this.attendance_typename ,time_in: data.time_in, time_out: data.time_out,total_hours: data.total_hours};
                 this.employeesDetails.push(attend);
              }
          }

          
      }); 
    this.dtTrigger.next();
  }



  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

}
