import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DepartmentService } from '../service/department.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.css']
})

export class EditDepartmentComponent implements OnInit {

	title='Edit Department';
    showSucessMessage: boolean;
    serverErrorMessages: string;
    id; departmentDetails;

    constructor(private departmentService: DepartmentService,private route: ActivatedRoute,private router: Router) { }    

    ngOnInit() {
  	    this.id = this.route.snapshot.paramMap.get('id');
	  	this.departmentService.editDepartment(this.id).subscribe(
		    res => {
		        this.departmentDetails = res[0];
		    },
		    err => { 
		        console.log(err);	        
		    }
	    );
    }

    onSubmit(form: NgForm) {
	    this.departmentService.UpdateDepartment(form.value).subscribe(
	        res => {
		        this.showSucessMessage = true;
		        setTimeout(() => this.showSucessMessage = false, 1000);
		    },
	        err => {
		        if (err.status === 422) {
		          this.serverErrorMessages = err.error.join('<br/>');
		        }
		        else
		          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
		    }
	    );
	}

}
