import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { EmployeesService } from '../../employees/service/employees.service';
import { LeaveRequestService } from '../../leave/service/leaverequest.service';
import { ReportsService } from '../service/reports.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-timeoff-used',
  templateUrl: './timeoff-used.component.html',
  styleUrls: ['./timeoff-used.component.css']
})
export class TimeoffUsedComponent implements OnInit {
  title='Timeoff Used';
  slug;age;rowid;employeesDetails;departmentDetails;roleDetails;reportFilters;leaveTypesList;
  employeeLeaves;def_leavtype;def_leavtype_totleave;def_employement;sel_leavtypes;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private titleService: Title, private employeesService: EmployeesService, private reportsService: ReportsService, private departmentService: DepartmentService, private leaveRequestService: LeaveRequestService) { }

  ngOnInit() {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.slug = this.employeesService.getusername();

    this.reportsService.listTimeOffUsed(this.slug).pipe(map(this.extractData)
            ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.employeeLeaves         = new Array();
        for(let employeeAtt of this.employeesDetails) { 
          this.leaveRequestService.getLeaveByID(employeeAtt.leave_applied.leave_type_id).subscribe(
            res => {
              this.sel_leavtypes           = res;

          let leaveVals  = {_id:employeeAtt._id, fullName:employeeAtt.fullName, date_from: employeeAtt.leave_applied.date_from, date_to: employeeAtt.leave_applied.date_to, days_no: employeeAtt.leave_applied.days_no, notes: employeeAtt.leave_applied.notes, departmentname:employeeAtt.departmentname, roles:employeeAtt.roles, type_name:this.sel_leavtypes.type_name }
          this.employeeLeaves.push(leaveVals);
          });
        }
        
        this.dtTrigger.next();
    });

   

    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
        res => {
          this.departmentDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.employeesService.listRoles(this.slug).subscribe(
        res => {
          this.roleDetails = res;
        },
        err => { 
          console.log(err);            
        }
    );

    this.reportFilters={leavetype:'',department:'',role:'',start_date:'',end_date:''}; 
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  onSubmit(form: NgForm) {
    this.rowid='';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
    this.employeeLeaves  = new Array;
    this.employeesDetails  = new Array;
    
    form.value["slug"] = this.employeesService.getusername();

    this.reportsService.searchTimeOffUsed(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {
        this.dtTrigger.unsubscribe(); 
        this.employeesDetails = employeesDetails;
        this.employeeLeaves         = new Array();        
        
        for(let employeeAtt of this.employeesDetails) { 
          this.leaveRequestService.getLeaveByID(employeeAtt.leave_applied.leave_type_id).subscribe(
            res => {
              this.sel_leavtypes           = res;

          let leaveVals  = {_id:employeeAtt._id, fullName:employeeAtt.fullName, date_from: employeeAtt.leave_applied.date_from, date_to: employeeAtt.leave_applied.date_to, days_no: employeeAtt.leave_applied.days_no, notes: employeeAtt.leave_applied.notes, departmentname:employeeAtt.departmentname, roles:employeeAtt.roles, type_name:this.sel_leavtypes.type_name }
          this.employeeLeaves.push(leaveVals);
          });
        }
       
        this.dtTrigger.next();
    });
    
    


  }

  clearFilter(form: NgForm) {
    this.reportFilters={leavetype:'',department:'',role:'',start_date:'',end_date:''}; 
    this.slug = this.employeesService.getusername();
    this.dtTrigger.unsubscribe(); 
    this.reportsService.listTimeOffUsed(this.slug).pipe(map(this.extractData)
            ).subscribe(employeesDetails => {
        this.employeesDetails = employeesDetails;
        this.employeeLeaves         = new Array();
        for(let employeeAtt of this.employeesDetails) { 
          this.leaveRequestService.getLeaveByID(employeeAtt.leave_applied.leave_type_id).subscribe(
            res => {
              this.sel_leavtypes           = res;

          let leaveVals  = {_id:employeeAtt._id, fullName:employeeAtt.fullName, date_from: employeeAtt.leave_applied.date_from, date_to: employeeAtt.leave_applied.date_to, days_no: employeeAtt.leave_applied.days_no, notes: employeeAtt.leave_applied.notes, departmentname:employeeAtt.departmentname, roles:employeeAtt.roles, type_name:this.sel_leavtypes.type_name }
          this.employeeLeaves.push(leaveVals);
          });
        }
        
        this.dtTrigger.next();
    });
  }

}
