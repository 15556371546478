import { Component, OnInit,Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeesService } from '../service/employees.service'
import { ActivatedRoute } from "@angular/router";
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DepartmentService } from '../../admin/department/service/department.service';

@Injectable({
  providedIn: 'root'
})

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

    title='User Profile';
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    showSucessMessage: boolean;;
    serverErrorMessages: string;
    signUpForm: FormGroup;
    fileData: File = null;
	previewUrl:any = null;  
	avatarFile:any = null;  
	fileUploadProgress: string = null;
	uploadedFilePath: string = null;
	today;editable;

    constructor(private employeesService: EmployeesService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private departmentService: DepartmentService, private http: HttpClient) { }

    id; employeesDetails;employeesListDetails;departmentDetails;roleDetails;employeesAvatar;devManagerList;slug;userrole;

    ngOnInit() {
    this.slug = this.userService.getusername();
    this.userrole = this.userService.getUserRole();
	  	this.employeesService.getUserProfile().subscribe(
		    res => {
		        this.employeesDetails = res;
		        if(res['avatar']) {
	              let apiURl = environment.apiBaseUrl;
	              apiURl = apiURl.replace('api','');
	              this.employeesAvatar = apiURl+res['avatar'];
	            }
	            this.employeesService.getRepPersonByDept(res['department'],this.slug).subscribe(
	              res => {
	                this.devManagerList = res;
	              },
	              err => { 
	                console.log(err);            
	              }
	            )
		    },
		    err => { 
		        console.log(err);        
		    }
	    );
        
	    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
          res => {
            this.departmentDetails = res;
          },
          err => { 
            console.log(err);            
          }
      	);

        this.employeesService.getRepPersonByDept('HR',this.slug).subscribe(
          res => {
            this.employeesListDetails = res;
          },
          err => { 
            console.log(err);            
          }
        );
        
	    this.employeesService.listRoles(this.slug).subscribe(
          res => {
            this.roleDetails = res;
          },
          err => { 
            console.log(err);            
          }
      	);


      	 this.signUpForm = this.formBuilder.group({
            fullName       : ['', Validators.required],
            display_name   : ['', Validators.required],
            gender         : ['', Validators.required],
            dob            : [''],            
            email          : ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            address        : ['', Validators.required],
            locality       : ['', Validators.required],            
            emp_code       : ['', Validators.required],
            joining_date   : ['', Validators.required],
            department     : ['', Validators.required], 
            pincode        : ['', [Validators.required, Validators.pattern("^[0-9]*$")]] ,
            manager      : [''], 
            hrmanager    : [''], 
        }); 


	}

    onSubmit(form: NgForm) {

	    let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16);

	    if(this.fileData) {
	        const formData = new FormData();
	        formData.append('file', this.fileData);
	        this.http.post(environment.apiBaseUrl + '/upload/', formData)
	          .subscribe(res => {         
	             form.value["avatar"] =res['uploadedFile'];
	             this.userService.updateavatarImage(form.value); 
	             this.employeesService.UpdateEmployees(form.value).subscribe(
	              res => {
	                this.showSucessMessage = true;
	                 setTimeout(() => {
	                      this.showSucessMessage = false;
	                  }, 4000);
	            },
	            err => {
	              if (err.status === 422) {
	                this.serverErrorMessages = err.error.join('<br/>');
	              }
	              else
	                this.serverErrorMessages = 'Something went wrong.Please contact admin.';
	          }
	          );

	        });

	    } else {
	        this.employeesService.UpdateEmployees(form.value).subscribe(
	            res => {
		            this.showSucessMessage = true;
		             setTimeout(() => {
		                  this.showSucessMessage = false;
		              }, 4000);
	            },
	            err => {
		            if (err.status === 422) {
		              this.serverErrorMessages = err.error.join('<br/>');
		            }else{
	                  this.serverErrorMessages = 'Something went wrong.Please contact admin.';
	                }
	            }
	        );
	    }
	}

	getDeptManager(dept: String) {    
	    this.employeesService.getRepPersonByDept(dept,this.slug).subscribe(
	        res => {
	            this.devManagerList = res;            
	        },
	        err => { 
	            console.log(err);          
	        }
	      );
	}    

    fileProgress(fileInput: any) {
      this.fileData = <File>fileInput.target.files[0];
      this.preview();
    }
   
    preview() {
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }   
      var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        this.previewUrl = reader.result; 
        this.employeesAvatar = ''; 
      }
    }

}
