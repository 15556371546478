import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../user/service/user.service';
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { RoleService } from '../service/role.service';

@Component({
  selector: 'app-edit-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.css']
})
export class EditRolesComponent implements OnInit {

  title='Edit Department';
  id; roleDetails;
  showSucessMessage: boolean;
  serverErrorMessages: string;

  constructor(private roleService: RoleService,private route: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
      this.id = this.route.snapshot.paramMap.get('id');
	  	this.roleService.editRoles(this.id).subscribe(
		    res => {
		        this.roleDetails = res[0];
		        console.log(this.roleDetails);
		    },
		    err => { 
		        console.log(err);	        
		    }
	    );
  }

  onSubmit(form: NgForm) {
      this.roleService.updateRoles(form.value).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => this.showSucessMessage = false, 4000);
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
  }

}
