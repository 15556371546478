import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Notification } from './notification.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  addNotification: Notification = {
    notification_type  : '',
    name               : '',
    from_name          : '', 
    from_email         : '',
    department         : '',
    subject            : '', 
    content            : '',
    mail_status        : '',
    schedule_mail      : '', 
    is_custom_mail     : '',
    expire_date        : '',
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  //HttpMethods

  postNotification(notification: Notification){
    return this.http.post(environment.apiBaseUrl+'/add_notification',notification,this.noAuthHeader);
  }

  listNotification(department,slug) {
    if(department=='HR'){
       return this.http.get(environment.apiBaseUrl + '/listNotification/'+slug);
    }else{
       return this.http.get(environment.apiBaseUrl + '/listNotificationByDept/'+department+'/'+slug);
    }
  }

  editNotification(id) {
    return this.http.get(environment.apiBaseUrl + '/editNotification/'+id);
  }

  updateNotification(notification: Notification){
    return this.http.post(environment.apiBaseUrl + '/updateNotification',notification,this.noAuthHeader);
  }

  deleteNotification(id){ 
    this.http.get(environment.apiBaseUrl + '/deleteNotification/'+id, {observe: 'response'})
      .subscribe(resp => {
      console.log(resp.headers.get('X-Token'));
    });
  } 

  listEmployeeNotification(department,slug) {
    return this.http.get(environment.apiBaseUrl + '/listEmployeeNotification/'+department+'/'+slug);
  } 

  listDepartmentUsers(users) {
       return this.http.get(environment.apiBaseUrl + '/listDepartmentUsers/'+users);
  }
  updateViewNotification(notid,userid) {
    return this.http.get(environment.apiBaseUrl + '/updateViewNotification/'+notid+'/'+userid);
  }

  listAllNotifications(date) {
       return this.http.get(environment.apiBaseUrl + '/listAllNotifications/'+date);
  }
  sendNotificationMailstatus(id) {
       return this.http.get(environment.apiBaseUrl + '/sendNotificationMailstatus/'+id);
  }
  viewUserNotificationStatus(id) {
      return this.http.get(environment.apiBaseUrl + '/viewUserNotificationStatus/'+id);
  }

  searchNotification(notification: Notification){
    return this.http.post(environment.apiBaseUrl + '/searchNotification',notification,this.noAuthHeader);
  }

}
