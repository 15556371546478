import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeesService } from '../service/employees.service'
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import {formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-leave-request',
  templateUrl: './leave-request.component.html',
  styleUrls: ['./leave-request.component.css']
})

export class LeaveRequestComponent implements OnInit {

  today= new Date(); 
  title='Leave Request';
  showSucessMessage: boolean;
  leavereqDateErr: boolean;
  serverErrorMessages: string;
  leaveRequestForm: FormGroup;
  loggedUserId: String;
  leaveTypesList;slug;leaveDetails;
  dateRange;prAproved;mailData;logged_user_line;user_email;logged_user;jstoday;managers;mailusers=[];approved_by;employeesDetails;

  constructor(public employeeService: EmployeesService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.leavereqDateErr= false;
       if(!this.userService.isLoggedIn()){
         this.router.navigateByUrl('/login');
       }
       this.slug = this.userService.getusername();
       this.leaveRequestForm = this.formBuilder.group({
            leave_type_id   : ['', Validators.required],
            leave_mode      : ['', Validators.required],
            days_no         : ['', Validators.required],
            date_from       : ['', Validators.required],
            notes           : ['', Validators.required],
        }); 

        this.loggedUserId = this.userService.getUserID();
        this.logged_user = this.userService.getUserDtls();
        this.slug=this.userService.getusername();

       // this.approved_by = new Map([['HR', 'HR'], ['LM', 'Line Manager']]);
        this.employeeService.getRepPersonByDeptHR(this.slug).subscribe(
            res => {
              this.employeesDetails = res;
            },
            err => { 
              console.log(err);            
            }
        );

        this.userService.getLineManagers(this.logged_user.manager,this.logged_user.hrmanager,this.loggedUserId).subscribe(
          res => {
                this.leaveDetails = res;
                let maillist = [];
                if(Object.keys(this.leaveDetails).length>0){
                  for(let employee of this.leaveDetails) {                     
                      maillist.push(employee.email);
                  }
                  localStorage.setItem('managers', JSON.stringify(maillist)); 
                }             

              this.managers  = this.userService.managers();
              this.mailusers = JSON.parse(this.userService.managers());

              this.mailusers.splice(this.mailusers.indexOf(this.logged_user.user_email), 1); //delete the current user from mail list

              console.log(this.mailusers);
              this.employeeService.getLeaveTypes(this.slug).subscribe(
                res => {
                  this.leaveTypesList = res; 
                },
                err => { 
                  console.log(err);            
                }
              );


          }
          
        ); 

        
        this.dateRange = true;
        this.prAproved = false;
  }

  onDaysChange(dayValue: number): void {  
    if(dayValue > 1) {
        this.dateRange = false
    } else {
        this.dateRange = true
    }
  }

  onDateChange(leavedate:any): void { 

    //let date_from    =  formatDate(leavedate, 'yyyy-MM-dd', 'en-US', '+0530');
    //var leave_dates = {'user_id':this.loggedUserId, 'leavedate': date_from }
   // this.employeeService.isLeaveDateExist(leave_dates).subscribe(
   //   res => {
   //     if(res>0) {        
    //      this.leavereqDateErr= true;
   //     } else {
   //       this.leavereqDateErr= false;
   //     }
   //   },
   //   err => { 
   //     console.log(err);            
   //   }
  //  );
  }

  checkCheckBoxvalue(event){
    if(event.target.checked){
      this.prAproved = true
    } else {
      this.prAproved = false
    }    
  }

  onSubmit(form: NgForm) {

    if(form.value.days_no >1) {
      form.value["date_to"]   = form.value.date_from[1]; 
      form.value["date_from"] = form.value.date_from[0];
    } 

    form.value["added_date"]   = new Date();
    form.value["updated_date"] = new Date();
    form.value["mailusers"]    = this.managers;

    this.employeeService.postLeaveRequest(form.value).subscribe(
        res => {

          if(res['leave_mode'] !=''){

                if(res['leave_mode'] ==1) {
                   var leaveMode = 'Full Day'
                } else {
                   var leaveMode = 'Half Day'
                }

                let mail_msg ='Hi Sir, <br/><br/>';
                mail_msg +='Kindly please accept and do the needful.<br/><br/>';
                mail_msg +='<table><tr height="35"><td width="150"><b>Leave Type</b></td><td>'+ res['leave_type_id'] +'</td></tr><tr height="35"><td><b>Leave Mode</b></td><td>'+ leaveMode +'</td></tr><tr height="35"><td><b>No. of Days</b></td><td>'+ res['days_no'] +'</td></tr>';

                if(res['days_no'] > 1) {
                  
                  let date_to      = this.datePipe.transform(res["date_to"],'yyyy-MM-dd');    
                  let date_from    = this.datePipe.transform(res["date_from"],'yyyy-MM-dd');

                  mail_msg += '<tr height="35"><td><b>Date From</b></td><td>'+ date_from +'</td></tr><tr height="35"><td><b>Date To</b></td><td>'+ date_to +'</td></tr>';

                } else {
                  let date_from_only    = this.datePipe.transform(res["date_from"],'yyyy-MM-dd');
                  mail_msg += '<tr><td><b>Date</b></td><td>'+ date_from_only +'</td></tr>'
                }

                if(res['pre_approved']) {
                   mail_msg += '<tr height="35"><td><b>Pre Aprroved?</b></td><td>Yes</td></tr><tr height="35"><td><b>Approved By</b></td>'+ res['pre_approved_by'] +'<td></td></tr>'
                }

                mail_msg += '<tr><td><b>Message</b></td><td>'+ res['notes'] +'</td></tr></table><br/><br/> Regards,<br/> '+this.logged_user.fullname;

                var subject= 'Leave Request from '+this.logged_user.fullname;

                this.mailData = {'to' :res['mailusers'],'from':this.logged_user.user_email,'subject':subject,'text':mail_msg};
                this.employeeService.sendMail(this.mailData).subscribe(
                  res => {
                   console.log(res); 
                  },
                  err => { 
                    console.log(err);            
                  }
                );

                this.showSucessMessage = true;          
                setTimeout(() => {
                    this.showSucessMessage = false;
                   // this.router.navigateByUrl('/list-leave-request');
                }, 1000);  //5s
                       
              }              
          }
      ); 
      this.showSucessMessage = true;
      this.resetForm(form);  
  }

  resetForm(form: NgForm) {
      this.employeeService.leaveRequest = {
        leave_type_id      : '',
        leave_mode: 1,    
        days_no: 1,    
        date_from: new Date(),
        date_to:new Date(),    
        pre_approved: false,    
        hr_approve: false,    
        line_approve: false,    
        pre_approved_by: '',    
        notes: '',    
        leave_status: 0,  
        added_date: new Date(),
        updated_date: new Date(),
        exclude_managers: false,
        exclude_by: '',
        hr_approved_date: '',
        linemanager_approved_date: '',
      };
      form.resetForm();
      this.serverErrorMessages = '';
  } 

}
