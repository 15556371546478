import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { EmployeesService } from '../../employees/service/employees.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from '../service/notification.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-notification',
  templateUrl: './list-notification.component.html',
  styleUrls: ['./list-notification.component.css']
})
export class ListNotificationComponent implements OnInit {

  notificationDetails;modalRef;userDepartment;role;notifiSearch;slug;showDelSucessMessage;today;
  title='List Notification';
  showSucessMessage: boolean;
  serverErrorMessages: string;
  attendanceForm: FormGroup;
  data: string;
  timeout_data: string;
  timein_data: string;
  loggedUserId: string;
  dtOptions: DataTables.Settings = {"order": [[ 2, "desc" ]]};
  dtTrigger: Subject<any> = new Subject();
  datePipeString : string;

  constructor(public notificationService: NotificationService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private employeesService: EmployeesService,private modalService: BsModalService,private datePipe: DatePipe) { }

  ngOnInit() {
        if(!this.userService.isLoggedIn()){
          this.router.navigateByUrl('/login');
        }

        this.notifiSearch={noti_name:'',subject:'',notification_type:'',start_date:'', end_date:''}; 
         this.datePipeString = this.datePipe.transform(Date.now(),'yyyy-MM-dd');
         this.role = this.userService.getUserRole();

        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 10,
        };

        this.userDepartment = this.userService.getUserDepartment();
        this.role = this.userService.getUserRole();
        this.slug=this.userService.getusername();
        this.notificationService.listNotification(this.userDepartment,this.slug).pipe(map(this.extractData)
          ).subscribe(notificationDetails => {
            this.notificationDetails = notificationDetails; 
            console.log(this.notificationDetails);
            this.dtTrigger.next();
        });
  }


  onSubmit(form: NgForm) {
    this.notificationDetails=[];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
    };
   
    this.dtTrigger.unsubscribe(); 

    form.value["department"]         = this.userService.getUserDepartment();
    form.value["slug"]               = this.userService.getusername();
    this.notificationService.searchNotification(form.value).pipe(map(this.extractData)
      ).subscribe(employeesDetails => {     
        this.notificationDetails = employeesDetails;
        this.dtTrigger.next();
    });
    this.dtTrigger.next();
  }

  clearFilter(form: NgForm) {
    this.notifiSearch={noti_name:'',subject:'',notification_type:'',start_date:'', end_date:''}; 
    this.dtTrigger.unsubscribe(); 
        this.userDepartment = this.userService.getUserDepartment();
        this.role = this.userService.getUserRole();
        this.slug=this.userService.getusername();
        this.notificationService.listNotification(this.userDepartment,this.slug).pipe(map(this.extractData)
          ).subscribe(notificationDetails => {
            this.notificationDetails = notificationDetails; 
            this.dtTrigger.next();
        }); 
  }


  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  deleteNotification(id) {
    const initialState = {
            del_id: id,
            page: 'notification',
        };
    this.modalRef = this.modalService.show(ConfirmBoxComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }

}
