import { Component, OnInit, ElementRef, OnDestroy,ViewChild ,TemplateRef} from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttendanceService } from '../service/attendance.service'
import { UserService } from '../../user/service/user.service';
import { Router } from "@angular/router";
import { EmployeesService } from '../../employees/service/employees.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmBoxComponent } from '../../shared/confirm-box.component';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { AttendanceTypesService } from '../../admin/attendance_types/service/attendancetypes.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-summary-details',
  templateUrl: './summary-details.component.html',
  styleUrls: ['./summary-details.component.css']
})

export class SummaryDetailsComponent implements OnInit {
 

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  attendanceTypesList;employeesDetails=[];id;employeename;attendanceDetails;attendance_typename;
  title='Employee Attendance Summary';
  attendanceSearch;departmentDetails;attendancetypeDetails;attendancetype;slug;showDelSucessMessage;today;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  attendanceForm: FormGroup;
  data: string;
  timeout_data: string;
  timein_data: string;
  loggedUserId: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(public attendanceService: AttendanceService,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private employeesService: EmployeesService,private modalService: BsModalService,private route: ActivatedRoute,private datePipe: DatePipe,private attendancetypesService: AttendanceTypesService) { }



  ngOnInit() {
      this.id = this.route.snapshot.paramMap.get('id');
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        order: [ 0, 'desc' ],
        destroy: true,
      };
      this.attendanceSearch={start_date:'',attendancetype:'',end_date:''}; 
      this.slug=this.userService.getusername();
      this.attendancetypesService.listAttendancetypes(this.slug).subscribe(
        res => {
          this.attendancetypeDetails = res;
        }
      );

      this.employeesService.listEmployeeAttendance(this.id).pipe(map(this.extractData)
        ).subscribe(employeesDetails => {
          this.attendanceDetails=employeesDetails;  
          this.dtTrigger.next();
      });
  }


  onSubmit(form: NgForm) {
    this.employeesDetails=[];
    this.id = this.route.snapshot.paramMap.get('id');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
       retrieve: true,
       order: [ 0, 'desc' ],
       destroy: true,
    };
    form.value["start_date"]      = this.datePipe.transform(form.value["start_date"],'yyyy-MM-dd');    form.value["end_date"]        = this.datePipe.transform(form.value["end_date"],'yyyy-MM-dd'); 
    form.value["attendancetype"]  = form.value["attendancetype"];
    form.value["user_id"]         = this.id;
    form.value["slug"]            = this.userService.getusername();
     this.dtTrigger.unsubscribe();
   
     this.attendanceService.searchAttendances(form.value).pipe(map(this.extractData)
        ).subscribe(employeesDetails => { 
           this.attendanceDetails=employeesDetails;  
           this.dtTrigger.next();
      });
  }

  clearFilter(form: NgForm) {
    form.reset();
    this.dtTrigger.unsubscribe();
    this.employeesDetails = [];
    this.id = this.route.snapshot.paramMap.get('id'); 
    this.employeesService.listEmployeeAttendance(this.id).pipe(map(this.extractData)
        ).subscribe(employeesDetails => {
          this.attendanceDetails=employeesDetails;
          this.dtTrigger.next();
      });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

}