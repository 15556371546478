import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NotificationService } from '../service/notification.service';
import { UserService } from '../../user/service/user.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";


@Component({
  selector: 'app-edit-notification',
  templateUrl: './edit-notification.component.html',
  styleUrls: ['./edit-notification.component.css']
})
export class EditNotificationComponent implements OnInit {

  title='Edit Notification'; 
  signUpForm: FormGroup;slug;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  dropdownList = {};
  selectedItems = [];
  
  today;department;
  constructor(private notificationService: NotificationService,private route: ActivatedRoute,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private departmentService: DepartmentService, private http: HttpClient) { }

  id;notificationDetails;departmentDetails;

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.slug=this.userService.getusername();
        
        this.notificationService.editNotification(this.id).subscribe(
		    res => {
		        this.notificationDetails = res[0];
		    },
		    err => { 
		        console.log(err);	        
		    }
	    );

      this.departmentService.listDepartment(this.slug).subscribe(
          res => {
            
             this.departmentDetails = res;
           
          },
          err => { 
            console.log(err);            
          }
        );
    }

     onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  

     onSubmit(form: NgForm) {  

      this.notificationService.updateNotification(form.value).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => {
              this.showSucessMessage = false;
          }, 4000);           
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
    }

}
