<div class="content-page">
  <div class="content">   
    <div class="container-fluid"> 
      <div class="row">
        <div class="col-xl-12">
          <div class="breadcrumb-holder">
            <h1 class="main-title float-left">{{ title }}</h1>
            <ol class="breadcrumb float-right">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active">{{ title }}</li>
            </ol>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div *ngIf="showSucessMessage">                
        <!-- Success message -->                 
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Attendance Added Successfully!</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
          </button>
        </div>            
      </div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
          <strong> {{serverErrorMessages}}</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
          </button>
      </div> 
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <div class='app'> 
            <full-calendar #fullcalendar [editable]="true" [events]="calendarEvents"  
                [header]="options.header" [customButtons]="options.customButtons"  
                (dateClick)="dateClick($event)" (eventDragStop)="eventDragStop($event)"  
                [plugins]="options.plugins" (eventClick)="eventClick($event)"  
                [columnHeaderHtml]="options.columnHeaderHtml"></full-calendar>  
          </div> 
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <form #attendanceForm="ngForm" (ngSubmit)="attendanceForm.valid && onSubmit(attendanceForm)" autocomplete="off">
            <div class="card mb-3">
              <div class="card-header">
                <h3><i class="fa fa-check-square-o"></i>Add Attendance</h3>
              </div>                            
              <div class="card-body">                 
                <div class="form-group">
                    <label for="exampleInputEmail1">Date {{data | date}}</label>
                    <input type="hidden" class="form-control" name="id" #id="ngModel" [(ngModel)]="loggedUserId">
                    <input class="form-control" placeholder="Enter Date" [minDate]="today" [maxDate]="today" [bsValue]="bsInlineValue" bsDatepicker (bsValueChange)="onValueChange($event)" #attend_date="ngModel" name="attend_date" [(ngModel)]="attendanceService.addAttendance.attend_date" value=""  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }"> 

                    <div *ngIf="attendanceForm.submitted && attend_date.errors">
                      <label class="validation-message" *ngIf="attend_date.errors.required">Please enter the Attendance Date.</label>
                    </div>    
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Time In</label>
                    <timepicker [(ngModel)]="attendanceService.addAttendance.time_in" name="time_in" #timein="ngModel"  ></timepicker>
                    <div *ngIf="attendanceForm.submitted && time_in.errors">
                      <label class="validation-message" *ngIf="time_in.errors.required">Please enter Time In.</label>
                    </div> 
                </div> 
                <div class="form-group">
                    <label for="exampleInputEmail1">Time Out</label>
                    <timepicker  [(ngModel)]="attendanceService.addAttendance.time_out" name="time_out" #time_out="ngModel"  ></timepicker>
                    <div *ngIf="attendanceForm.submitted && time_out.errors">
                      <label class="validation-message" *ngIf="time_out.errors.required">Please enter  Time Out.</label>
                    </div> 
                </div>  
                <div class="form-group">
                    <label for="exampleInputEmail1">Attendance Type</label>
                    <select class="select2 form-control custom-select" #attend_type_id="ngModel" [(ngModel)]="attendanceService.addAttendance.attend_type_id" name="attend_type_id" required >
                        <option value="">Select</option>
                        <option *ngFor="let attendancetype of attendanceTypesList" [ngValue]="attendancetype._id" value="{{attendancetype._id}}">{{attendancetype.type_name}}</option>
                    </select>                   
                </div>                          
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>                                                      
            </div>
          </form> 
        </div>
      </div>           
    </div>
  </div>
</div>
