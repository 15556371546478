<div class="content-page">
    <div class="content">            
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="breadcrumb-holder">
                        <h1 class="main-title float-left">{{ title }}</h1>
                        <ol class="breadcrumb float-right">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item active">{{ title }} </li>
                        </ol>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <!-- Success message -->
            <div *ngIf="showSucessMessage">                
                   <!-- Success message -->                 
                  <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong> Added Successfully!</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>      
            </div>
              <!-- Error message -->
            <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="serverErrorMessages">
                <strong> {{serverErrorMessages}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 

            <form #leavetypeForm="ngForm" (ngSubmit)="leavetypeForm.valid && onSubmit(leavetypeForm)" autocomplete="off"> 

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-9 col-xl-9">                        
                    <div class="card mb-3">
                        <div class="card-header">
                            <h3><i class="fa fa-check-square-o"></i> {{ title }}</h3>
                             <a [routerLink]="['/list-leavetypes']" (click)="setTitle( 'List Leave Types' )" class="pull-right"><button type="button" class="btn btn-primary btn-sm ">List Leave Types</button></a>
                        </div>
                            
                        <div class="card-body">

                            <div class="form-group">
                                <label for="exampleInputEmail1">Name</label>
                                <input type="text" class="form-control" #type_name="ngModel" [(ngModel)]="leavetypesService.addLeaveTypes.type_name" name="type_name" required placeholder="Name" [ngClass]="{'invalid-textbox' :leavetypeForm.submitted && !type_name.valid }">
                                <div *ngIf="leavetypeForm.submitted && type_name.errors">
                                  <label class="validation-message"  *ngIf="type_name.errors.required">Please enter Name.</label>
                                </div>                  
                            </div>

                            <div class="form-group">
                                <label for="exampleInputEmail1">Description</label>
                                <textarea class="form-control" #description="ngModel" [(ngModel)]="leavetypesService.addLeaveTypes.description" name="description" rows="8"  [ngClass]="{'invalid-textbox' :leavetypeForm.submitted && !description.valid }"></textarea>
                                           
                            </div>

                             <div class="form-group">
                                    <label for="exampleInputPassword1">Employement Type</label>
                                    <select class="select2 form-control custom-select" #employement="ngModel" [(ngModel)]="leavetypesService.addLeaveTypes.employement" name="employement" required  [ngClass]="{'invalid-textbox' :leavetypeForm.submitted && !employement.valid }">
                                        <option>Select</option>                                         
                                        <option  *ngFor="let employement of employementDetails" value="{{employement._id}}">{{employement.employement_name}}</option>                                                         
                                    </select>  
                                    <div *ngIf="leavetypeForm.submitted && employement.errors">
                                      <label class="validation-message" *ngIf="employement.errors.required">Please select the Employement type.</label>
                                    </div>  
                            </div>

                            <div class="form-group">
                                <label for="exampleInputEmail1">Duration</label>
                                <input type="text" class="form-control" #duration="ngModel" [(ngModel)]="leavetypesService.addLeaveTypes.duration" name="duration" placeholder="Duration">                                                
                            </div>



                            <div class="form-group">
                                <label for="exampleInputEmail1">Number of Leaves</label>
                                <input type="number" class="form-control" #totalleaves="ngModel" [(ngModel)]="leavetypesService.addLeaveTypes.totalleaves" name="totalleaves" required placeholder="Total Leaves in Days" [ngClass]="{'invalid-textbox' :leavetypeForm.submitted && !totalleaves.valid }">

                                <div *ngIf="leavetypeForm.submitted && totalleaves.errors">
                                  <label class="validation-message"  *ngIf="totalleaves.errors.required">Please enter the total leaves.</label>
                                </div>
                                                        
                                                
                            </div>

                            <div class="form-group">
                                <label for="exampleInputEmail1">Status</label>
                                    <div class="form-check">
                                        <div class="radiocon">
                                            <div class="custom-control custom-radio radiobutton">
                                                <input type="radio" class="custom-control-input" id="customControlValidation1" name="status" #status="ngModel" [(ngModel)]="leavetypesService.addLeaveTypes.status" required name="status" [value]="true">
                                                <label class="custom-control-label" for="customControlValidation1">Active</label>                                
                                            </div>
                                            <div class="custom-control custom-radio radiobutton">
                                                <input type="radio" class="custom-control-input" id="customControlValidation2" #status="ngModel" [(ngModel)]="leavetypesService.addLeaveTypes.status" required name="status" [value]="false">
                                                <label class="custom-control-label" for="customControlValidation2">Inactive</label>
                                            </div>
                                            <div *ngIf="leavetypeForm.submitted && status.errors">
                                                      <label class="validation-message"  *ngIf="status.errors.required">Please select status.</label>
                                            </div>
                                        </div> 
                                        <div *ngIf="leavetypeForm.submitted && status.errors">
                                          <label class="validation-message"  *ngIf="gender.errors.required">Please select the status.</label>
                                        </div>  
                                    </div>          
                            </div>



                            <button type="submit" class="btn btn-primary">Submit</button>


                        </div>
                    </div>
                </div>
            </div>
        </form>
        </div>
    </div>
</div>

