import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {
constructor(private userService: UserService,private router : Router) { }

  model ={
    email :this.userService.getRemeberMeUsername(),
    password:this.userService.getRemeberMePassword(),
    remember_me:this.userService.getRemeberMe(),
  };

  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages: string;

  ngOnInit() {
    if(this.userService.isLoggedIn()){
       this.router.navigateByUrl('/home');
    }
  }

  onSubmit(form : NgForm){
    
    this.userService.login(form.value).subscribe(
      res => {
        if(form.value['remember_me']) {
          this.userService.setRemeberMe(form.value); 
        } else {
          this.userService.removeRemeberMe(); 
        }
        location.reload();
        this.userService.setToken(res['token']);        
        this.userService.setLoggedUser(res['logged_user']);        
      },
      err => {
        this.serverErrorMessages = err.error.message;
      }
    );
  }

}
