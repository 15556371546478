import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EmployeesService } from '../service/employees.service';
import { UserService } from '../../user/service/user.service';
import { DepartmentService } from '../../admin/department/service/department.service';
import { ActivatedRoute } from "@angular/router";
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";

@Component({
  selector: 'app-edit-employees',
  templateUrl: './edit-employees.component.html',
  styleUrls: ['./edit-employees.component.css']
})

export class EditEmployeesComponent implements OnInit {
	title='Edit Employees'; 
  fileData: File = null;
  previewUrl:any = null;  
  avatarFile:any = null;  
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  signUpForm: FormGroup;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  today;employement;

  constructor(private employeesService: EmployeesService,private route: ActivatedRoute,private userService: UserService,private router: Router, private formBuilder: FormBuilder,private departmentService: DepartmentService, private http: HttpClient) { }

  id;employeesDetails;departmentDetails;roleDetails;employeesListDetails;employeesAvatar;devManagerList;slug;
  private sanitization: DomSanitizer

  ngOnInit() {
	  	this.id = this.route.snapshot.paramMap.get('id');
      this.slug = this.userService.getusername();

	  	this.employeesService.editEmployee(this.id).subscribe(
        res => {
            this.employeesDetails = res[0];
            if(res[0].avatar) {
              let apiURl = environment.apiBaseUrl;
              apiURl = apiURl.replace('api','');
              this.employeesAvatar = apiURl+res[0].avatar;
            }
                this.employeesService.getRepPersonByDeptManager(res[0].department,this.slug).subscribe(
                    res => {
                      this.devManagerList = res;
                    },
                    err => { 
                      console.log(err);            
                    }
                )
        },
        err => { 
            console.log(err);          
        }
      );   
      
	    this.departmentService.listEmployeesDepartment(this.slug).subscribe(
          res => {
            this.departmentDetails = res;
          },
          err => { 
            console.log(err);            
          }
      );

      this.employeesService.getRepPersonByDeptHR(this.slug).subscribe(
          res => {
            this.employeesListDetails = res;
          },
          err => { 
            console.log(err);            
          }
      );
      
	    this.employeesService.listRoles(this.slug).subscribe(
          res => {
            this.roleDetails = res;
          },
          err => { 
            console.log(err);            
          }
      );
       this.employeesService.listEmployeeType(this.slug).subscribe(
          res => {
            this.employement = res;
          },
          err => { 
            console.log(err);            
          }
      );
	}

  onSubmit(form: NgForm) {
      let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, pos - 20); // how far to scroll on each step
          } else {
              window.clearInterval(scrollToTop);
          }
      }, 16);  
      
      if(this.fileData) {

        const formData = new FormData();
        formData.append('file', this.fileData);
        this.http.post(environment.apiBaseUrl + '/upload/', formData)
          .subscribe(res => {         
             form.value["avatar"] =res['uploadedFile'];
             this.employeesService.UpdateEmployees(form.value).subscribe(
              res => {
                this.showSucessMessage = true;
                  setTimeout(() => {
                      this.showSucessMessage = false;
                      this.router.navigateByUrl('/list-employees');
                  }, 500);
              },
              err => {
                if (err.status === 422) {
                  this.serverErrorMessages = err.error.join('<br/>');
                }
                else
                  this.serverErrorMessages = 'Something went wrong.Please contact admin.';
            }
          );

        });

      } else {

        this.employeesService.UpdateEmployees(form.value).subscribe(
          res => {
            this.showSucessMessage = true;
             setTimeout(() => {
                  this.showSucessMessage = false;
                  this.router.navigateByUrl('/list-employees');
              }, 500);
          },
          err => {
            if (err.status === 422) {
              this.serverErrorMessages = err.error.join('<br/>');
            }
            else
              this.serverErrorMessages = 'Something went wrong.Please contact admin.';
          }
        );
      } 
	}

  getDeptManager(dept: String) {    
    this.employeesService.getRepPersonByDept(dept,this.slug).subscribe(
        res => {
            this.devManagerList = res;            
        },
        err => { 
            console.log(err);          
        }
      );
  }    

  fileProgress(fileInput: any) {
      this.fileData = <File>fileInput.target.files[0];
      this.preview();
  }
   
  preview() {
      // Show preview 
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
   
      var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        this.previewUrl = reader.result; 
        this.employeesAvatar = ''; 
      }
  }

}
